import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { apiEndpoints } from '../../common/constants';
import { ApiProvider } from '../common/api.service';

// import { apiURL } from "../../common/constants";

@Injectable({
  providedIn: 'root'
})
export class NotificationsProvider {

  constructor(private api: ApiProvider) {

  }

  /**
   * @name getList
   * @desc Metodo para obtener lista de notificaciones.
   * @returns {Object} - Promesa
   */
  getList() {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getNotifications;
      this.api.post(endpoint).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data.Notifications);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

  /**
   * @name read
   * @desc Metodo para indicar al API notificación leida desde el app.
   * @param {String} notificationId
   */
  markAsRead(notificationId: string) {
    const records = {
      Notifications: {
        NotificationId: notificationId
      }
    };
    const endpoint = apiEndpoints.setNotificationReaded;
    this.api.put(endpoint, records);
  }

  /**
   * @name getCounter
   * @desc Metodo para obtener el numer de notificaciones pendientes
   * @returns Promesa
   */
  getCounter() {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getNotificationCounter;
      this.api.post(endpoint).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data.Counter);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      );
    });
  }

}
