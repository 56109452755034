import { Injectable } from "@angular/core";
import { AlertController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { share } from 'rxjs/operators';

import { Events } from "./events.service";
import { ErrorService } from "../error-service/error.service";

import { Callback, CallbackOperation } from "../../interfaces/callback.interface";
import { apiURL, apiEndpoints, dashboardsEndpoints } from "../../common/constants";

@Injectable({
  providedIn: 'root'
})
export class ApiProvider {
  conexionAlertShown: boolean = false;
  sesionAlertShown: boolean = false;
  data: any;
  constructor(public http: HttpClient,
    private errorService: ErrorService,
    private datePipe: DatePipe,
    private events: Events,
    private alertCtrl: AlertController) { }

  /**
   * @name post
   * @desc Método para realizar requests en metodo post.
   * @param {Object} endpoint dirección del request solicitado
   * @param {Object} records records que se agregarán al body del request
   * @param {string} baseUrlWSS parametro para cambiar la url base de la peticion. SOLO USAR PARA WSS
   * @returns {Object} - Observable
   */
  post(endpoint: string, records: any = {}, baseUrlWSS: string = null, acceptJson: boolean = true) {
    if (!this.errorService.isConnected()) {
      if (!this.conexionAlertShown) {
        this.conexionAlertShown = true;
        this.showErrorAlert("Sin Conexión", "El dispositivo no tiene acceso a internte, verifique su conexión.");
        this.errorService.addErrorOffline({
          Fecha_Hora: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZZZZZ'),
          Error_Code: ErrorService.NO_CONEXION_ERROR_CODE,
          Error_Message: "Sin Conexion."
        });
      }
      return new Observable(subscriber => {
        subscriber.next({
          DateTime: "",
          ErrorCode: "",
          ErrorMessage: "Error Inesperado.",
          OperationName: "",
          Status: ""
        });
      });
    }
    const url = (baseUrlWSS || apiURL) + endpoint;
    if (!!acceptJson && records) {
      records.JSON = acceptJson;
    }
    if (endpoint == "/ReportFile") {
      return this.http
        .post(url, records, { responseType: "text" })
        .pipe(
          map(data => (this.data = data)),
          share()
        );
    } else {

      return this.http.post(url, records)
        .pipe(
          map((data: Callback) => {
            if (data.Success && data.Success.toString() === "true") {
              if (endpoint === dashboardsEndpoints.activar && data.Operation.ErrorMessage) return data.Operation;
              return data.Records;
            }
            else if (endpoint === apiEndpoints.login || endpoint === dashboardsEndpoints.validateCodigoActivacion) {
              return {
                DateTime: "",
                ErrorCode: "",
                ErrorMessage: "Error Inesperado.",
                OperationName: "",
                Status: ""
              };
            }
            else if (data.Operation.ErrorMessage === "Sesion Expirada" || data.Operation.ErrorCode === ErrorService.INVALID_SESSION_ERROR_CODE) {
              if (!this.sesionAlertShown) {
                this.sesionAlertShown = true;
                this.showErrorAlert("Sesion Expirada", "Por favor iniciar sesión nuevamente, sesión expirada.", () => {
                  this.events.publish("app:logout", null);
                  this.sesionAlertShown = false;
                });
              }
            }
            else if (data.Operation.ErrorCode === ErrorService.SQL_TIMEOUT_ERROR_CODE || data.Operation.ErrorCode === ErrorService.SQL_ERROR_CODE) {
              this.showErrorAlert("Algo salió mal!", "Servicio no disponible por el momento. Vuelva a intentarlo más tarde.");
              this.errorService.addError({
                Fecha_Hora: data.Operation.DateTime,
                Error_Code: data.Operation.ErrorCode,
                Error_Message: data.Operation.ErrorMessage
              });
            } else {
              this.showErrorAlert("Algo salió mal!", "Favor de contactar al administrador del sistema para terminar la transacción solicitada.");
              this.errorService.addError({
                Fecha_Hora: data.Operation.DateTime,
                Error_Code: data.Operation.ErrorCode,
                Error_Message: data.Operation.ErrorMessage
              });
            }
            const error: CallbackOperation = {
              DateTime: "",
              ErrorCode: "",
              ErrorMessage: "Error Inesperado.",
              OperationName: "",
              Status: ""
            };
            return error;
          }),
        );
    }
  }

  /**
   * @name put
   * @desc Método para realizar requests en metodo put.
   * @param {Object} endpoint dirección del request solicitado
   * @param {Object} records records que se agregarán al body del request
   * @returns {Object} - Observable
   */
  put(endpoint: string, records: any = {}) {
    const url = apiURL + endpoint;
    return this.http.put(url, records)
      .pipe(
        map((data: Callback) => {
          if (data.Success) {
            if (data.Success === "true") {
              return data.Records;
            } else {
              return data.Operation;
            }
          }
          const error: CallbackOperation = {
            DateTime: "",
            ErrorCode: "",
            ErrorMessage: "Error Inesperado.",
            OperationName: "",
            Status: ""
          };
          return error;
        })
      );
  }

  delete(endpoint: string, records: any = {}) {
    const url = apiURL + endpoint;
    return this.http.delete(url, records)
      .pipe(
        map((data: any) => {
          if (data.Success) {
            if (data.Success === "true") {
              return data.Records;
            } else {
              return data.Operation;
            }
          }
          const error: CallbackOperation = {
            DateTime: "",
            ErrorCode: "",
            ErrorMessage: "Error Inesperado.",
            OperationName: "",
            Status: ""
          };
          return error;
        })
      );
  }

  /**
   * @name showErrorAlert
   * @desc Metodo que se encargar de mostrar una Alerta de error sencilla
   * @title El parametro que recive es el titulo a mostrar en la Alerta
   * @error El parametro que recive es el error a mostrar en la Alerta
   * @cb El parametro que recive es la acccion a realizar al dar click en el boton de Aceptar al mostrar en la Alerta
  */
  async showErrorAlert(title: string, error: string, cb: any = null) {
    let acceptButton: any = {
      text: "Aceptar"
    };
    if (cb) {
      acceptButton.handler = cb;
    }
    const errorAlert = await this.alertCtrl.create({
      header: title,
      message: error,
      backdropDismiss: false,
      buttons: [acceptButton],
      cssClass: "base-alert error-alert"
    });
    await errorAlert.present();
  }
}
