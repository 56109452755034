import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { ApiProvider } from "../common/api.service";
import { apiEndpoints, apiUsuario } from "../../common/constants";

@Injectable({
  providedIn: 'root'
})
export class PreferencesProvider {
  Pref = {
    shortcutsDataConfig: {},
    notificationsDataConfig: {}
  }

  constructor(private api: ApiProvider) { }

  getData() {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getPreferences;
      this.api.post(endpoint).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            //console.log('data',data)
            this.Pref.shortcutsDataConfig = data.User.Shortcuts;
            this.Pref.notificationsDataConfig = data.User.Notices;

            resolve(this.Pref);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  sendShortcutsConfig(shortcutsConfig, notificationsConfig) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getPreferences;

      const records = this._getRecords('Shorcuts', shortcutsConfig, 'Notices', notificationsConfig)

      this.api.put(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  sendNotificationsConfig(notificationsConfig, shortcutsConfig) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getPreferences;

      const records = this._getRecords('Notices', notificationsConfig, 'Shorcuts', shortcutsConfig)

      this.api.put(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  sendProfileConfig(profileModel) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.sendProfile;
      const profileRecords = {
        User: [
          {
            Name: profileModel.userName,
            Mail: profileModel.userMail,
            Avatar: profileModel.userAvatar
          }
        ]
      };

      this.api.put(endpoint, profileRecords).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  setUsarioInfo(usuario, mail) {
    console.log(usuario, mail);

    try {
      const endpoint = apiEndpoints.setUsarioInfo;
      const profileRecords = {
        user_Name: usuario,
        user_Mail: mail
      };

      this.api.post(endpoint, profileRecords, apiUsuario).subscribe(
        (data: any) => {
          console.log(data);
        },
        (error: HttpErrorResponse) => {
        }
      );
    }

    catch (e) {
      console.log(e);
    }
  }

  // /////////////////////////////////////// PRIVATE

  _getRecords(principalName, principalConfig, secondaryName, secondaryConfig) {
    var object = {
      User: {}
    };
    object.User[principalName] = [];
    object.User[secondaryName] = secondaryConfig;

    // principalConfig.forEach(value => {
    //   var item = {};
    //   item[value.index] = value;
    //   object.User[principalName].push(item);
    // });

    // angular.forEach(principalConfig, function(value, prop) {
    //   var item = {};
    //   item[prop] = value;
    //   object.User[principalName].push(item);
    // });

    for (const prop in principalConfig) {
      if (principalConfig.hasOwnProperty(prop)) {
        const element = principalConfig[prop];
        var item = {};
        item[prop] = element;
        object.User[principalName].push(item);
      }
    }

    return object;
  }
  // //ARV - 31/08/17
  // getUserMenu() {
  //   return apiFactory
  //     .send(API_ENDPOINTS.getUserMenu, "POST")
  //     .then(function(data) {
  //       $rootScope.$broadcast("setUpMenuConfig", data);
  //     });
  // }
}
