import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { ApiProvider } from '../common/api.service';
import { consultasCivar, consultasCivarUrl } from '../../common/constants';

/**
 * @name ConsultasCivarService
 * @desc Servicio usado para hacer las peticiones de las vistas de consultas
 */
@Injectable({
  providedIn: 'root'
})
export class ConsultasCivarService {
  constructor(private api: ApiProvider) { }

  /**
   * @name getVines
   * @desc Metodo que regresa el numero de vines que hay dada una consulta y/o unos filtros
   */
  getVines(consult, dealer: number = 0) {
    const fields = consult.cols.concat(consult.rows).toString();
    return this.api.post(consultasCivar.vines, {
      dealer,
      nueva: 1,
      filtros: consult.filters === "" ? 0 : 1,
      campos: fields + ",",
      filtroDrillDown: "",
      detalle: 0,
      id_Cnsc_Consulta: 0,
      filtrosXML: consult.filters
    }, consultasCivarUrl);
  }

  /**
   * @name getVinesNavigateBy
   * @desc Metodo que regresa el numero de vines que hay dada una consulta y un criterio de busqueda
   */
  getVinesNavigateBy(drillDown: string, fields: string, filters, dealer: number = 0) {
    return this.api.post(consultasCivar.vines, {
      dealer,
      nueva: 1,
      filtros: !!filters && filters !== "" ? 1 : 0,
      campos: fields + ",",
      filtroDrillDown: drillDown,
      detalle: 0,
      id_Cnsc_Consulta: 0,
      filtrosXML: filters || ""
    }, consultasCivarUrl);
  }


  // CONSULTAS
  /**
   * @name getAll
   * @desc Metodo que regresa todas las consultas almacenadas por un usuario
   */
  getAll() {
    return this.api.post(
      consultasCivar.verTodas,
      {},
      consultasCivarUrl

    );
  }

  /**
   * @name saveConsult
   * @desc Metodo que guarda una consulta en la bd
   */
  saveConsult(name, consult) {
    return this.api.post(consultasCivar.guardar, {
      "desc_Nombre": name,
      "bit_Default": consult.bit_Default || 0,
      "filtros": consult.filters === "" ? 0 : 1,
      "campos": 1,
      "ordenamiento": consult.cols.map((v, i) => {
        return v + ";" + (!!consult.sort ? consult.sort.cols[i].toUpperCase() : "ASC")
      }).concat(consult.rows.map((v, i) => {
        return v + ";" + (!!consult.sort ? consult.sort.rows[i].toUpperCase() : "ASC")
      })).join("|") + "|",
      "valores": "Desc_Estatus;" + consult.frontFilters.join("~"),
      "filtrosXML": consult.filters,
      "columnas": consult.cols.join("|") + "|",
      "renglones": consult.rows.join("|") + "|"
    }, consultasCivarUrl);
  }

  /**
   * @name updateConsult
   * @desc Metodo que actualiza una consulta en la bd
   */
  updateConsult(consult) {
    return this.api.post(consultasCivar.actualizar, {
      "id_Cnsc_Consulta": consult.id || consult.id_Cnsc_Consulta,
      "desc_Nombre": consult.name,
      "bit_Default": consult.bit_Default,
      "filtros": consult.filters === "" ? "0" : "1",
      "campos": 1,
      "ordenamiento": consult.cols.map((v, i) => {
        return v + ";" + (!!consult.sort ? consult.sort.cols[i].toUpperCase() : "ASC")
      }).concat(consult.rows.map((v, i) => {
        return v + ";" + (!!consult.sort ? consult.sort.rows[i].toUpperCase() : "ASC")
      })).join("|") + "|",
      "valores": "Desc_Estatus;" + consult.frontFilters.join("~"),
      "filtrosXML": consult.filters,
      "columnas": consult.cols.join("|") + "|",
      "renglones": consult.rows.join("|") + "|"
    }, consultasCivarUrl);
  }

  /**
   * @name deleteConsults
   * @desc Metodo que elimina una consulta de la bd
   */
  deleteConsults(ids: string[]) {
    return this.api.post(consultasCivar.eliminar, {
      "id_Cnsc_Consulta": ids.join(",0;") + ",0;"
    }, consultasCivarUrl);
  }

  /**
   * @name getById
   * @desc Metodo que regresa los detalles de una consulta dado su id
   */
  getById(id) {
    return this.api.post(consultasCivar.verUna, {
      "id_Cnsc_Consulta": id,
      "id_Cnsc_Idioma": 1
    }, consultasCivarUrl);
  }

  getDefault() {
    return this.api.post(
      consultasCivar.verDefault,
      {},
      consultasCivarUrl

    );
  }

  saveDefault(id) {
    return this.api.post(consultasCivar.guardarDefault, {
      "id_Cnsc_Consulta": id,
    }, consultasCivarUrl);
  }

  // PUBLICAR
  /**
   * @name getPublishedUsers
   * @desc Metodo que regresa los usuarios a los que se les ha publicado una consulta
   */
  getPublishedUsers(id) {
    return this.api.post(consultasCivar.verUsuarios, {
      "idConsulta": id
    }, consultasCivarUrl);
  }

  /**
   * @name publishConsult
   * @desc Metodo que guarda la publicacion de una consulta
   */
  publishConsult(id: number, name: string, idTipoPubl: number, users: string) {
    return this.api.post(consultasCivar.publicar, {
      "id_Cnsc_Consulta": id,
      "id_TipoPublicacion": idTipoPubl,
      "desc_Nombre": name,
      "usuariosSeg": users
    }, consultasCivarUrl);
  }

  /**
   * @name searchUsers
   * @desc Metodo que busca usuarios
   */
  searchUsers(idTipo: number, searchStr: string) {
    return this.api.post(consultasCivar.buscarUsuarios, {
      "id_Tipo": idTipo,
      "descripcion": searchStr
    }, consultasCivarUrl);
  }

  // FILTROS
  /**
   * @name getFilter
   * @desc Metodo que regresa los valores de un filtro
   */
  getFilter(key, filters: any = {}) {
    let body = {
      opcion: this.filterOptions[key],
      ...filters
    };
    return this.api.post(consultasCivar.filtros, body, consultasCivarUrl).pipe(first()).toPromise();
  }

  private filterOptions: any = {
    "Cve_Cliente": 1,
    "Cve_Marca": 2,
    "Cve_Familia": 3,
    "Num_Remision": 4,
    "Desc_Modelo": 5,
    "Cve_Linea": 6,
    "Num_PullOrder": 8,
    "Cve_Distribuidor": 9,
    "Desc_PerteneceCDM": 10,
    "Desc_VINImpExpDom": 11,
    "Cve_Catalogo": 12,
    "Cve_Zona": 13,
    "Desc_Accesorizado": 14,
    "Cve_Destino": 15,
    "Id_Cnsc_Source": 17,
    "Desc_Detenciones": 19
  }

  /**
   * @name getLAB
   * @desc Metodo que regresa los valores del filtro de LAB
   */
  getLAB() {
    return this.api.post(
      consultasCivar.lab,
      {},
      consultasCivarUrl

    ).pipe(first()).toPromise();
  }

  /**
   * @name getRange
   * @desc Metodo que regresa los valores de los filtros que sean de un rango de números (Antigüedad en Company Stock, ...)
   */
  getRange() {
    return this.api.post(
      consultasCivar.range,
      {},
      consultasCivarUrl

    ).pipe(first()).toPromise();
  }
}
