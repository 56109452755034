import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiEndpoints } from '../../common/constants';
import { ApiProvider } from '../common/api.service';
import { UserProvider } from '../user/user.service';

import { apiURL } from "../../common/constants";

/*
  Generated class for the ShipProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class ShipProvider {

  constructor(public _usuarioProv: UserProvider,
    private api: ApiProvider) {
  }

  getList(byVIN: string = '') {
    const records = {
        VIN: byVIN.length === 17 ? byVIN : null
    };

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getShipList;
      this.api.post(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data.Ships);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

  /* getDetail(tripId: number) {
    const records = {
      Trips: [{
        IdViaje: tripId
      }]
    };
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getTrackingDetail;
      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data.Trips);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  } */


}
