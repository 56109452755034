import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { apiEndpoints } from './../../common/constants';
import { ApiProvider } from '../common/api.service';
import { AppStorageProvider } from '../common/app-storage.service';

import { apiURL } from "../../common/constants";

@Injectable({
  providedIn: 'root'
})
export class HomeProvider {
  slides: [string];
  shortcuts: any;
  survillance: any;

  param = {}

  constructor(private api: ApiProvider, private appStorage: AppStorageProvider) {
    // this.loadStorage();
  }

  loadStorage() {
    Promise.all([
      this.appStorage.get('preferences'),
      this.appStorage.get('travelList/current')
    ]
    ).then((values: any[]) => {
      if (values[0]) {
        this.shortcuts = values[0].Shortcuts;
      }
      if (values[1]) {
        this.survillance = values[1].trips.surv;
      }
    });
  }

  /**
   * @name getShortcuts
   * @desc Metodo para resolver data de Shortcuts.
   * @returns {Object} - Promesa
   */
  getShortcuts() {
    return new Promise((resolve, reject) => {
      // if (this.shortcuts) {
      //   resolve(this.shortcuts);
      // } else {
      const endpoint = apiEndpoints.getPreferences;
      this.api.post(endpoint).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            const preferences = data.User;
            if (preferences) {
              // this.appStorage.put('preferences', preferences);
              this.shortcuts = preferences.Shortcuts
            }
            resolve(this.shortcuts);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
      // }
    });
  }

  /**
   * @name getSurvillance
   * @desc Metodo para resolver data de viajes "En Vigilancia".
   * @returns {Object} - Promesa
   */
  getSurvillance() {

    return new Promise((resolve, reject) => {
      // if (this.survillance) {
      //   resolve(this.survillance);
      // } else {
      const records = {
        TripType: 'CURRENT',
        Page: 1,
        EnVigilancia: 1
      };
      const endpoint = apiEndpoints.getTravelList;
      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.survillance = data.Trips ? data.Trips : {};

            // const travels = {
            //   trips: {
            //     surv: this.survillance
            //   }
            // };

            // this.appStorage.put('travelList/current', travels);
            resolve(this.survillance);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
      // }
    });
  }
}
