import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { apiEndpoints } from "../../common/constants";
import { ApiProvider } from "../common/api.service";
import { apiURL } from "../../common/constants";

@Injectable({
  providedIn: 'root'
})
export class ConsultaCDProvider {

  cd: any;

  constructor(private api: ApiProvider) { }

  getCD(filters) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getCD;

      const records = filters

      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            if (data.Brands.length > 0) {
              var list = data.CDList;
              var brands = data.Brands;

              var total_vins = 0;

              for (let cd of list) {
                var vins = 0;

                for (let status of cd['ESTATUS']) {
                  vins += status.VINS;
                }

                cd.VINS = vins;
                total_vins += vins;

              }

              list.TOTALVINS = total_vins;

              this.cd = {
                'ListCD' : list,
                'Brands' : brands,
                'Total' : total_vins
              };

            } else {
              this.cd = {}
            }

            resolve(this.cd);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

}
