import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastProvider {
  constructor(public toastCtrl: ToastController) { }

  /**
   * @name presentToast
   * @desc Metodo que se encarga de mostrar un Toast sencillo
   * @toastMessage El parametro que recive es el mensaje a mostrar en el Toast
   * @toastDuration El parametro que recive es la duracion antes de desaparecer el Toast, si no se envia por default es 2000 -> 2sec
   * @toastPosition El parametro que recive es la pocision para mostrar en el Toast ( 'top', 'bottom', 'middle' ), si no se evia por default es 'middle'
   * @toastCustomClass El parametro que recive es la cssClass para modificar el diseño del Toast ( nombre de una clase de scss ), si no se evia por default es 'm-custom-toast'
   */
   async presentToast(toastMessage: string, toastDuration: number = 2000, toastPosition: any = 'middle', toastCustomClass: string = 'm-custom-toast') {
    const toast = await this.toastCtrl.create({
      message: toastMessage,
      duration: toastDuration,
      position: toastPosition,
      cssClass: toastCustomClass
    });
    await toast.present();
  }
}
