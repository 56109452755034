import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { apiEndpoints } from '../../common/constants';
import { ApiProvider } from '../common/api.service';

import { apiURL } from "../../common/constants";

/*
  Generated class for the ReportViewerProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class ReportViewerProvider {

  strByte: string;

  constructor(public http: HttpClient, private api: ApiProvider) {

  }

  getFile(Id_Cnsc_Reporte) {
    var request;

    request = {
      'Id_Cnsc_Reporte': Id_Cnsc_Reporte
    };

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getReportFile;
      this.api.post(endpoint, request, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            this.strByte = data ? data : {};
            resolve(this.strByte);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });

  }

}
