<div *ngIf="items.length > 0">
  <ul style="background-color: rgb(33, 33, 33);">
    <li *ngFor="let item of items; let idx = index;"
      [ngStyle]="{'background-color': item.OpcionMenu == null ? 'rgb(0, 0, 0)' : null}">
      <ion-item class="menu-item" lines="none" [color]="item.OpcionMenu == null ? 'light-black' : 'light-black'"
        (click)="onClick(item, idx)">
        <p class="ion-text-nowrap">{{item.Cve_Desc_Menu}}</p>
        <i slot="end" class="arrow" *ngIf="item.OpcionMenu != null"
          [ngClass]="showSubMenu[idx] ? 'arrow-down' : 'arrow-right'"></i>
      </ion-item>
      <div style="max-height: 0px; overflow: hidden; transition: max-height 0.4s ease-in;" #submenuElem>
        <my-menu (onSubmenuOpen)="resizeContainer($event);" [items]="item.OpcionMenu || []" [level]="level + 1"
          [menu]="menu ? menu : item.Cve_Desc_Menu">
        </my-menu>
      </div>
    </li>
  </ul>
</div>