import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ApiProvider } from '../common/api.service';
import { apiEndpoints } from '../../common/constants';
import { reportMenu } from '../../interfaces/reports.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportMenuProvider {
  rptTypes: reportMenu;

  constructor(public http: HttpClient, private api: ApiProvider) {

  }

  getList() {

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getReportType;
      this.api.post(endpoint).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            this.rptTypes = data ? data : {};
            resolve(this.rptTypes);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }
}
