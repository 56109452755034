import { NgModule } from '@angular/core';

import { ClipTextPipe } from '../pipes/clip-text/clip-text.pipe';
import { CustomTitleCasePipe } from '../pipes/custom-title-case/custom-title-case.pipe';
import { FloatPipe } from '../pipes/float/float.pipe';
import { SortByPipe } from '../pipes/sortBy/sortBy.pipe';
import { OrderByPipe } from '../pipes/orderBy/orderBy.pipe';
import { OrderByAscPipe } from '../pipes/orderBy/orderByAsc.pipe';

@NgModule({
    declarations: [
        FloatPipe,
        SortByPipe,
        OrderByPipe,
        OrderByAscPipe,
        CustomTitleCasePipe,
        ClipTextPipe,
    ],
    exports: [
        FloatPipe,
        SortByPipe,
        OrderByPipe,
        OrderByAscPipe,
        CustomTitleCasePipe,
        ClipTextPipe,
    ]
})

export class PipesComponentModule { }
