import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Overlay, CloseScrollStrategy } from '@angular/cdk/overlay';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AgmCoreModule } from '@agm/core';
import { IonicSelectableModule } from 'ionic-selectable';

import { CsiApp } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiInterceptor } from './common/api.interceptor';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Pipes
import { DatePipe, registerLocaleData } from '@angular/common';
import { PipesComponentModule } from './modules/pipesComponent.modules';

//Components
import { NavigationComponentsModule } from './modules/navigationComponents.modules';

// Register Mexico locale data
import localeEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localeEsMx);

//Providers
import { Events } from './providers/common/events.service';
import { DataService } from './providers/common/data.service';
import { DataResolverService } from './resolver/data-resolver/data-resolver.service';
import { UtilityFunctionService } from './providers/common/utilityFunction.service';
import { URLService } from './providers/common/url.service';
import { AlertasService } from './providers/alertas-service/alertas.service';
import { AlertProvider } from './providers/alerts/alert.service';
import { ToastProvider } from './providers/toast/toast.service';
import { ApiProvider } from './providers/common/api.service';
import { AppStorageProvider } from './providers/common/app-storage.service';
import { ConsultFieldsService } from './providers/consult-fields-service/consult-fields.service';
import { ConsultaCDProvider } from './providers/consulta-cd/consulta-cd.service';
import { ConsultasCivarService } from './providers/consultas-civar-service/consultas-civar.service';
import { DashboardsService } from './providers/dashboards/dashboards.service';
import { DashboardBSCServiceService } from './providers/dashboardBSC/dashboard-bscservice.service';
import { DetailCdProvider } from './providers/detail-cd/detail-cd.service';
import { ErrorService } from './providers/error-service/error.service';
import { ExchangeProvider } from './providers/exchange/exchange.service';
import { FavoriteHomeProvider } from './providers/favorite-home/favorite-home-service.service';
import { HomeProvider } from './providers/home/home.service';
import { InvestorRelationsService } from './providers/investor-relations-service/investor-relations.service';
import { LoginProvider } from './providers/login/login.service';
import { MatrizEstatusService } from './providers/matriz-estatus-service/matriz-estatus.service';
import { MultipleVinService } from './providers/multiple-vin-service/multiple-vin.service';
import { NotificationsProvider } from './providers/notifications/notifications.service';
import { PatiosService } from './providers/patios/patios.service';
import { PreferencesProvider } from './providers/preferences/preferences.service';
import { ReportListProvider } from './providers/report-list/report-list.service';
import { ReportMenuProvider } from './providers/report-menu/report-menu.service';
import { ReportViewerProvider } from './providers/report-viewer/report-viewer.service';
import { ScoreProvider } from './providers/score/score.service';
import { SessionProvider } from './providers/session/session.service';
import { TractoresService } from './providers/tractores-service/tractores-service.service';
import { TrakingMapProvider } from './providers/traking-map/traking-map.service';
import { TravelDetailProvider } from './providers/travel-detail/travel-detail.service';
import { TravelListProvider } from './providers/travel-list/travel-list.service';
import { UbicacionProvider } from './providers/ubicacion/ubicacion.service';
import { UserProvider } from './providers/user/user.service';
import { VehicleDetailProvider } from './providers/vehicle-detail/vehicle-detail.service';
import { VinListProvider } from './providers/vin-list/vin-list.service';
import { ShipProvider } from './providers/ship-service/ship.service';
import { RateTripsService } from './providers/rate-trip/rate-trips.service';
import { TranslateConfigService } from './providers/common/translate-config.service';
import { IdiomasAppService } from './providers/idiomas/idiomas-app.service';
import { AppVersionCheckService } from './providers/common/appVersionCheck.service';

import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { NgxEchartsModule } from 'ngx-echarts';

import { QRCodeModule } from 'angularx-qrcode';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [CsiApp],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollPadding: false,
      scrollAssist: true,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCV4LrTyIasQp2p22kxCwzFlAALLs9eXCI', //SUNUS
      // apiKey: "AIzaSyBdl4D6HY15_LrCDoe0QZBwScCCe3aONZ0" //Ruben
    }),
    IonicSelectableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NavigationComponentsModule,
    PipesComponentModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    QRCodeModule,
  ],
  providers: [
    CsiApp,
    Events,
    DataService,
    DataResolverService,
    UtilityFunctionService,
    URLService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    DatePipe,
    NavParams,
    ApiProvider,
    AppStorageProvider,
    FavoriteHomeProvider,
    HomeProvider,
    LoginProvider,
    NotificationsProvider,
    SessionProvider,
    UserProvider,
    TravelListProvider,
    ScoreProvider,
    TravelDetailProvider,
    UbicacionProvider,
    TrakingMapProvider,
    VehicleDetailProvider,
    ConsultaCDProvider,
    ReportListProvider,
    ReportMenuProvider,
    ReportViewerProvider,
    PreferencesProvider,
    DetailCdProvider,
    VinListProvider,
    AlertProvider,
    ToastProvider,
    ExchangeProvider,
    MultipleVinService,
    ConsultFieldsService,
    ConsultasCivarService,
    MatrizEstatusService,
    AlertasService,
    TractoresService,
    ErrorService,
    DashboardsService,
    DashboardBSCServiceService,
    PatiosService,
    ShipProvider,
    InvestorRelationsService,
    RateTripsService,
    ScreenOrientation,
    TranslateConfigService,
    IdiomasAppService,
    AppVersionCheckService
  ],
  bootstrap: [CsiApp],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
