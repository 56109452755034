import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

import { Howl } from 'howler';

@Component({
  selector: 'app-modal-splash-screen',
  templateUrl: './modal-splash-screen.component.html',
  styleUrls: ['./modal-splash-screen.component.scss'],
})
export class SplashScreenModal implements OnInit {

  private soundAssets = "./assets/sounds/CSI_Sound.mp3";
  private player: Howl = null;

  constructor(private modalCtrl: ModalController) {

  }

  ngOnInit() {
    this.player = new Howl({
      src: this.soundAssets,
      loop: false,
      volume: 0.6,
    });

    this.player.play();


    setTimeout(() => {
      this.onPlayerDestroy();
      this.modalCtrl.dismiss();
    }, 5000);
  }

  onPlayerDestroy() {
    this.player.stop();
    this.player = null;
  }

}
