import { Component, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Events } from 'src/app/providers/common/events.service';
import { AppStorageProvider } from './.././../providers/common/app-storage.service';
import { SessionProvider } from "./../../providers/session/session.service";
import { DashboardsService } from "../../providers/dashboards/dashboards.service";

// declare var zip:any;

@Component({
  selector: 'csi-mobilemenu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})

export class MobileMenuComponent implements OnDestroy {
  @Output() sendNav = new EventEmitter<any>();
  @Input() activeMenuItem: string = "";

  //menu configurable
  showTrackingMap: boolean = false;
  showTravelList: boolean = false;
  showVesselTravelList: boolean = false;
  showScore: boolean = false;
  showClaims: boolean = false;
  showConfig: boolean = true;
  showRateTrip: boolean = false;
  //showConsultaCD: boolean = false
  showReport: boolean = false;
  showSingleQueryVin: boolean = false;
  showHome: boolean = true;
  showMultipleVinConsult: boolean = true;
  showConsultByDealer: boolean = true;
  showConsultBySiteMexico: boolean = true;

  showExchange: boolean = false;
  showShipmentRequest: boolean = false;

  showAssetsManager: boolean = false;
  showMatrizEstatusEquipo: boolean = false;
  showProgramacionCarga: boolean = false;

  showAlertasPage: boolean = false;
  showConsultaDeTractor: boolean = false;

  showInvestorRelations: boolean = false;

  showDrive: boolean = false;
  showFleet: boolean = false;
  showOntrack: boolean = false;
  showYard: boolean = false;

  goToRating = ""
  ratingsParam = ""
  isAuth: boolean = false;

  showQuantumMenu: boolean = false;
  @Input("civarMenu") civarMenu: any[] = [];
  @ViewChild("submenuElem") submenuElem;
  public showCivarMenu: boolean[] = [];
  @ViewChildren("civarElem") civarElems: QueryList<ElementRef>;
  addedEventListener: boolean = false;
  @ViewChild("homeSubmenuElem") private homeSubmenuElem: ElementRef;
  @ViewChild("dashboardBSCSubmenuElem") dashboardBSCSubmenuElem;
  showHomeSubmenu: boolean = true;
  showDashboardBSCSubmenu: boolean = true;
  favouriteDashboard: string = "";
  dashboardFinancieroActive: boolean = false;
  dashboardOperativoActive: boolean = false;
  dashboardAMDF: boolean = false;
  dashboardMazda: boolean = false;
  dashboardBMW: boolean = false;
  multipleDashboardActive: boolean = false;
  multipleDashboardBSCActive: boolean = false;
  dashboardBSC_CPVActive: boolean = false;
  dashboardBSC_CSIActive: boolean = false;
  dashboardBSC_CSIDActive: boolean = false;
  dashboardBSC_CSIXActive: boolean = false;
  dashboardBSC_SunusActive: boolean = false;
  showDashboardBSC: boolean = false;
  currentDashboard: string = "";
  currentDashboardBSC: string = "";
  HOME_SUBMENU_DEFAULT_HEIGHT = 104;

  userFavHomePage: number = 0;

  // Variables para el menu colapsable
  showMoreMenu: boolean = false;
  showMoreMenuDetails: boolean = true;
  @ViewChild("moreMenuSubmenuElem") moreMenuSubmenuElem;


  showTrackingMap_Collapsible: boolean = false;
  showTravelList_Collapsible: boolean = false;
  showScore_Collapsible: boolean = false;
  showConsultBySiteMexico_Collapsible: boolean = false;
  showReport_Collapsible: boolean = false;
  showExchange_Collapsible: boolean = false;
  showMultipleVinConsult_Collapsible: boolean = false;
  showMatrizEstatusEquipo_Collapsible: boolean = false;
  showProgramacionCarga_Collapsible: boolean = false;
  showAlertasPage_Collapsible: boolean = false;
  showShipmentRequest_Collapsible: boolean = false;
  showConsultaDeTractor_Collapsible: boolean = false;
  showInvestorRelations_Collapsible: boolean = false;
  showAssetsManager_Collapsible: boolean = false;
  dashboardBSC_CSIActive_Collapsible: boolean = false;
  dashboardBSC_SunusActive_Collapsible: boolean = false;
  dashboardBSC_CPVActive_Collapsible: boolean = false;
  dashboardBSC_CSIDActive_Collapsible: boolean = false;
  dashboardBSC_CSIXActive_Collapsible: boolean = false;
  showRateTrip_Collapsible: boolean = false;

  constructor(
    private router: Router,
    private sessionProvider: SessionProvider,
    private storage: AppStorageProvider,
    public events: Events,
    private dashboardsService: DashboardsService,
    private renderer: Renderer2
  ) {
    this.initializeApp();

    events.subscribe('menuLoad', (time) => {
      // user and time are the same arguments passed in `events.publish(user, time)`
      this.initializeMenu();
    });

    /* this.events.subscribe("menu:dashboardoperativo:setstate", (state: boolean) => {
      this.dashboardOperativoActive = state;
      if (this.dashboardOperativoActive && this.dashboardFinancieroActive && this.dashboardAMDF) {
        // this.getFavouriteDashboard();
        this.showHomeSubmenu = true;
        if (this.showQuantumMenu) {
          this.submenuElem.nativeElement.style.maxHeight = ((this.submenuElem.nativeElement.scrollHeight || parseFloat(this.submenuElem.nativeElement.style.maxHeight)) + (this.homeSubmenuElem.nativeElement.style.maxHeight || this.HOME_SUBMENU_DEFAULT_HEIGHT)) + "px";
        }
      }
    });
    this.events.subscribe("menu:dashboardfinanciero:setstate", (state: boolean) => {
      this.dashboardFinancieroActive = state;
      if (this.dashboardOperativoActive && this.dashboardFinancieroActive && this.dashboardAMDF) {
        // this.getFavouriteDashboard();
        this.showHomeSubmenu = true;
        if (this.showQuantumMenu) {
          this.submenuElem.nativeElement.style.maxHeight = ((this.submenuElem.nativeElement.scrollHeight || parseFloat(this.submenuElem.nativeElement.style.maxHeight)) + (this.homeSubmenuElem.nativeElement.style.maxHeight || this.HOME_SUBMENU_DEFAULT_HEIGHT)) + "px";
        }
      }
    }); */
  }

  ngOnDestroy() {
    // this.events.destroy("menu:dashboardoperativo:setstate");
    // this.events.destroy("menu:dashboardfinanciero:setstate");
  }

  /*
  // Esta sección esta documentada al ya no se utilizada para cargar los Dashboards y determinar cual es el favorito seleccionado por el usuario
  async initDashboards(){
    await this.getDashboardFinancieroActive();
    await this.getDashboardOperativoActive();
    if(this.dashboardFinancieroActive && this.dashboardOperativoActive){
      this.getFavouriteDashboard();
    }
  }

  getFavouriteDashboard(){
    this.dashboardsService.getDashboardFavorito().then((dashboard: string) => {
      this.currentDashboard = dashboard;
      this.favouriteDashboard = dashboard;
    }).catch(err => {
      console.error(err);
    });
  }

  async getDashboardFinancieroActive(){
    this.dashboardFinancieroActive = await this.dashboardsService.areActivated();
  }

  async getDashboardOperativoActive(){
    this.dashboardOperativoActive = await this.dashboardsService.areActivatedOperativo();
  }
  */

  toggleMenu(isCivar: boolean, idx: number = 0) {
    if (isCivar) {
      this.showQuantumMenu = false;
      if (this.submenuElem && this.submenuElem.nativeElement) {
        this.submenuElem.nativeElement.style.maxHeight = "0px";
      }
      const otherElems = this.civarElems.filter((el, i) => {
        if (i !== idx) this.showCivarMenu[i] = false;
        return i !== idx;
      });
      otherElems.forEach(el => {
        el.nativeElement.style.maxHeight = "0px";
      });
      this.showCivarMenu[idx] = !this.showCivarMenu[idx];
      const elem = this.civarElems.find((el, i) => i === idx);
      elem.nativeElement.style.maxHeight = this.showCivarMenu[idx] ? elem.nativeElement.scrollHeight + "px" : "0px";
      if (!this.showCivarMenu) this.events.publish("menues:close", 1);
    } else {
      for (let index = 0; index < this.showCivarMenu.length; index++) {
        this.showCivarMenu[index] = false;
      }
      this.events.publish("menues:close", 1);

      this.civarElems.forEach(el => {
        el.nativeElement.style.maxHeight = "0px";
      });

      this.showQuantumMenu = !this.showQuantumMenu;
      if (this.submenuElem && this.submenuElem.nativeElement) {
        this.submenuElem.nativeElement.style.maxHeight = this.showQuantumMenu ? this.submenuElem.nativeElement.scrollHeight + "px" : "0px";
      }
    }
  }

  resizeContainer(ev: any, idx: number = 0) {
    const elem = this.civarElems.find((el, i) => idx === i);
    elem.nativeElement.style.maxHeight = (elem.nativeElement.scrollHeight + ev) + "px";
  }

  onClose() {
    if (this.submenuElem && this.submenuElem.nativeElement) {
      this.submenuElem.nativeElement.style.maxHeight = "0px";
    }
    this.showQuantumMenu = false;

    this.civarElems.forEach(el => {
      el.nativeElement.style.maxHeight = "0px";
    });

    for (let index = 0; index < this.showCivarMenu.length; index++) {
      this.showCivarMenu[index] = false;
    }

    this.events.publish("menues:close", 1);
  }

  initializeApp() {
    this.sessionProvider.load().then(() => {
      if (this.sessionProvider.isAuthenticated()) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    });
  }

  initializeMenu() {
    this.storage.get('userMenu').then((data: any) => {
      if (data) {
        if (data.UserProfile != 1 && data.UserProfile && this.isAuth) {
          // this.rootPage = ConsultaCdPage
          //this.showConsultaCD = true;
          this.showConsultBySiteMexico = true;
          this.showConfig = true;
          this.showHome = false;
          this.showExchange = true;
          this.showShipmentRequest = true;
        }
        else if (data.Preferencias) {
          // this.rootPage = HomePage;
          this.showHome = true;
          for (const item of data.Preferencias) {
            switch (item.Id_Preferencia) {
              case '1':
                this.showTrackingMap = item.Bit_Collapsible ? true : false;
                this.showTrackingMap_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 2 : this.userFavHomePage;
                break;
              case '2':
                //notifications center
                break;
              case '3':
                this.showTravelList = this.showVesselTravelList = item.Bit_Collapsible ? true : false;
                this.showTravelList_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 3 : this.userFavHomePage;
                break;
              case '4':
                this.showScore = item.Bit_Collapsible ? true : false;
                this.showScore_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 4 : this.userFavHomePage;
                break;
              case '5':
                this.showClaims = true;
                this.userFavHomePage = item.Bit_Default ? 5 : this.userFavHomePage;
                break;
              case '6':
                this.showConfig = true;
                break;
              case '7':
                //help
                break;
              case '8':
                this.showSingleQueryVin = true;
                this.userFavHomePage = item.Bit_Default ? 8 : this.userFavHomePage;
                break;
              case '9':
                this.showConsultBySiteMexico = item.Bit_Collapsible ? true : false;
                this.showConsultBySiteMexico_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 9 : this.userFavHomePage;
                break;
              case '10':
                this.showReport = item.Bit_Collapsible ? true : false;
                this.showReport_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 10 : this.userFavHomePage;
                break;
              case '11':
                this.showExchange = item.Bit_Collapsible ? true : false;
                this.showExchange_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 11 : this.userFavHomePage;
                break;
              case '12':
                this.showMultipleVinConsult = item.Bit_Collapsible ? true : false;
                this.showMultipleVinConsult_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 12 : this.userFavHomePage;
                break;
              case '13':
                this.showConsultByDealer = item.Bit_Collapsible ? true : false;

                this.userFavHomePage = item.Bit_Default ? 13 : this.userFavHomePage;
                break;
              case '15':
                this.showMatrizEstatusEquipo = item.Bit_Collapsible ? true : false;
                this.showMatrizEstatusEquipo_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 15 : this.userFavHomePage;
                break;
              case '16':
                this.showProgramacionCarga = item.Bit_Collapsible ? true : false;
                this.showProgramacionCarga_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 16 : this.userFavHomePage;
                break;
              case '17':
                this.showAlertasPage = item.Bit_Collapsible ? true : false;
                this.showAlertasPage_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 17 : this.userFavHomePage;
                break;
              case '20':
                this.showShipmentRequest = item.Bit_Collapsible ? true : false;
                this.showShipmentRequest_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 20 : this.userFavHomePage;
                break;
              case '21':
                this.showConsultaDeTractor = item.Bit_Collapsible ? true : false;
                this.showConsultaDeTractor_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 21 : this.userFavHomePage;
                break;
              case '22':
                this.showInvestorRelations = item.Bit_Collapsible ? true : false;
                this.showInvestorRelations_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 22 : this.userFavHomePage;
                break;
              case '23':
                this.showAssetsManager = item.Bit_Collapsible ? true : false;
                this.showAssetsManager_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 23 : this.userFavHomePage;
                break;
              case '24':
                this.showDashboardBSC = this.dashboardBSC_CSIActive = true;

                this.userFavHomePage = item.Bit_Default ? -11 : this.userFavHomePage;
                break;
              case '25':
                this.showDashboardBSC = this.dashboardBSC_SunusActive = true;

                this.userFavHomePage = item.Bit_Default ? -14 : this.userFavHomePage;
                break;
              case '26':
                this.showDashboardBSC = this.dashboardBSC_CPVActive = true;

                this.userFavHomePage = item.Bit_Default ? -10 : this.userFavHomePage;
                break;
              case '27':
                this.showDashboardBSC = this.dashboardBSC_CSIDActive = true;

                this.userFavHomePage = item.Bit_Default ? -12 : this.userFavHomePage;
                break;
              case '28':
                this.showDashboardBSC = this.dashboardBSC_CSIXActive = true;

                this.userFavHomePage = item.Bit_Default ? -13 : this.userFavHomePage;
                break;
              case '29':
                this.showRateTrip = item.Bit_Collapsible ? true : false;
                this.showRateTrip_Collapsible = item.Bit_Collapsible ? false : true;

                this.userFavHomePage = item.Bit_Default ? 24 : this.userFavHomePage;
                break;
              default:
                break;
            }
          }
        } else {
          // this.rootPage = LoginPage;
        }

        const route = this.router.url;
        if (route.includes('/dashboard_bsc/')) this.onRouteIsDashboarBSC(this.router.url.replace('/dashboard_bsc/', ''));

        // El siguiente bloque de codigo se encarga de mostrar en el menu los dashboards
        if (data.Dashboards) {
          console.log('Loading Dashboards...');
          for (const dashboard of data['Dashboards']) {
            switch (dashboard.Id_Dashboard) {
              case '1': // Dashboard Financiero 
                this.dashboardFinancieroActive = true; // De esta forma indicamos que el dashboard esta habilitado.
                if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'financiero'; // Indicamos cual es el dashboard favorito del usuario.
                break;
              case '2': // Dashboard Operativo 
                this.dashboardOperativoActive = true; // De esta forma indicamos que el dashboard esta habilitado.
                if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'operativo'; // Indicamos cual es el dashboard favorito del usuario.
                break;
              case '3': // Dashboard AMDF 
                this.dashboardAMDF = true; // De esta forma indicamos que el dashboard esta habilitado.
                if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'amdf'; // Indicamos cual es el dashboard favorito del usuario.
                break;
              case '4': // Dashboard Mazda 
                this.dashboardMazda = true; // De esta forma indicamos que el dashboard esta habilitado.
                if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'mazda'; // Indicamos cual es el dashboard favorito del usuario.
                break;
              case '5': // Dashboard BMW 
                this.dashboardBMW = true; // De esta forma indicamos que el dashboard esta habilitado.
                if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'bmw'; // Indicamos cual es el dashboard favorito del usuario.
                break;
              default:
                break;
            }
          }

          data.Preferencias.forEach((pantalla: any) => {
            if (!pantalla.Bit_Collapsible) {
              this.showMoreMenu = true;
              /* 
                          if (pantalla.Id_Preferencia == "1") {
                            this.showTrackingMap_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "3") {
                            this.showTravelList_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "4") {
                            this.showScore_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "9") {
                            this.showConsultBySiteMexico_Collapsible = true
                          }
                          if (pantalla.Id_Preferencia == "10") {
                            this.showReport_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "11") {
                            this.showExchange_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "12") {
                            this.showMultipleVinConsult_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "15") {
                            this.showMatrizEstatusEquipo_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "16") {
                            this.showProgramacionCarga_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "17") {
                            this.showAlertasPage_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "20") {
                            this.showShipmentRequest_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "21") {
                            this.showConsultaDeTractor_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "22") {
                            this.showInvestorRelations_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "23") {
                            this.showAssetsManager_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "24") {
                            this.dashboardBSC_CSIActive_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "25") {
                            this.dashboardBSC_SunusActive_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "26") {
                            this.dashboardBSC_CPVActive_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "27") {
                            this.dashboardBSC_CSIDActive_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "28") {
                            this.dashboardBSC_CSIXActive_Collapsible = true;
                          }
                          if (pantalla.Id_Preferencia == "29") {
                            this.showRateTrip_Collapsible = true;
                          } */
            }
          });
          
          this.enableHomeSubMenuShow(this.dashboardFinancieroActive, this.dashboardOperativoActive, this.dashboardAMDF, this.dashboardMazda, this.dashboardBMW);
          // await this.initDashboards();
        }
      }
    });
  }

  onRouteIsDashboarBSC(currentDashboard) {
    this.currentDashboardBSC = currentDashboard;
  }

  enableHomeSubMenuShow(activeFinanciero: boolean, activeOperativo: boolean, activeAMDF: boolean, activeMazda: boolean, activeBMW) {
    let arrayDashboard = [
      { id: 1, bool: activeFinanciero },
      { id: 2, bool: activeOperativo },
      { id: 3, bool: activeAMDF },
      { id: 4, bool: activeMazda },
      { id: 5, bool: activeBMW },
    ];

    let activeDashboard: number = 0;
    let deactiveDashboard: number = 0;

    arrayDashboard.forEach((dashboardItem) => {
      if (this.check(dashboardItem)) {
        activeDashboard++;
      } else {
        deactiveDashboard++;
      }
    });

    if (arrayDashboard.every(this.check)) {
      console.log("all active");
      this.multipleDashboardActive = true;
    } else {
      if (activeDashboard > 1 && activeDashboard <= 4) {
        console.log("active more than one");
        this.multipleDashboardActive = true;
      } else {
        this.multipleDashboardActive = false;
      }
    }
  }

  check(item) {
    return item.bool === true || false;
  }

  toggleHomeSubmenu() {
    let arrayDashboard = [
      { id: 1, bool: this.dashboardFinancieroActive },
      { id: 2, bool: this.dashboardOperativoActive },
      { id: 3, bool: this.dashboardAMDF },
      { id: 4, bool: this.dashboardMazda },
      { id: 5, bool: this.dashboardBMW },
    ];

    let activeDashboard: number = 0;
    let deactiveDashboard: number = 0;

    arrayDashboard.forEach((dashboardItem) => {
      if (this.check(dashboardItem)) {
        activeDashboard++;
      } else {
        deactiveDashboard++;
      }
    });

    if (arrayDashboard.every(this.check) || (activeDashboard > 1 && activeDashboard <= 4)) {
      this.showHomeSubmenu = !this.showHomeSubmenu;
      // this.homeSubmenuElem.nativeElement.style.maxHeight = this.showHomeSubmenu ? this.homeSubmenuElem.nativeElement.scrollHeight + "px" : "0px";

      this.renderer.setStyle(this.homeSubmenuElem.nativeElement, 'maxHeight', (this.showHomeSubmenu ? this.homeSubmenuElem.nativeElement.scrollHeight + 'px' : '0px'))

      this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + this.homeSubmenuElem.nativeElement.style.maxHeight) + "px";
    } else {
      if (this.dashboardFinancieroActive) {
        this.goToDashboardFinanciero();
      }
      else if (this.dashboardOperativoActive) {
        this.goToDashboardOperativo();
      }
      else if (this.dashboardAMDF) {
        this.goToDashboardAMDF();
      }
      else if (this.dashboardMazda) {
        this.goToDashboardMazda();
      }
      else if (this.dashboardBMW) {
        this.goToDashboardBMW();
      }
      /* else {
        this.goToHome();
      } */
    }
  }

  toggleDashboardBSCSubmenu() {
    this.showDashboardBSCSubmenu = !this.showDashboardBSCSubmenu;
    this.dashboardBSCSubmenuElem.nativeElement.style.maxHeight = this.showDashboardBSCSubmenu ? this.dashboardBSCSubmenuElem.nativeElement.scrollHeight + "px" : "0px";
    this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + this.dashboardBSCSubmenuElem.nativeElement.style.maxHeight) + "px";
  }

  toggleShowMoreSubmenu() {
    this.showMoreMenuDetails = !this.showMoreMenuDetails;
    this.moreMenuSubmenuElem.nativeElement.style.maxHeight = this.showMoreMenuDetails ? this.moreMenuSubmenuElem.nativeElement.scrollHeight + "px" : "0px";
    this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + this.moreMenuSubmenuElem.nativeElement.style.maxHeight) + "px";
  }

  async selectFavouriteDashboard(dashboard: string) {
    (await this.dashboardsService.setDashboardFavorito(dashboard)).subscribe(data => {
      this.favouriteDashboard = dashboard;
    }, err => {
      console.error(err);
    });
  }

  goToDashboardFinanciero() {
    this.currentDashboard = "financiero";
    this.sendNav.emit(-1);
  }

  goToDashboardOperativo() {
    this.currentDashboard = "operativo";
    this.sendNav.emit(-2);
  }

  goToDashboardAMDF() {
    this.currentDashboard = "amdf";
    this.sendNav.emit(-3);
  }

  goToDashboardMazda() {
    this.currentDashboard = "mazda";
    this.sendNav.emit(-4);
  }

  goToDashboardBMW() {
    this.currentDashboard = "bmw";
    this.sendNav.emit(-5);
  }

  goToDashboardBSCCPV() {
    this.currentDashboardBSC = "cpv";
    this.sendNav.emit(-10);
  }

  goToDashboardBSCCSI() {
    this.currentDashboardBSC = "csi";
    this.sendNav.emit(-11);
  }

  goToDashboardBSCCSID() {
    this.currentDashboardBSC = "csid";
    this.sendNav.emit(-12);
  }

  goToDashboardBSCCSIX() {
    this.currentDashboardBSC = "csix";
    this.sendNav.emit(-13);
  }

  goToDashboardBSCSunus() {
    this.currentDashboardBSC = "sunus";
    this.sendNav.emit(-14);
  }

  goToHome() {
    if (this.userFavHomePage !== 0) {
      // console.log('Valor: ', this.userFavHomePage);

      this.sendNav.emit(this.userFavHomePage);
    } else {
      this.sendNav.emit(1);
    }

  }

  goToTrackingMap() {
    // this.rootPage = TrackingMapPage;
    this.sendNav.emit(2)
  }

  goToTravelList() {
    // this.rootPage = TravelListPage, { travelType: 1 };
    this.sendNav.emit(3);
  }

  goToVesselTravelList() {
    this.sendNav.emit(25);
  }

  goToScore() {
    // this.rootPage = TravelListPage, { travelType: 3 };
    this.sendNav.emit(4);
  }

  goToClaims() {
    // this.rootPage = ClaimsPage;
    this.sendNav.emit(5)
  }

  goToConfig() {
    // this.rootPage = ConfigPage;
    this.sendNav.emit(6)
  }

  goToSingleQueryVin() {
    // this.rootPage = ConfigPage;
    this.sendNav.emit(8)
  }

  goToConsultBySiteMexico() {
    this.sendNav.emit(9);
  }

  goToReports() {
    // this.rootPage = ReportMenuPage;
    this.sendNav.emit(10);
  }

  goToExchange() {
    this.sendNav.emit(11);
  }

  goToShipmentRequest() {
    this.sendNav.emit(20);
  }

  goToMultipleVinConsult() {
    this.sendNav.emit(12);
  }

  goToConsultByDealer() {
    this.sendNav.emit(13);
  }

  logout() {
    this.sendNav.emit(14)
  }

  goToMatrizEstatusEquipo() {
    this.sendNav.emit(15);
  }

  goToProgramacionCarga() {
    this.sendNav.emit(16);
  }

  goToAlertasPage() {
    this.sendNav.emit(17);
  }

  goToConsultaDeTractor() {
    this.sendNav.emit(21);
  }

  goToInvestorRelationsPage() {
    this.sendNav.emit(22);
  }

  goToAssetsManagerPage() {
    this.sendNav.emit(23);
  }

  goToRateTrip() {
    this.sendNav.emit(24);
  }

  goToWebFrame() {
    this.sendNav.emit(100);
  }

  getIconNameCivarMenu(nameCivar: string) {
    let nameIcon: string = 'earth';

    switch (nameCivar) {
      case 'ADMINISTRACION':
        nameIcon = 'person-circle-outline';
        break;
      case 'OPERACION':
        nameIcon = 'earth';
        break;
      case 'CALIDAD':
        nameIcon = 'medal-outline';
        break;
      case 'RH':
        nameIcon = 'people-outline';
        break;
      case 'TI':
        nameIcon = 'server-outline';
        break;
    }

    return nameIcon;
  }
}
