import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { ApiProvider } from '../common/api.service';
import { consultasMatriz, consultasMatrizUrl } from '../../common/constants';

/**
 * @name MatrizEstatusService
 * @desc Servicio usado para hacer las peticiones de las vistas de matris-estatus-equipo
 */
@Injectable({
  providedIn: 'root'
})
export class MatrizEstatusService {
  private matrizEstatusFields: any = {
    "Desc_Estatus": "Estatus",
    "CDDescripcion": "Ciudad"
  }

  constructor(private api: ApiProvider) { }

  /**
   * @name translate
   * @desc Funcion que traduce el id de un campo a su valor de texto
   */
  translate(val: string): string {
    return this.matrizEstatusFields[val] || val;
  }

  /**
   * @name getMatrizData
   * @desc Funcion que obtiene la informacion de MatrizEstatusEquipoPage
   */
  getMatrizData(filters: any) {
    return this.api.post(consultasMatriz.resumen, filters, consultasMatrizUrl);
  }

  /**
   * @name getTipoEquipo
   * @desc Funcion que obtiene la informacion del filtro TipoEquipo
   */
  getTipoEquipo() {
    return new Promise((resolve, reject) => {
      resolve([{
        value: "T",
        text: "Todos"
      }, {
        value: "S",
        text: "Shuttle"
      }, {
        value: "N",
        text: "Nacional"
      }]);
    });
  }

  /**
   * @name getIdCD
   * @desc Funcion que obtiene la informacion del filtro IdCD
   */
  getIdCD() {
    return this.api.post(consultasMatriz.cd, {}, consultasMatrizUrl).pipe(first()).toPromise();
  }

  /**
   * @name getEstatus
   * @desc Funcion que obtiene la informacion del filtro Estatus
   */
  getEstatus() {
    return this.api.post(consultasMatriz.estatus, {}, consultasMatrizUrl).pipe(first()).toPromise();
  }

  /**
   * @name getEconomico
   * @desc Funcion que obtiene la informacion del filtro Economico
   */
  getEconomico() {
    return this.api.post(consultasMatriz.economico, {}, consultasMatrizUrl).pipe(first()).toPromise();
  }

  /**
   * @name getCliente
   * @desc Funcion que obtiene la informacion del filtro Cliente
   */
  getCliente() {
    return this.api.post(consultasMatriz.cliente, {}, consultasMatrizUrl).pipe(first()).toPromise();
  }

  /**
   * @name getDetails
   * @desc Funcion que obtiene la informacion del detalle del resumen de trafico
   */
  getDetails(IdCD: string, IdEstatus: string) {
    return this.api.post(consultasMatriz.detalle, {
      IdCD: IdCD,
      IdEstatus: IdEstatus
    }, consultasMatrizUrl);
  }

  /**
   * @name getProgramacionSemanal
   * @desc Funcion que obtiene la informacion de la vista ProgramacionCargaPage
   */
  getProgramacionSemanal(date: Date, filters: any) {
    const body = {
      FechaInicio: date
    }
    if (filters) {
      body["TipoEquipo"] = filters.TipoEquipo;
      body["IdCD"] = filters.IdCD;
      body["Economico"] = filters.Economico;
      body["Cliente"] = filters.Cliente;
    }
    return this.api.post(consultasMatriz.programacion, body, consultasMatrizUrl);
  }

  /**
   * @name updateProgramacion
   * @desc Funcion que actualiza la informacion de ProgramacionCargaPage
   */
  updateProgramacionSemanal(data: any) {
    return this.api.post(consultasMatriz.updateProgramacion, data, consultasMatrizUrl);
  }
}
