import { Injectable } from '@angular/core';

import { ApiProvider } from '../common/api.service';
import { alertasWss, alertasWssUrl } from '../../common/constants';

/**
 * @name AlertasService
 * @desc Servicio que se usa para todo lo referente a las alertas
 */
@Injectable({
  providedIn: 'root'
})
export class AlertasService {
  constructor(private api: ApiProvider) { }

  /**
   * @name getAlertas
   * @desc Metodo que regresa la información de las alertas
   */
  getAlertas(empresas: any[], categorias: any[]) {
    return this.api.post(alertasWss.getAlertas, {
      empresas,
      categorias
    }, alertasWssUrl);
  }

  /**
   * @name getDetalleAlertas
   * @desc Metodo que regresa los detalles de las alertas
   */
  getDetalleAlertas(idAlarma: number, clasificacion: string, empresas: any[], categorias: any[]) {
    return this.api.post(alertasWss.getDetalleAlertas, {
      Id_Cnsc_Alarma: idAlarma,
      Clasificacion: clasificacion,
      bit_Activo: 1,
      empresas,
      categorias
    }, alertasWssUrl);
  }

  /**
   * @name getListaClasificacion
   * @desc Metodo que regresa las opciones usadas en el select de clasificacion
   */
  getListaClasificacion(idAlarma: number) {
    return this.api.post(alertasWss.getListaClasificacion, {
      id_Cnsc_Alarma: idAlarma
    }, alertasWssUrl);
  }

  /**
   * @name updateAlertas
   * @desc Metodo que actualiza las alertas
   */
  updateAlertas(body) {
    return this.api.post(alertasWss.updateAlertas, body, alertasWssUrl);
  }

  getCategorias() {
    return this.api.post(alertasWss.getCategorias, {}, alertasWssUrl);
  }

  getEmpresas() {
    return this.api.post(alertasWss.getEmpresas, {}, alertasWssUrl);
  }
}
