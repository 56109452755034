import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { apiEndpoints } from "../../common/constants";
import { ApiProvider } from "../common/api.service";

import { apiURL } from "../../common/constants";

/*
  Generated class for the TravelDetailProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class TravelDetailProvider {
  truckerDetail: any;
  travelDetail: any;
  vehicles: any;

  tmpETA: any;
  tmpDestination: any;
  tmpHistory: any;
  infoDocumentsDetail: any;

  constructor(private api: ApiProvider) { }

  getTruckerInfo(idViaje) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getTrackingDetail;

      const records = {
        Trips: [
          {
            IdViaje: idViaje
          }
        ]
      };

      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.truckerDetail = data.Trips.length > 0 ? data.Trips : {};
            resolve(this.truckerDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  getTravelDetail(idViaje, type) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getTravelDetail;

      const records = {
        Trips: [
          {
            IdViaje: idViaje
          }
        ]
      };

      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            if (type == 1 /** Score **/) {
              this.travelDetail = data.Trips.length > 0 ? data.Trips : {};
            } else {
              this.travelDetail = data.Satellite.length > 0 ? data : {};
            }
            resolve(this.travelDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }


  getData(idViaje, type) {

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getTravelDetail;

      const records = {
        Trips: [
          {
            IdViaje: idViaje
          }
        ]
      };

      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            if (type == 1 /** Score **/) {
              this.travelDetail = data.Trips.length > 0 ? data.Trips : {};

              resolve(this.travelDetail);
            } else {
              if (data.Trips[0] === undefined || data.Trips[0].Info.ETA == undefined) {
                reject()
              } else {

                this.tmpETA = data.Trips[0].Info.ETA;
                this.tmpDestination = data.Trips[0].Info.Destination;
                this.tmpHistory = data.Trips[0].TravelHistory;

                var coords = data.Trips[0].Info.Cordinates;
                var trackingObj = data.Trips[0].Tracking;
                var satelliteObj = data.Satellite;

                var res = {
                  info: data.Trips[0].Info,
                  vehicles: data.Vehicles,
                  tracking: trackingObj,
                  coordsOrigin: {
                    lat: parseFloat(coords.Origin.Latitude),
                    lng: parseFloat(coords.Origin.Longitude)
                  },
                  coordsDestination: {
                    lat: parseFloat(coords.Destination.Latitude),
                    lng: parseFloat(coords.Destination.Longitude)
                  },
                  currentInfo: this._getCurrentInfo(
                    data.Trips[0].Info,
                    satelliteObj
                  ),
                  satellite: data.Satellite,
                  deliveries: this._getDeliveries(trackingObj),
                  pendingDeliveries: this._getPendingDeliveries(trackingObj),
                  travelHistory: this.tmpHistory //Phase 3 TravelHistroy ARV
                  // hasHistory: tmpFlag //Phase 3 TravelHistroy ARV


                };
                resolve(res);
              }
            }
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  getDocuments(idViaje: any) {
    return new Promise((resolve, reject) => {
      const documentsInfoRecords = {
        VIN: "",
        IdViaje: idViaje
      };
      this.api.post(apiEndpoints.getInfoDocuments, documentsInfoRecords, apiURL).subscribe((data: any) => {
        if (data.ErrorMessage) {
          reject();
        } else {
          this.infoDocumentsDetail = data ? data : {};
          resolve(this.infoDocumentsDetail);
        }
      },
        (error: HttpErrorResponse) => {
          reject();
        });
    });


  }

  _getDeliveries(trackingObj) {
    var deliveries = [];

    trackingObj.forEach(collection => {
      collection.forEach(item => {
        if (item.IdStatus === 10) {
          deliveries.push({
            statusId: item.IdStatus,
            coords: {
              lat: item.Latitude ? parseFloat(item.Latitude) : "",
              lng: item.Longitude ? parseFloat(item.Longitude) : ""
            }
          });
          //console.log("(" +item.Latitude +"," +item.Longitude +")" +" " +item.UpdateDate);
        }
      });
    });

    return deliveries;
  }

  _getPendingDeliveries(trackingObj) {
    var pendingDeliveries = [];

    trackingObj.forEach(collection => {
      collection.forEach(item => {
        if (item.IdStatus === 7) {
          if (item.Latitude !== null && item.Longitude !== null) {
            pendingDeliveries.push({
              statusId: item.IdStatus,
              coords: {
                lat: item.Latitude ? parseFloat(item.Latitude) : "",
                lng: item.Longitude ? parseFloat(item.Longitude) : ""
              }
            });
            //console.log("(" +item.Latitude +"," +item.Longitude +")" +" Pendiente " +item.UpdateDate);
          }
        }
      });
    });
    return pendingDeliveries;
  }

  _getCurrentInfo(travelInfo, satelliteObj) {
    var statusId = {
      ASIGNADO: 7,
      CARGANDO: 8,
      "EN TRANSITO": 2,
      LLEGADA: 9,
      ENTREGADO: 10
    };

    var currentPosition = null;

    for (var i = satelliteObj.length - 1; i >= 0; i--) {
      if (satelliteObj[i].Location != null) {
        currentPosition = satelliteObj[i];
        break;
      }
    }

    //console.log("(" +currentPosition.Latitude +"," +currentPosition.Longitude +")" +" corriente " +currentPosition.UpdateDate);
    return {
      statusId: statusId[travelInfo.Status],
      coords: {
        lat: currentPosition.Latitude ? currentPosition.Latitude : "",
        lng: currentPosition.Longitude ? currentPosition.Longitude : ""
      }
    };
  }

  sendData(travelId, model) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.sendScore;

      const records = {
        Trips: [
          {
            IdViaje: travelId
          }
        ],
        Qualification: model
      };

      this.api.put(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.truckerDetail = data.Trips.length > 0 ? data.Trips : {};
            resolve(this.truckerDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  getPaths(satellite, destination, statusId) {
    // fallback service erro structure
    if (!satellite.length) {
      return {};
    }

    var limit = satellite.length - 1,
      pathsObj = {};

    let index = 0;
    satellite.forEach(item => {
      if (item.Location == null) {
        item.Location = "PENDIENTE";
      }
      if (index === limit) {
        if (statusId !== 10) {
          pathsObj["p" + index] = {
            weight: 0,
            latlngs: [
              {
                lat: parseFloat(item.Latitude),
                lng: parseFloat(item.Longitude)
              },
              {
                lat: parseFloat(destination.lat),
                lng: parseFloat(destination.lng)
              },
              {
                location: item.Location
              }
            ]
          };
        }
        return true;
      }

      pathsObj["p" + index] = {
        weight: 0,
        latlngs: [
          {
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude)
          },
          {
            lat: parseFloat(
              satellite[index + 1].Latitude
                ? satellite[index + 1].Latitude
                : satellite[index].Latitude
            ),
            lng: parseFloat(
              satellite[index + 1].Longitude
                ? satellite[index + 1].Longitude
                : satellite[index].Longitude
            )
          },
          {
            location: item.Location
          }
        ]
      };
      index = index + 1;
    });

    return pathsObj;
  }

  // getPaths(originCoords, coordenCurrent, deliveries, pendings) {
  //   var /*limit = satellite.length - 1,*/
  //   pathsObj = {};
  //   let index = 0;

  //   if (deliveries.length > 0) {

  //     pathsObj["p" + index] = {
  //       weight: 0,
  //       latlngs: [
  //         {
  //           lat: parseFloat(originCoords.lat),
  //           lng: parseFloat(originCoords.lng)
  //         },
  //         {
  //           lat: parseFloat(deliveries[0].coords.lat),
  //           lng: parseFloat(deliveries[0].coords.lng)
  //         },
  //         {
  //           location: "INICIO"
  //         }
  //       ]
  //     };
  //     index = index + 1;

  //     let indexD = 0;
  //     deliveries.forEach(item => {
  //       for (let key in item) {
  //         if ((key = "coords")) {
  //           let element = item[key];

  //           if (indexD < deliveries.length - 1) {
  //             pathsObj["p" + index] = {
  //               weight: 0,
  //               latlngs: [
  //                 {
  //                   lat: parseFloat(element.lat),
  //                   lng: parseFloat(element.lng)
  //                 },
  //                 {
  //                   lat: parseFloat(
  //                     deliveries[indexD + 1].coords.lat
  //                       ? deliveries[indexD + 1].coords.lat
  //                       : deliveries[indexD].coords.lat
  //                   ),
  //                   lng: parseFloat(
  //                     deliveries[indexD + 1].coords.lng
  //                       ? deliveries[indexD + 1].coords.lng
  //                       : deliveries[indexD].coords.lng
  //                   )
  //                 },
  //                 {
  //                   location: "PENDIENTE"
  //                 }
  //               ]
  //             };
  //           }
  //         }
  //       }
  //       indexD = indexD + 1;

  //       index = index + 1;
  //     });

  //     pathsObj["p" + index] = {
  //       weight: 0,
  //       latlngs: [
  //         {

  //           lat: parseFloat(deliveries[deliveries.length - 1].coords.lat),
  //           lng: parseFloat(deliveries[deliveries.length - 1].coords.lng)
  //         },
  //         {

  //           lat: parseFloat(coordenCurrent.coords.lat),
  //           lng: parseFloat(coordenCurrent.coords.lng)
  //         },
  //         {
  //           location: "ENTREGA"
  //         }
  //       ]
  //     };
  //     index = index + 1;

  //     let indexP = 0;
  //     pendings.forEach(item => {
  //       for (let key in item) {
  //         if ((key = "coords")) {
  //           let element = item[key];

  //           if (indexP < pendings.length - 1) {
  //             pathsObj["p" + index] = {
  //               weight: 0,
  //               latlngs: [
  //                 {
  //                   lat: parseFloat(element.lat),
  //                   lng: parseFloat(element.lng)
  //                 },
  //                 {
  //                   lat: parseFloat(
  //                     pendings[indexP + 1].coords.lat
  //                       ? pendings[indexP + 1].coords.lat
  //                       : pendings[indexP].coords.lat
  //                   ),
  //                   lng: parseFloat(
  //                     pendings[indexP + 1].coords.lng
  //                       ? pendings[indexP + 1].coords.lng
  //                       : pendings[indexP].coords.lng
  //                   )
  //                 },
  //                 {
  //                   location: "PENDIENTE"
  //                 }
  //               ]
  //             };
  //           }
  //         }
  //       }
  //       indexP = indexP + 1;

  //       index = index + 1;
  //     });
  //   }else{
  //     pathsObj["p" + index] = {
  //       weight: 0,
  //       latlngs: [
  //         {
  //           lat: parseFloat(coordenCurrent.coords.lat),
  //           lng: parseFloat(coordenCurrent.coords.lng)
  //         },
  //         {
  //           lat: parseFloat(pendings[0].coords.lat),
  //           lng: parseFloat(pendings[0].coords.lng)
  //         },
  //         {
  //           location: "ENTREGA"
  //         }
  //       ]
  //     };
  //     index = index + 1;

  //     let indexP = 0;
  //     pendings.forEach(item => {
  //       for (let key in item) {
  //         if ((key = "coords")) {
  //           let element = item[key];

  //           if (indexP < pendings.length - 1) {
  //             pathsObj["p" + index] = {
  //               weight: 0,
  //               latlngs: [
  //                 {
  //                   lat: parseFloat(element.lat),
  //                   lng: parseFloat(element.lng)
  //                 },
  //                 {
  //                   lat: parseFloat(
  //                     pendings[indexP + 1].coords.lat
  //                       ? pendings[indexP + 1].coords.lat
  //                       : pendings[indexP].coords.lat
  //                   ),
  //                   lng: parseFloat(
  //                     pendings[indexP + 1].coords.lng
  //                       ? pendings[indexP + 1].coords.lng
  //                       : pendings[indexP].coords.lng
  //                   )
  //                 },
  //                 {
  //                   location: "PENDIENTE"
  //                 }
  //               ]
  //             };
  //           }
  //         }
  //       }
  //       indexP = indexP + 1;

  //       index = index + 1;
  //     });
  //   }

  //   return pathsObj;

  //   console.log("originCoords", originCoords);
  //   console.log("coordenCurrent", coordenCurrent);
  //   console.log("deliveries", deliveries);
  //   console.log("pendings", pendings);
  //   console.log("pathsObj", pathsObj);

  //   // // fallback service erro structure
  //   // if (!satellite.length) {
  //   //    return {};
  //   // }

  //   // var limit = satellite.length - 1,
  //   //   pathsObj = {};

  //   // let index = 0;
  //   // satellite.forEach(item => {
  //   //   if (item.Location == null) {
  //   //     item.Location = "PENDIENTE";
  //   //   }
  //   //   if (index === limit) {
  //   //     if (statusId !== 10) {
  //   //       pathsObj["p" + index] = {
  //   //         weight: 0,
  //   //         latlngs: [
  //   //           {
  //   //             lat: parseFloat(item.Latitude),
  //   //             lng: parseFloat(item.Longitude)
  //   //           },
  //   //           {
  //   //             lat: parseFloat(destination.lat),
  //   //             lng: parseFloat(destination.lng)
  //   //           },
  //   //           {
  //   //             location: item.Location
  //   //           }
  //   //         ]
  //   //       };
  //   //     }
  //   //     return true;
  //   //   }

  //   //   pathsObj["p" + index] = {
  //   //     weight: 0,
  //   //     latlngs: [
  //   //       {
  //   //         lat: parseFloat(item.Latitude),
  //   //         lng: parseFloat(item.Longitude)
  //   //       },
  //   //       {
  //   //         lat: parseFloat(
  //   //           satellite[index + 1].Latitude
  //   //             ? satellite[index + 1].Latitude
  //   //             : satellite[index].Latitude
  //   //         ),
  //   //         lng: parseFloat(
  //   //           satellite[index + 1].Longitude
  //   //             ? satellite[index + 1].Longitude
  //   //             : satellite[index].Longitude
  //   //         )
  //   //       },
  //   //       {
  //   //         location: item.Location
  //   //       }
  //   //     ]
  //   //   };
  //   //   index = index + 1;
  //   // });

  //   // return pathsObj;
  // }
}
