import { Injectable } from "@angular/core";

import { ApiProvider } from "../common/api.service";
import { AppStorageProvider } from "../common/app-storage.service";

import { rateTrips, apiURL } from "../../common/constants";
import { detailTripRequest, detailVINDamage, rateTrip, rateTripsRequestInfo } from "src/app/interfaces/rate-trips/rateTrips.interface";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class RateTripsService {
  constructor(private api: ApiProvider, private storage: AppStorageProvider, private datePipe: DatePipe) { }

  /**
   * @name getListRateTrips
   * @desc Metodo que regresa la lista de viajes para calificar
   */

  getListRateTrips(requestData: rateTripsRequestInfo) {

    return this.api.post(rateTrips.getDeliveredTravel, requestData, apiURL);
  }

  /**
   * @name getDetailRateTrips
   * @desc Metodo que regresa el detalle de viajes para calificar
   */

  getDetailRateTrips(requestData: detailTripRequest) {

    return this.api.post(rateTrips.getDetailDeliveredTravel, requestData, apiURL);
  }

  /**
   * @name getVinDamageListDetail
   * @desc Metodo que regresa el detalle de viajes para calificar
   */

  getVinDamageListDetail(requestData: detailVINDamage) {

    return this.api.post(rateTrips.getVinDanos, requestData, apiURL);
  }

  /**
   * @name postRateTrip
   * @desc Metodo que califica el detalle de viajes 
   */

  postRateTrip(requestData: rateTrip) {

    return this.api.post(rateTrips.postRateTrip, requestData, apiURL);
  }


  /**
   * @name getDanos
   * @desc Metodo que califica el detalle de viajes 
   */

  getDanos() {

    return this.api.post(rateTrips.getDanos, {}, apiURL);
  }

  /**
   * @name getDanos
   * @desc Metodo que califica el detalle de viajes 
   */

  postDanos(requestData) {

    return this.api.post(rateTrips.postDanos, requestData, apiURL);
  }

  /**
   * @name postEditedDanos
   * @desc Metodo que guarda los cambios registrados del daño
   */

  postEditedDanos(requestData) {
    return this.api.post(rateTrips.postEditedDanos, requestData, apiURL);
  }
}
