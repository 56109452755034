import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { MenuLatComponent } from '../components/menu-lat/menu-lat.component';
import { MyMenuComponent } from '../components/menu-lat/my-menu.component';
import { MobileMenuComponent } from '../components/mobile-menu/mobile-menu.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        MenuLatComponent,
        MobileMenuComponent,
        MyMenuComponent
    ],
    exports: [
        MenuLatComponent,
        MobileMenuComponent,
        MyMenuComponent
    ]
})

export class NavigationComponentsModule { }
