import { Injectable } from '@angular/core';
import { ApiProvider } from '../common/api.service';

import { idiomasEndpoints, idiomasUrl } from '../../common/constants';

/**
 * @name IdiomasAppService
 * @desc Servicio que se usa para todo lo referente a los dashboards de BSC
 */
@Injectable({
    providedIn: 'root'
})
export class IdiomasAppService {

    constructor(private api: ApiProvider) { }

    updateIdiomaSelected(idIdioma: number) {
        return this.api.post(idiomasEndpoints.updateIdiomaSelection, {
            Id_Num_Idioma: idIdioma,
        }, idiomasUrl);
    }
}
