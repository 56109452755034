import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { apiEndpoints } from "../../common/constants";
import { ApiProvider } from "../common/api.service";
// import { AppStorageProvider } from "../common/app-storage.provider";
import { apiAmazon } from "../../common/constants";


@Injectable({
  providedIn: 'root'
})
export class ExchangeProvider {

  marcas: any;
  familia: any;
  linea: any;
  modelo: any;
  color: any;

  constructor(private api: ApiProvider) { }

  /**
     * @name getVinsSolicitudEmbarque
     * @desc Metodo para resolver data de getVinsSolicitudEmbarque para intercambios
     * @returns {Object} - Promesa
     */
  getVinsSolicitudEmbarque(linea, familia, marca, modelo, color, vin) {
    return new Promise((resolve, reject) => {
      const records = {
        IdLinea: linea,
        IdFamilia: familia,
        IdMarca: marca,
        Modelo: modelo,
        Color: color,
        VIN: vin
      };

      const endpoint = apiEndpoints.getVinsSolicitudEmbarque;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        });
    });
  }

  /**
 * @name setSolicitudEmbarque
 * @desc Metodo para resolver data de setSolicitudEmbarque para intercambios
 * @returns {Object} - Promesa
 */
  setVinsSolicitudEmbarqueCancelar(arr) {
    return new Promise((resolve, reject) => {
      const records = [];

      arr.forEach(element => {
        records.push({ "Id_Cnsc_Solicitud": element.Id_Cnsc_Solicitud, "Cve_VIN": element.VinCodigo });
      });

      console.log(records);

      const endpoint = apiEndpoints.setVinsSolicitudEmbarqueCancelar;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        });
    });
  }

  /**
   * @name getVinsSolicitudEmbarqueActiva
   * @desc Metodo para resolver data de getVinsSolicitudEmbarqueActiva para intercambios
   * @returns {Object} - Promesa
   */

  getVinsSolicitudEmbarqueActiva(linea, familia, marca, modelo, color, vin) {
    return new Promise((resolve, reject) => {
      const records = {
        IdLinea: linea,
        IdFamilia: familia,
        IdMarca: marca,
        Modelo: modelo,
        Color: color,
        VIN: vin
      };

      const endpoint = apiEndpoints.getVinsSolicitudEmbarqueActiva;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        });
    });
  }


  /**
   * @name setSolicitudEmbarque
   * @desc Metodo para resolver data de setSolicitudEmbarque para intercambios
   * @returns {Object} - Promesa
   */
  setSolicitudEmbarque(arr) {
    return new Promise((resolve, reject) => {
      const records = [];

      arr.forEach((element, index) => {
        records.push({ "Id_Cnsc_Solicitud": index + 1, "Cve_VIN": element.VinCodigo });
        console.log(index)
      });


      const endpoint = apiEndpoints.setSolicitudEmbarque;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        });
    });
  }


  /**
   * @name getUsuarioVins
   * @desc Metodo para resolver data de getUsuarioVins para intercambios
   * @returns {Object} - Promesa
   */
  getUsuarioVins(linea, familia, marca, modelo, color, pais, estado, municipio, dealer, vin) {
    return new Promise((resolve, reject) => {
      const records = {
        IdLinea: linea,
        IdFamilia: familia,
        IdMarca: marca,
        Modelo: modelo,
        Color: color,
        IdPais: pais,
        IdEstado: estado,
        IdMunicipio: municipio,
        IdDistribuidor: dealer,
        VIN: vin
      };

      const endpoint = apiEndpoints.getUsuarioVins;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        });
    });
  }

  /**
   * @name getUsuarioDealer
   * @desc Metodo para resolver data de getUsuarioDealer para intercambios
   * @returns {Object} - Promesa
   */
  getUsuarioDealer() {
    return new Promise((resolve, reject) => {
      const records = {
      };
      const endpoint = apiEndpoints.getUsuariosDealers;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
   * @name setSolicitudes
   * @desc Metodo para resolver data de solicitudes para intercambios
   * @returns {Object} - Promesa
   */
  setSolicitudes(VIN, idDistribuidorOri, IdDistribuidorDes, Comentarios) {
    return new Promise((resolve, reject) => {
      const records = {
        idvin: VIN,
        comentarios: Comentarios,
        idDistribuidorOri: idDistribuidorOri,
        IdDistribuidorDes: IdDistribuidorDes
      };
      const endpoint = apiEndpoints.setSolicitud;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          console.log(JSON.stringify(data));
          if (data == null) {
            resolve(data)
          } else {
            if (data.ErrorMessage) {
              reject();
            }
            else {
              resolve(data)
            }
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
   * @name updateSolicitudes
   * @desc Metodo para resolver data de solicitudes para intercambios
   * @returns {Object} - Promesa
   */
  updateSolicitudes(idSolicitud, idVin, Estatus, Comentarios, idDistribuidorOri, IdDistribuidorDes
    //, folioFordCredit
  ) {
    return new Promise((resolve, reject) => {
      const records = {
        idSolicitud: idSolicitud,
        idvin: parseInt(idVin),
        Estatus: Estatus,
        comentarios: Comentarios,
        idDistribuidorOri: idDistribuidorOri,
        idDistribuidorDes: IdDistribuidorDes
        //folioFordCredit: folioFordCredit
      };
      const endpoint = apiEndpoints.updateSolicitud;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data == null) {
            resolve(data)
          } else {
            if (data.ErrorMessage) {
              reject();
            }
            else {
              resolve(data)
            }
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }



  /**
   * @name getSolicitudes
   * @desc Metodo para resolver data de solicitudes para intercambios
   * @returns {Object} - Promesa
   */
  getSolicitudes() {
    return new Promise((resolve, reject) => {
      const records = {
      };
      const endpoint = apiEndpoints.getSolicitudes;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
   * @name getMarca
   * @desc Metodo para resolver data de marcas para la forma de Intercambios.
   * @returns {Object} - Promesa
   */
  getVinMarcas() {
    return new Promise((resolve, reject) => {
      const records = {};

      const endpoint = apiEndpoints.getVinMarcas;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
  * @name getFamilia
  * @desc Metodo para resolver data de familias para la forma de Intercambios.
  * @returns {Object} - Promesa
  */
  getVinFamilias(marca) {
    return new Promise((resolve, reject) => {
      let records = {
        idMarca: null
      }

      marca != null ? records.idMarca = marca.IdMarca : records.idMarca = null

      const endpoint = apiEndpoints.getVinFamilias;

      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
* @name getLinea
* @desc Metodo para resolver data de lineas para la forma de Intercambios.
* @returns {Object} - Promesa
*/
  getVinLineas(familia) {
    return new Promise((resolve, reject) => {
      let records = {
        idMarca: null,
        idFamilia: null
      }

      if (familia != null) {
        familia.IdMarca != undefined ? records.idMarca = familia.IdMarca : records.idMarca = familia.idmarca
        familia.IdFamilia != undefined ? records.idFamilia = familia.IdFamilia : records.idFamilia = familia.idfamilia
      }

      const endpoint = apiEndpoints.getVinLineas;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
* @name getModelo
* @desc Metodo para resolver data de modelos para la forma de Intercambios.
* @returns {Object} - Promesa
*/
  getVinModelos(linea) {
    return new Promise((resolve, reject) => {
      const records = {
        idLinea: linea
      };
      const endpoint = apiEndpoints.getVinModelos;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
* @name getColores
* @desc Metodo para resolver data de colores para la forma de Intercambios.
* @returns {Object} - Promesa
*/
  getVinColores(linea, modelo) {
    return new Promise((resolve, reject) => {
      const records = {
        idLinea: linea.IdLinea,
        modelo: modelo
      };
      const endpoint = apiEndpoints.getVinColores;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  /**
* @name getLocalidades
* @desc Metodo para resolver data de colores para la forma de Intercambios.
* @returns {Object} - Promesa
*/
  getLocalidades() {
    return new Promise((resolve, reject) => {
      const records = {};

      const endpoint = apiEndpoints.getLocalidades;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

  getVinDealers(localidad) {
    return new Promise((resolve, reject) => {
      let records = {
        IdPais: null,
        IdEstado: null,
        IdMunicipio: null
      };

      if (localidad != null) {
        records.IdPais = localidad.IdPais
        records.IdEstado = localidad.IdEstado
        records.IdMunicipio = localidad.IdMunicipio
      }

      const endpoint = apiEndpoints.getVinDealers;
      this.api.post(endpoint, records, apiAmazon).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.travels = data.Trips.length > 0 ? data.Trips : null;
            // this.saveTrips(tripType);
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.message);
        }
      );
      //}
    });
  }

}
