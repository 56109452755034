import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { ApiProvider } from "../common/api.service";
import { apiURL } from "../../common/constants";
import { apiEndpoints } from "../../common/constants";

@Injectable({
  providedIn: 'root'
})
export class DetailCdProvider {
  constructor(public api: ApiProvider) { }

  getList(idCD, idEstatus, marcas) {
    var endpoint: any;
    var request = {
      VINCD: {
        idPunto: idCD,
        idEstatusVin: idEstatus,
        Marcas: marcas
      }
    };

    switch (idEstatus) {
      case 104:
      case 106:
      case 107:
      case 111:
      case 112:
        endpoint = apiEndpoints.getTrans;
        return new Promise((resolve, reject) => {
          this.api.post(endpoint, request, apiURL).subscribe(
            (data: any) => {
              if (data.ErrorMessage) {
                reject();
              } else {
                var result = data.CDTransList;
                var vins = 0;

                result.forEach(conveyance => {
                  vins += conveyance.VINs;
                });
                result.TOTALVINS = vins;

                resolve(result);
              }
            },
            (error: HttpErrorResponse) => {
              reject();
            }
          );
        });

      case 108:
      case 109:
      case 110:
        endpoint = apiEndpoints.getYards;
        return new Promise((resolve, reject) => {
          this.api.post(endpoint, request, apiURL).subscribe(
            (data: any) => {
              if (data.ErrorMessage) {
                reject();
              } else {
                var result = data.CDYardList;
                var vins = 0;

                result.forEach(yards => {
                  vins += yards.VINs;
                });
                result.TOTALVINS = vins;

                resolve(result);
              }
            },
            (error: HttpErrorResponse) => {
              reject();
            }
          );
        });

      default:
        break;
    }
  }
}
