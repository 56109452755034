import { Injectable } from '@angular/core';

import { ApiProvider } from '../common/api.service';
import { tractoresWss, tractoresWssUrl } from '../../common/constants';

/**
 * @name TractoresService
 * @desc Servicio que se usa para todo lo referente a las alertas
 */
@Injectable({
  providedIn: 'root'
})
export class TractoresService {
  constructor(private api: ApiProvider) { }

  /**
   * @name getEconomico
   * @desc Metodo que obtiene la lista de economicos
   */
  getEconomico() {
    return this.api.post(tractoresWss.getEconomico, {}, tractoresWssUrl);
  }

  /**
   * @name getConsultaTractor
   * @desc Metodo que obtiene la informacion general y el trafico de un tractor
   */
  getConsultaTractor(economico: number, fechaInicial: any, fechaFinal: any, talon: string = "", vin: string = "") {
    return this.api.post(tractoresWss.getConsultaTractor, {
      "NumeroEconomico": economico,
      "FechaInicial": fechaInicial,
      "FechaFinal": fechaFinal,
      "Talon": talon,
      "VIN": vin
    }, tractoresWssUrl);
  }

  /**
   * @name getRastreo
   * @desc Metodo que obtiene la informacion de rastreo de un tractor
   */
  getRastreo(economico: number, fechaInicial: any, fechaFinal: any) {
    return this.api.post(tractoresWss.getRastreo, {
      "NumeroEconomico": economico,
      "FechaInicial": fechaInicial,
      "FechaFinal": fechaFinal,
      "Talon": "",
      "VIN": ""
    }, tractoresWssUrl);
  }

  /**
   * @name getMantenimiento
   * @desc Metodo que obtiene la informacion de los mantenimientos de un tractor
   */
  getMantenimiento(economico: number, fechaInicial: any, fechaFinal: any) {
    return this.api.post(tractoresWss.getMantenimiento, {
      "NumeroEconomico": economico,
      "FechaInicial": fechaInicial,
      "FechaFinal": fechaFinal,
      "Talon": "",
      "VIN": ""
    }, tractoresWssUrl);
  }
}
