import { Injectable } from '@angular/core';

import { Events } from "src/app/providers/common/events.service";
import { DashboardsService } from '../dashboards/dashboards.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteHomeProvider {

  private dashboardsFinancierosActive: boolean = false;
  private dashboardsOperativosActive: boolean = false;
  private dashboardsAMDFActive: boolean = false;
  private dashboardMazda: boolean = false;
  private dashboardBMW: boolean = false;

  private dahsboardCountActive: number = 0;
  private favDashboard: string = "home";

  public favoriteHome: string = 'Home';
  public favoriteURL: string = '';

  constructor(
    private dashboardsService: DashboardsService,
    private events: Events,
  ) {
    this.getFavouriteDashboard();
    this.initDashboards();
  }

  getFavorite() {
    // console.log("then", this.favDashboard, this.dashboardsFinancierosActive, this.dashboardsOperativosActive, this.dashboardsAMDFActive, this.dashboardMazda, this.dashboardBMW);

    if (this.dahsboardCountActive >= 2) {
      console.log("active more than one");
      this.dashboardsService.getDashboardFavorito().then((dashboard: string) => {
        this.events.publish("menu:dashboards:current", dashboard);
        this.favDashboard = dashboard;
      }).catch(err => {
        console.error(err);
      });
    } else if (this.dashboardsFinancierosActive) {
      // console.log("financiero");
      this.favoriteHome = "financiero";
      this.favoriteURL = "dashboard/financiero";
    } else if (this.dashboardsOperativosActive) {
      // console.log("operativo");
      this.favoriteHome = "operativo";
      this.favoriteURL = "dashboard/operativo";
    } else if (this.dashboardsAMDFActive) {
      // console.log("amdf");
      this.favoriteHome = "amdf";
      this.favoriteURL = "dashboard/amdf";
    } else if (this.dashboardMazda) {
      // console.log("mazda");
      this.favoriteHome = "mazda";
      this.favoriteURL = "dashboard/mazda";
    } else if (this.dashboardBMW) {
      // console.log("bmw");
      this.favoriteHome = "bmw";
      this.favoriteURL = "dashboard/bmw";
    }

    return this.favoriteHome;
  }

  getFavouriteDashboard() {
    this.dashboardsService.getDashboardFavorito().then((dashboard: string) => {
      this.favDashboard = dashboard;
    }).catch(err => {
      console.error(err);
    });
  }

  async initDashboards() {
    let active = await this.dashboardsService.areActivated();
    this.dashboardsFinancierosActive = active;
    let activeOp = await this.dashboardsService.areActivatedOperativo();
    this.dashboardsOperativosActive = activeOp;
    let activeAMDF = await this.dashboardsService.areActivatedDashboard('AMDF');
    this.dashboardsAMDFActive = activeAMDF;
    let activeMazda = await this.dashboardsService.areActivatedDashboard('Mazda');
    this.dashboardMazda = activeMazda;
    let activeBMW = await this.dashboardsService.areActivatedDashboard('BMW');
    this.dashboardBMW = activeBMW;

    this.dahsboardCountActive = 0;

    if (this.dashboardsFinancierosActive)
      this.dahsboardCountActive++;
    if (this.dashboardsOperativosActive)
      this.dahsboardCountActive++;
    if (this.dashboardsAMDFActive)
      this.dahsboardCountActive++;
    if (this.dashboardMazda)
      this.dahsboardCountActive++;
    if (this.dashboardBMW)
      this.dahsboardCountActive++;

  }
}
