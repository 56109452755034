import { Injectable } from '@angular/core';
import { ApiProvider } from '../common/api.service';
import { investorRelations, investorRelationsUrl } from '../../common/constants';
import { Observable } from "rxjs/";
import { of } from "rxjs";

/**
 * @name InvestorRelationsService
 * @desc Servicio que se usa para todo lo referente a la pagina de InvestorRelationsPage
 */
@Injectable({
  providedIn: 'root'
})
export class InvestorRelationsService {

  constructor(private api: ApiProvider) { }

  /**
   * @name getAlertas
   * @desc Metodo que regresa la información de las alertas
   */
  getDownloadUrl(Cve_Adjunto: string) {
    return this.api.post(investorRelations.getDownloadUrl, {
      Cve_Adjunto
    }, investorRelationsUrl);
  }

  getDocuments(Tipo_Documento: string) {
    return this.api.post(investorRelations.getDocuments, {
      Tipo_Documento
    }, investorRelationsUrl);
  }

  getLabels(): Observable<string[]> {
    return of([
      'Financial',
      'Legal',
      'Commercial'
    ]);
  }
}
