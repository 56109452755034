import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertProvider {
  constructor(public alertCtrl: AlertController) { }

  /**
   * @name myAlertMethod
   * @desc Metodo que se encargar de mostrar una Alerta sencilla
   * @title El parametro que recive es el titulo a mostrar en la Alerta
   * @message El parametro que recive es el mensaje a mostrar en la Alerta
   */
  async myAlertMethod(title: string, message: string) {
    const confirm = await this.alertCtrl.create({
      header: title,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: "Aceptar"
        }
      ],
      cssClass: "base-alert"
    });
    await confirm.present();
  }

  /**
   * @name confirmationAlert
   * @desc Metodo que se encargar de mostrar una Alerta de confirmación sencilla
   * @title El parametro que recive es el titulo a mostrar en la Alerta
   * @message El parametro que recive es el mensaje a mostrar en la Alerta
   */
  async confirmationAlert(title: string, message: string) {
    const confirm = await this.alertCtrl.create({
      header: title,
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          handler: data => {
            confirm.present()
          }
        },
        {
          text: 'Cancelar',
          handler: data => {
            confirm.present()
          }
        }
      ],
      cssClass: "base-alert"
    });
    await confirm.present();

    confirm.onDidDismiss().then((paramData) => {
      console.log(paramData);
      const data = paramData.data;
    });
  }
}
