import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Cookie } from "ng2-cookies/ng2-cookies";

import { ApiProvider } from "../common/api.service";
import { AppStorageProvider, get } from "../common/app-storage.service";

import { dashboardsEndpoints, dashboardsUrl, dashboardsAMDFUrl, dashboardsMazdaUrl, dashboardsBMWUrl } from "../../common/constants";

import { requestDataInfo } from "src/app/interfaces/Dashboards/amdf.interface";

export type FiltrosRequest = {
  transportistas: any[];
  marcas: any[];
  zonas: any[];
  familias: any[];
  distribuidores: any[];
  clientes: any[];
  periodo_Ini: any[];
  periodo_Fin: any[];
};

/**
 * @name DashboardsService
 * @desc Servicio que se usa para todo lo referente a los dashboards financieros
 */
@Injectable({
  providedIn: "root",
})
export class DashboardsService {
  constructor(private api: ApiProvider, private storage: AppStorageProvider) { }

  setDashboardFavorito(dashboard: string) {
    let body: any;
    if (dashboard === "financiero") {
      body = {
        ...this.getDashboardCookies(),
      };
    } else if (dashboard === "operativo") {
      body = {
        ...this.getDashboardOperativoCookies(),
      };
    } else if (dashboard === "amdf") {
      body = {
        ...this.getDashboardAMDFCookies(),
      };
    }
    return this.api.post(
      dashboardsEndpoints.setDashboardFavorito,
      body,
      dashboardsUrl
    );
  }

  /* getDashboardFavorito() {
    return new Promise((resolve, reject) => {
      this.api.post(dashboardsEndpoints.getDashboardFavorito, {
        ...this.getDashboardCookies(),
        ...this.getDashboardOperativoCookies()
      }, dashboardsUrl).subscribe((data: any) => {
        if (data) {
          if (data.Id_Token.includes("__csidfe_")) {
            resolve("financiero");
          } else if (data.Id_Token.includes("__csidop_")) {
            resolve("operativo");
          } else {
            reject(null);
          }
        }
      }, err => {
        reject(err);
      })
    });
  } */

  getDashboardFavorito() {
    return new Promise((resolve, reject) => {
      this.storage.get("userMenu").then(
        (data: any) => {
          if (data.Dashboards) {
            for (const dashboard of data["Dashboards"]) {
              if (dashboard.Dashboard.includes("Financiero") && dashboard.Bit_Default) {
                resolve("financiero");
              } else if (dashboard.Dashboard.includes("Operativo") && dashboard.Bit_Default) {
                resolve("operativo");
              } else if (dashboard.Dashboard.includes("AMDF") && dashboard.Bit_Default) {
                resolve("amdf");
              } else if (dashboard.Dashboard.includes("Mazda") && dashboard.Bit_Default) {
                resolve("mazda");
              } else if (dashboard.Dashboard.includes("BMW") && dashboard.Bit_Default) {
                resolve("bmw");
              }
            }
          } else {
            resolve("");
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getConfigReporteIngresos(empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getConfigReporteIngresos,
      {
        ...this.getDashboardCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteIngresos(empresa: string, indicador: string | null = null) {
    return this.api.post(
      dashboardsEndpoints.getReporteIngresos,
      {
        indicador,
        ...this.getDashboardCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteEstadoFinanciero(
    isApp: boolean,
    empresa: string,
    indicador: string | null = null,
    period: string | null = null
  ) {
    return this.api.post(
      dashboardsEndpoints.getReporteEstadoFinanciero,
      {
        indicador,
        period,
        isApp,
        ...this.getDashboardCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getConfigReporteKPI(empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getConfigReporteKPI,
      {
        ...this.getDashboardCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteKPITable(analysis: string, period: string, empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getReporteKPITable,
      {
        analysis,
        period,
        ...this.getDashboardCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteKPIChart(indicador: string, analysis: string, empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getReporteKPIChart,
      {
        indicador,
        analysis,
        ...this.getDashboardCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  activate(): Observable<any> {
    return this.api.post(dashboardsEndpoints.activar, {}, dashboardsUrl);
  }

  getDashboardCookies(name: string = "") {
    /* let cookies = document.cookie.split(";");
    let dashboardCookies = cookies.filter(v => v.indexOf("__csidfe_" + name) > -1).map(v => v.split("="));
    let result = {};
    for (let c of dashboardCookies) {
      result[c[0].trim()] = c[1].trim();
    }

    return result; */
    const result = {
      __csidfe_: null,
    };

    return result;
  }

  getDashboardOperativoCookies(name: string = "") {
    /* let cookies = document.cookie.split(";");
    let dashboardCookies = cookies.filter(v => v.indexOf("__csidop_" + name) > -1).map(v => v.split("="));
    let result = {};
    for (let c of dashboardCookies) {
      result[c[0].trim()] = c[1].trim();
    }

    return result; */
    const result = {
      __csidop_: null,
    };

    return result;
  }

  getDashboardAMDFCookies(name: string = "") {
    const result = {
      __csidamdf_: null,
    };

    return result;
  }

  async getDashboardsStorageCookies(dashboardType: string) {
    let keyName = dashboardType + "_key";
    let valName = dashboardType + "_val";

    let _key: string = await get(keyName);
    let _val: string = await get(valName);

    const result = {
      [_key]: _val,
    };

    return result;
  }

  areActivated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.get("userMenu").then(
        (data: any) => {
          if (data.Dashboards) {
            for (const dashboard of data["Dashboards"]) {
              if (dashboard.Dashboard.includes("Financiero")) {
                resolve(true);
              }
            }
            resolve(false);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
    /* return new Promise((resolve, reject) => {
      this.api.post(dashboardsEndpoints.verificarActivacion, {
      ...this.getDashboardCookies()
    }, dashboardsUrl).subscribe((data: any) => {
      if (data.Activo === true) resolve(true);
      else resolve(false);
    }, err => {
      reject(err);
      })
    }); */
  }

  validateActivation(codigo: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.api
        .post(
          dashboardsEndpoints.validateCodigoActivacion,
          {
            pin: codigo,
          },
          dashboardsUrl
        )
        .subscribe(
          (data: any) => {
            if (
              data.Dashboard_Access_Token &&
              data.Dashboard_Access_Token.Key &&
              data.Dashboard_Access_Token.Value
            ) {
              Cookie.set(
                data.Dashboard_Access_Token.Key,
                data.Dashboard_Access_Token.Value,
                365
              );
              resolve();
            } else {
              reject();
            }
          },
          (err) => {
            reject();
          }
        );
    });
  }

  deactivate(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.api
        .post(
          dashboardsEndpoints.desactivar,
          {
            ...this.getDashboardCookies(),
          },
          dashboardsUrl
        )
        .subscribe(
          (data: any) => {
            if (data.Dashboard_Access_Token.Key) {
              Cookie.delete(data.Dashboard_Access_Token.Key);
              resolve();
            } else {
              reject();
            }
          },
          (err) => {
            reject();
          }
        );
    });
  }

  getEmpresas() {
    return this.api.post(
      dashboardsEndpoints.getEmpresas,
      {
        ...this.getDashboardCookies(),
        ...this.getDashboardOperativoCookies(),
      },
      dashboardsUrl
    );
  }

  activateOperativo(): Observable<any> {
    return this.api.post(
      dashboardsEndpoints.activarOperativo,
      {},
      dashboardsUrl
    );
  }

  areActivatedOperativo(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.get("userMenu").then(
        (data: any) => {
          if (data.Dashboards) {
            for (const dashboard of data["Dashboards"]) {
              if (dashboard.Dashboard.includes("Operativo")) {
                resolve(true);
              }
            }
            resolve(false);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });

    /* return new Promise((resolve, reject) => {
      this.api.post(dashboardsEndpoints.verificarActivacionOperativo, {
        ...this.getDashboardOperativoCookies()
      }, dashboardsUrl).subscribe((data: any) => {
        if (data.Activo === true) resolve(true);
        else resolve(false);
      }, err => {
        reject(err);
      })
    }); */
  }

  validateActivationOperativo(codigo: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.api
        .post(
          dashboardsEndpoints.validateCodigoActivacionOperativo,
          {
            pin: codigo,
          },
          dashboardsUrl
        )
        .subscribe(
          (data: any) => {
            if (
              data.Dashboard_Access_Token &&
              data.Dashboard_Access_Token.Key &&
              data.Dashboard_Access_Token.Value
            ) {
              Cookie.set(
                data.Dashboard_Access_Token.Key,
                data.Dashboard_Access_Token.Value,
                365
              );
              resolve();
            } else {
              reject();
            }
          },
          (err) => {
            reject();
          }
        );
    });
  }

  deactivateOperativo(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.api
        .post(
          dashboardsEndpoints.desactivarOperativo,
          {
            ...this.getDashboardOperativoCookies(),
          },
          dashboardsUrl
        )
        .subscribe(
          (data: any) => {
            if (data.Dashboard_Access_Token.Key) {
              Cookie.delete(data.Dashboard_Access_Token.Key);
              resolve();
            } else {
              reject();
            }
          },
          (err) => {
            reject();
          }
        );
    });
  }

  getConfigReporteOperativoVolumen(empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getConfigReporteOperativoVolumen,
      {
        ...this.getDashboardOperativoCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteOperativoVolumen(empresa: string, indicador: string | null = null) {
    return this.api.post(
      dashboardsEndpoints.getReporteOperativoVolumen,
      {
        indicador,
        ...this.getDashboardOperativoCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteOperativoEstaditico(
    isApp: boolean,
    empresa: string,
    indicador: string | null = null,
    period: string | null = null
  ) {
    return this.api.post(
      dashboardsEndpoints.getReporteOperativoEstaditico,
      {
        indicador,
        period,
        isApp,
        ...this.getDashboardOperativoCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getConfigReporteOpKPITransporte(
    empresa: string,
    equipment: number | null = null
  ) {
    if (equipment != null) {
      return this.api.post(
        dashboardsEndpoints.getConfigReporteOpKPITransporte,
        {
          ...this.getDashboardOperativoCookies(),
          Cve_Empresa: empresa,
          EquipmentCategory: equipment,
        },
        dashboardsUrl
      );
    } else {
      return this.api.post(
        dashboardsEndpoints.getConfigReporteOpKPITransporte,
        {
          ...this.getDashboardOperativoCookies(),
          Cve_Empresa: empresa,
        },
        dashboardsUrl
      );
    }
  }

  getReporteOpKPITransporteTable(
    analysis: string,
    period: string,
    empresa: string,
    equipment: number | null = null
  ) {
    console.log(equipment);
    if (equipment != null) {
      console.log(equipment);
      return this.api.post(
        dashboardsEndpoints.getReporteOpKPITransporteTable,
        {
          analysis,
          period,
          EquipmentCategory: equipment,
          ...this.getDashboardOperativoCookies(),
          Cve_Empresa: empresa,
        },
        dashboardsUrl
      );
    } else {
      return this.api.post(
        dashboardsEndpoints.getReporteOpKPITransporteTable,
        {
          analysis,
          period,
          ...this.getDashboardOperativoCookies(),
          Cve_Empresa: empresa,
        },
        dashboardsUrl
      );
    }
  }

  getReporteOpKPITransporteChart(
    indicador: string,
    analysis: string,
    empresa: string,
    equipment: number | null = null
  ) {
    if (equipment != null) {
      console.log(equipment);
      return this.api.post(
        dashboardsEndpoints.getReporteOpKPITransporteChart,
        {
          indicador,
          analysis,
          EquipmentCategory: equipment,
          ...this.getDashboardOperativoCookies(),
          Cve_Empresa: empresa,
        },
        dashboardsUrl
      );
    } else {
      return this.api.post(
        dashboardsEndpoints.getReporteOpKPITransporteChart,
        {
          indicador,
          analysis,
          ...this.getDashboardOperativoCookies(),
          Cve_Empresa: empresa,
        },
        dashboardsUrl
      );
    }
  }

  getConfigReporteOpKPICD(empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getConfigReporteOpKPICD,
      {
        ...this.getDashboardOperativoCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteOpKPICDTable(analysis: string, period: string, empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getReporteOpKPICDTable,
      {
        analysis,
        period,
        ...this.getDashboardOperativoCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  getReporteOpKPICDChart(indicador: string, analysis: string, empresa: string) {
    return this.api.post(
      dashboardsEndpoints.getReporteOpKPICDChart,
      {
        indicador,
        analysis,
        ...this.getDashboardOperativoCookies(),
        Cve_Empresa: empresa,
      },
      dashboardsUrl
    );
  }

  // Dashboard AMDF Functions ----------------------------->

  areActivatedAMDF(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.get("userMenu").then(
        (data: any) => {
          if (data.Dashboards) {
            for (const dashboard of data["Dashboards"]) {
              if (dashboard.Dashboard.includes("AMDF")) {
                resolve(true);
              }
            }
            resolve(false);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /* Agregar funciones necesarias para obtener datos para las graficas, apartir de esta linea */

  /**
   * @name fetchAMDFilters
   * @desc Metodo que regresa la lista de filtros disponibles para las dashboards
   **/
  fetchAMDFilters() {
    return this.api.post(dashboardsEndpoints.getFiltros, {}, dashboardsAMDFUrl);
  }

  /**
   * @name getUnidadesEntregadas
   * @desc Metodo que regresa la información filtrada de las unidades entregadas que alimentará las dashboard
   **/
  getUnidadesEntregadas({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getUnidadesEntregadas,
      request,
      dashboardsAMDFUrl
    );
  }

  /**
   * @name getCarLine
   * @desc Metodo que regresa la información filtrada del CarLine que alimentará las dashboard de CarLine
   */
  getCarLine({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getCarLine,
      request,
      dashboardsAMDFUrl
    );
  }

  /**
   * @name getTTpromedio
   * @desc Metodo que regresa la información filtrada de las TT que alimentará las dashboard de tt promedio
   */
  getTTpromedio({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getTTpromedio,
      request,
      dashboardsAMDFUrl
    );
  }

  /**
   * @name getTablaTTpromedio
   * @desc Metodo que regresa la información filtrada del promedio de las TT en forma de tabla
   */
  getTablaTTpromedio({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getTablaTTpromedio,
      request,
      dashboardsAMDFUrl
    );
  }

  /**
   * @name getIntercambios
   * @desc Metodo que regresa la información filtrada del dashboard Intercambios
   */
  getIntercambios({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getIntercambios,
      request,
      dashboardsAMDFUrl
    );
  }

  /**
   * @name getFrecuenciaEntregas
   * @desc Metodo que regresa la información filtrada del dashboard Frecuencia de entregas
   */
  getFrecuenciaEntregas({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getFrecuenciaEntregas,
      request,
      dashboardsAMDFUrl
    );
  }

  /**
 * @name getDiasSolicitud
 * @desc Metodo que regresa la información filtrada del dashboard Dias de Solicitud
 */
  getDiasSolicitud({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getDiasDeSolicitud,
      request,
      dashboardsAMDFUrl
    );
  }

  /**
 * @name getBarFrecuenciaEntregas
 * @desc Metodo que regresa la información filtrada del dashboard frecuencia de entregas bar
 */
  getBarFrecuenciaEntregas({
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(
      dashboardsEndpoints.getBarFrecuenciaEntregas,
      request,
      dashboardsAMDFUrl
    );
  }

  // Dashboard AMDF/Mazda Newer/Corrected Functions ----------------------------->

  areActivatedDashboard(typeDashboard: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.get("userMenu").then(
        (data: any) => {
          if (data.Dashboards) {
            for (const dashboard of data["Dashboards"]) {
              if (dashboard.Dashboard.includes(typeDashboard)) {
                resolve(true);
              }
            }
            resolve(false);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /* Dashboard  AMDF/Mazda Filters */
  /**
   * @name getFiltersByDashboard
   * @desc Metodo que regresa la lista de filtros disponibles para el dashboard AMDF/Mazda
  **/
  getFiltersByDashboard(typeDashboard: string) {
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    return this.api.post(dashboardsEndpoints.getFiltros, {}, urlDashboard);
  }

  /**
   * @name getUnidadesEntregadasByDashboard
   * @desc Metodo que regresa la información filtrada de las unidades entregadas que alimentará las dashboard
   **/
  getUnidadesEntregadasByDashboard(typeDashboard: string, requestData: requestDataInfo) {
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    const periodo_ini_value = requestData.periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = requestData.periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        requestData.transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        requestData.marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        requestData.zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        requestData.familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        requestData.distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        requestData.clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Viaje: requestData.viaje > 0
        ? requestData.viaje
        : 0,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };

    return this.api.post(dashboardsEndpoints.getUnidadesEntregadas, request, urlDashboard);
  }

  /**
   * @name getCarLineByDashboard
   * @desc Metodo que regresa la información filtrada del CarLine que alimentará las dashboard de CarLine
   */
  getCarLineByDashboard(typeDashboard: string, requestData: requestDataInfo) {
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    const periodo_ini_value = requestData.periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = requestData.periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        requestData.transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        requestData.marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        requestData.zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        requestData.familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        requestData.distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        requestData.clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Viaje: requestData.viaje > 0
        ? requestData.viaje
        : 0,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };

    return this.api.post(dashboardsEndpoints.getCarLine, request, urlDashboard);
  }

  /**
   * @name getTTpromedioByDashboard
   * @desc Metodo que regresa la información filtrada de las TT que alimentará las dashboard de tt promedio
  **/
  getTTpromedioByDashboard(typeDashboard: string, requestData: requestDataInfo) {
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    const periodo_ini_value = requestData.periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = requestData.periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        requestData.transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        requestData.marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        requestData.zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        requestData.familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        requestData.distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        requestData.clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Viaje: requestData.viaje > 0
        ? requestData.viaje
        : 0,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(dashboardsEndpoints.getTTpromedio, request, urlDashboard);
  }

  /**
   * @name getTablaTTpromedioByDashboard
   * @desc Metodo que regresa la información filtrada del promedio de las TT en forma de tabla
   */
  getTablaTTpromedioByDashboard(typeDashboard: string, requestData: requestDataInfo) {
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    const periodo_ini_value = requestData.periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = requestData.periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        requestData.transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        requestData.marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        requestData.zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        requestData.familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        requestData.distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        requestData.clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Viaje: requestData.viaje > 0
        ? requestData.viaje
        : 0,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(dashboardsEndpoints.getTablaTTpromedio, request, urlDashboard);
  }

  /**
   * @name getIntercambiosByDashboard
   * @desc Metodo que regresa la información filtrada del dashboard Intercambios
   */
  getIntercambiosByDashboard(typeDashboard: string, requestData: requestDataInfo) {
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    const periodo_ini_value = requestData.periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = requestData.periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        requestData.transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        requestData.marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        requestData.zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        requestData.familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        requestData.distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        requestData.clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Viaje: requestData.viaje > 0
        ? requestData.viaje
        : 0,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };

    return this.api.post(dashboardsEndpoints.getIntercambios, request, urlDashboard);
  }

  /**
   * @name getFrecuenciaEntregasByDashboard
   * @desc Metodo que regresa la información filtrada del dashboard Frecuencia de entregas
   */
  getFrecuenciaEntregasByDashboard(typeDashboard: string, requestData: requestDataInfo) {
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    const periodo_ini_value = requestData.periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = requestData.periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        requestData.transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        requestData.marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        requestData.zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        requestData.familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        requestData.distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        requestData.clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Viaje: requestData.viaje > 0
        ? requestData.viaje
        : 0,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    return this.api.post(dashboardsEndpoints.getFrecuenciaEntregas, request, urlDashboard);
  }

  /**
 * @name getServiciosRealizados
 * @desc Metodo que regresa la información filtrada del dashboard Servicios Realizados.
 */

  getServiciosRealizados(typeDashboard: string, {
    transportistas,
    marcas,
    zonas,
    familias,
    distribuidores,
    clientes,
    periodo_Ini,
    periodo_Fin,
  }: FiltrosRequest) {
    const periodo_ini_value = periodo_Ini?.map((periodo_IniId) => {
      return {
        IdPeriodo: periodo_IniId,
      };
    });

    const periodo_fin_value = periodo_Fin?.map((periodo_FinId) => {
      return {
        IdPeriodo: periodo_FinId,
      };
    });

    const request = {
      Transportistas:
        transportistas?.map((transportistaId) => {
          return {
            IdTransportista: transportistaId,
          };
        }) || null,
      Marcas:
        marcas?.map((marcaId) => {
          return {
            IdMarca: marcaId,
          };
        }) || null,
      Zonas:
        zonas?.map((zonaId) => {
          return { IdZona: zonaId };
        }) || null,
      Familias:
        familias?.map((familiaId) => {
          return {
            IdFamilia: familiaId,
          };
        }) || null,
      Distribuidores:
        distribuidores?.map((distribuidorId) => {
          return {
            IdDistribuidor: distribuidorId,
          };
        }) || null,
      Clientes:
        clientes?.map((clienteId) => {
          return {
            IdCliente: clienteId,
          };
        }) || null,
      Periodo_Ini:
        periodo_ini_value && periodo_ini_value.length > 0
          ? periodo_ini_value[0].IdPeriodo
          : null,
      Periodo_Fin:
        periodo_fin_value && periodo_fin_value.length > 0
          ? periodo_fin_value[0].IdPeriodo
          : null,
    };
    const urlDashboard = this.getDashboardEndPointURL(typeDashboard);

    return this.api.post(dashboardsEndpoints.getServiciosRealizados, request, urlDashboard);
  }

  private getDashboardEndPointURL(typeDashboard: string) {
    let dashboardEndPointURL: string;

    switch (typeDashboard) {
      case 'amdf':
        dashboardEndPointURL = dashboardsAMDFUrl;
        break;
      case 'mazda':
        dashboardEndPointURL = dashboardsMazdaUrl;
        break;
      case 'bmw':
        dashboardEndPointURL = dashboardsBMWUrl;
        break;
    }

    return dashboardEndPointURL;
  }

}
