import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilityFunctionService {

    private data = [];

    constructor() { }

    public isUndefined(paramValue: any) {
        return typeof paramValue === 'undefined';
    }

    public isEmpty(paramValue: any) {
        return this.isUndefined(paramValue) || paramValue === '' || paramValue === null;
    }

    public randomNum(paramMinNumb: number = 0, paramMaxNumb: number = 100) {
        return Math.round(Math.random() * (paramMaxNumb - paramMinNumb + 1) + paramMinNumb);
    }

    public getMonthName(Mes: number) {
        let nombreMes: string = '';

        switch (Mes) {
            case 1:
                nombreMes = 'Enero';
                break;
            case 2:
                nombreMes = 'Febrero';
                break;
            case 3:
                nombreMes = 'Marzo';
                break;
            case 4:
                nombreMes = 'Abril';
                break;
            case 5:
                nombreMes = 'Mayo';
                break;
            case 6:
                nombreMes = 'Junio';
                break;
            case 7:
                nombreMes = 'Julio';
                break;
            case 8:
                nombreMes = 'Agosto';
                break;
            case 9:
                nombreMes = 'Septiembre';
                break;
            case 10:
                nombreMes = 'Octubre';
                break;
            case 11:
                nombreMes = 'Noviembre';
                break;
            case 12:
                nombreMes = 'Diciembre';
                break;
        }

        return nombreMes;
    }

    /* convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(btoa(event.target.result.toString()));
        return result;
    } */

    convertFileToBase64(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => result.next(reader.result.toString());
        return result;
    }
}
