import { Injectable } from '@angular/core';

/**
 * @name ConsultFieldsService
 * @desc Servicio que se usa para manipular y homologar los campos de las consultas
 */
@Injectable({
  providedIn: 'root'
})
export class ConsultFieldsService {
  constructor() { }

  /**
   * @name getAll
   * @desc Methodo que regresa todos los campos registrados, se pueden excluir campos con el parametro 'exclude'
   */
  getAll(exclude: string[] = null) {
    if (!!exclude) {
      const result = {};
      for (let key in this.fields) {
        if (this.fields.hasOwnProperty(key)) {
          if (exclude.indexOf(key) == -1) {
            result[key] = this.fields[key];
          }
        }
      }
      return result;
    }
    else {
      return this.fields;
    }
  }

  translate(val: string) {
    return this.fields[val];
  }

  getStatusOrder(val: string): number {
    return this.statusOrder[val] || 100;
  }

  filterToField(val: string): string {
    const filterToFieldObj = {
      "Cve_Cliente": "Desc_Cliente",
      "Desc_Detenciones": "Desc_Acampanado",
      'Cve_Marca': "Desc_Marca",
      'Cve_Destino': "Desc_DestinoDealer",
      'Cve_Catalogo': "Desc_Catalogo",
      'Cve_Familia': "Desc_Familia",
      'Cve_Linea': "Desc_Linea",
      'Cve_Distribuidor': "Desc_Distribuidor",
      'Desc_VINImpExpDom': "Desc_TipoMercado",
      'Cve_Zona': "Desc_Zona",
      'Id_Cnsc_Source': "Desc_Fuente",
      "Ant_CompStock2": "Ant_CompStock",
      "AntRemision2": "AntRemision",
      "AntEstatus": "ANT_ULTESTATUS",
      "AntEstatus2": "ANT_ULTESTATUS"
    }

    return filterToFieldObj[val] || val;
  }

  /**
   * @name sortAsc
   * @desc Metodo usado para ordenar los campos alfabeticamente de manera ascendente
   */
  sortAsc(a: string, b: string): number {
    const A = this.fields[a].toLowerCase();
    const B = this.fields[b].toLowerCase();
    return A > B ? 1 : A < B ? -1 : 0;
  }

  private fields = {
    "VinCodigo": "Vin",
    "Desc_Cliente": "Cliente",
    "Desc_Marca": "Marca",
    "Desc_Familia": "Familia",
    "Desc_Linea": "Línea",
    "Desc_Catalogo": "Catálogo",
    "Desc_Modelo": "Modelo",
    "Desc_TipoMercado": "Mercado",
    "Desc_Planta": "Planta de Producción",
    "Fec_FechaProduccion": "Fecha de Producción",
    "Num_Pedimento": "Pedimento",
    "Fec_FechaPedimento": "Fecha de Pedimento",
    "Desc_ColorInterior": "Color Interior",
    "Desc_ColorExterior": "Color Exterior",
    "Desc_CentroDistribucion": "Centro de Distribución",
    "Desc_Estatus": "Estatus",
    "Fec_FecEstatus": "Fecha del Estatus",
    "Desc_Acampanado": "Detenido",
    "Desc_Accesorizado": "Accesorizado",
    "Fec_FecAccesorizado": "Fecha de Accesorizado",
    "Desc_PrimerPatio": "Primer Patio",
    "Fec_FechaPrimerPatio": "Fecha de Primera entrada a Patio",
    "Desc_Patio": "Patio",
    "Desc_Bahia": "Bahía",
    "Desc_Cajon": "Cajón",
    "Desc_Danado": "Dañado",
    "Desc_DestinoEntrega": "Destino de Punto de Entrega",
    "Desc_MedioTransporte": "Medio de Transporte",
    "Desc_Transportista": "Transportista",
    "Desc_Transporte": "Transporte",
    "Num_LoadNumber": "Número de Viaje",
    "Desc_PerteneceCDM": "Pertenece a CDM",
    "Num_PullOrder": "Pullorder",
    "Fec_FechaPullOrder": "Fecha de Pullorder",
    "Desc_InstruccionesEspeciales": "Instrucciones Especiales",
    "Desc_BidOriginal": "BID de ASN",
    "Desc_DistribuidorOriginal": "Distribuidor de ASN",
    "Desc_DestinoDealerOriginal": "Destino del ASN",
    "Desc_Bid": "BID",
    "Desc_Distribuidor": "Distribuidor",
    "Desc_DestinoDealer": "Destino del Distribuidor",
    "Num_Remision": "Remision",
    "Fec_FechaRemision": "Fecha de Remision",
    "Fec_FechaLiberacion": "Fecha de Liberación",
    "Fec_HisFHEstLlegada": "Fecha Estimada de Llegada",
    "Fec_VDFechaTerminoCampana": "Fecha de Término de Campaña",
    "Fec_HISFHInicio": "Fecha de Inicio del Estatus",
    "Fec_HISFHFin": "Fecha de Término del Estatus",
    "Num_LAB": "LAB",
    "Ant_CompStock": "Antigüedad en Company Stock",
    "Ant_Remision": "Antigüedad de Remisión",
    "ANT_ULTESTATUS": "Antigüedad del estatus",
    "Desc_Zona": "Zona",
    "Num_Talon": "Talón",
    "Desc_Fuente": "Fuente",
    "Desc_DistribuidorRemision": "Distribuidor de la Remisión",
    "Desc_BIDDistribuidorRemision": "BID del Distribuidor de la Remisión",
    "Desc_DestDistOriginal": "Destino del Distribuidor de la Remisión",
    "Desc_PtoDescarga": "Punto Descarga",
    "Desc_TipoPtoDescarga": "Tipo Punto Descarga",
    "Desc_PtoCarga": "Punto Carga",
    "Desc_TipoPtoCarga": "Tipo Punto Carga",
    "Desc_TipoOrden": "Tipo Orden",
    "Desc_Personalizado": "Personalizado",
    "Fec_Personalizacion": "Fecha de Personalización",
    "Desc_Mantenimientos": "Con Mantenimientos",
    "Fec_UltMantenimiento": "Fecha del Ultimo Mantenimiento",
    "Desc_FilaCarga": "En Fila de Carga",
    "Desc_PatioCDM": "En Patio CDM",
    "Desc_EstPersonalizacion": "Estatus de Personalización",
    "Desc_EstMantenimiento": "Estatus de Mantenimiento",
    "Desc_EstAccesorizacion": "Estatus de Accesorización",
    "Desc_DestExp": "Destino de Exportación",
    "Fec_Cruce": "Fecha de Cruze",
    "Num_FolioRepuve": "Folio REPUVE",
    "Cve_TagIdRepuve": "REPUVE Tag ID",
    "Fec_InstalacionRepuve": "Fecha de Instalación de REPUVE",
    "Fec_IniAccesorizacion": "Fecha Inicial de Accesorización",
    "Fec_FinAccesorizacion": "Fecha Final de Accesorización",
    "Bit_Certificado": "Certificación de Accesorización",
    "Fec_Certificado": "Fecha de Certificación de Accesorización",
    "Num_Estatus1": "Estatus1_Fisicote",
    "Fec_Estatus1": "Est1_Fisicote Accesorización Completa",
    "Num_Estatus2": "Est2_Fisicote",
    "Fec_Estatus2": "Est2_Fisicote Inicio Accesorización",
    "Num_Estatus3": "Estatus3_Fisicote",
    "Fec_Estatus3": "Est3_Fisicote Fin Accesorización",
    "Fec_Factura": "Fecha Factura",
    "Fec_Entrega": "Fecha Entrega",
    "Desc_PrimerCDM": "Primer CDM",
    "Fec_FechaPrimerCDM": "Fecha Entrega Primer CDM",
    "Desc_AMDF": "AMDF",
    "Desc_SolEmbarque": "Solicitud Embarque",
    "Fec_SolEmbarque": "Fecha Solicitud Embarque",
    "Dias_SDE": "Dias SDE",
    "FechaPrimerCDAMDF": "Fecha Primer CD",
    "Bit_Flotilla": "Flotilla",
    "Desc_Flotilla": "Flotilla Nombre",
    "OrdenRetail": "Orden Retail"
  };

  private statusOrder = {
    "CANCELADO": 1,
    "PROYECTADO": 2,
    "PRODUCIDO": 3,
    "EN TRÁNSITO": 4,
    "POR DESCARGAR": 5,
    "EN TRASLADO": 6,
    "EN PATIO": 7,
    "EN REVISION": 8,
    "EN CONSOLIDACION": 9,
    "POR ARMAR": 10,
    "ARMADO": 11,
    "ASIGNADO": 12,
    "CARGANDO": 13,
    "POR LLEGAR": 14
  }
}
