<ion-content class="splashscreen-bg">
  <ion-grid class="fullscreen">
    <ion-row class="ion-justify-content-center ion-align-items-center fullscreen">
      <ion-col size="11" class="ion-text-center">
        <img src="assets/imgs/gif/CSI LOGO.gif" />
        <!-- <img src="assets/imgs/csi.png" class="dimensions"/> -->
        <!--  <video id="medio" class="dimensions" preload="none" autoplay playsinline>
          <source src="assets/imgs/gif/CSI LOGO.mp4" type="video/mp4">
        </video> -->
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>