import { Injectable } from '@angular/core';
import { ApiProvider } from '../common/api.service';

import { dashboardsEndpoints, dashboardBSCUrl } from '../../common/constants';

import { captureKPIFromFile, captureKPIRequest } from '../../interfaces/DashboardBSC/bcs.interface';

/**
 * @name DashboardBSCServiceService
 * @desc Servicio que se usa para todo lo referente a los dashboards de BSC
 */
@Injectable({
  providedIn: 'root'
})
export class DashboardBSCServiceService {

  constructor(private api: ApiProvider) { }

  getFiltrosReporteKPI(anio: string, empresaName: string) {
    return this.api.post(dashboardsEndpoints.getDashboardBSCFiltros, {
      Anio: anio,
      Empresa: empresaName
    }, dashboardBSCUrl);
  }

  getReporteKPITable(anio: string, mes: string, empresaName: string) {
    return this.api.post(dashboardsEndpoints.getDashboardBSCTable, {
      Anio: anio,
      Mes: mes,
      Empresa: empresaName
    }, dashboardBSCUrl);
  }

  getReporteKPIChart(kpi: string, anio: string, empresaName: string) {
    return this.api.post(dashboardsEndpoints.getDashboardBSCChart, {
      Anio: anio,
      Cve_KPI: kpi,
      Empresa: empresaName
    }, dashboardBSCUrl);
  }

  capturarKPIValor(infoKPI: captureKPIRequest) {
    return this.api.post(dashboardsEndpoints.captureKPIValDashboardBSC,
      infoKPI,
      dashboardBSCUrl);
  }

  capturarKPIEstatus(infoKPI: captureKPIRequest) {
    return this.api.post(dashboardsEndpoints.captureKPIEstDashboardBSC,
      infoKPI,
      dashboardBSCUrl);
  }

  capturarKPIFromFile(capturarKPI: captureKPIFromFile) {
    return this.api.post(dashboardsEndpoints.captureKPIFromFileDashboardBSC,
      capturarKPI,
      dashboardBSCUrl);
  }
}
