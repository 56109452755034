// import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { apiEndpoints } from "../../common/constants";
import { ApiProvider } from "../common/api.service";

import { apiURL } from "../../common/constants";


// import { AppStorageProvider } from "../common/app-storage.provider";
/*
  Generated class for the ScoreProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class ScoreProvider {

  truckerDetail: any;
  travelDetail: any;

  constructor(
    // private appStorage: AppStorageProvider,
    private api: ApiProvider
  ) { }

  /**
   * @name loadStorage
   * @desc Metodo para la obtencion de viajes almacenados en el storage interno solo cuando el
   * el tipo de viaje es CURRENT(En Transito).
   * @returns {Object} - LocalStorage(Cache Interno)
   */
  getTruckerInfo(idViaje) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getTrackingDetail;

      const records = {
        Trips: [{
          IdViaje: idViaje
        }]
      };

      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.truckerDetail = data.Trips.length > 0 ? data.Trips : {};
            resolve(this.truckerDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  getTravelDetail(idViaje) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getTravelDetail;

      const records = {
        Trips: [{
          IdViaje: idViaje
        }]
      };

      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.travelDetail = data.Trips.length > 0 ? data.Trips : {};
            resolve(this.travelDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }

  sendData(travelId, model) {

    return new Promise<void>((resolve, reject) => {
      const endpoint = apiEndpoints.sendScore;

      const records = {
        Trips: [{
          IdViaje: travelId
        }],
        Qualification: model
      };

      this.api.put(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            // this.truckerDetail = data.Trips.length > 0 ? data.Trips : {};
            // resolve(this.truckerDetail);
            resolve();
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }
}
