import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './resolver/data-resolver/data-resolver.service';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home/financiero',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home/operativo',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'dashboard/financiero',
    loadChildren: () => import('./pages/dashboards/dashboard-financiero/dashboard-financiero.module').then( m => m.DashboardFinancieroPageModule)
  },
  {
    path: 'dashboard/operativo',
    loadChildren: () => import('./pages/dashboards/dashboard-operativo/dashboard-operativo.module').then( m => m.DashboardOperativoPageModule)
  },
  {
    path: 'dashboard/amdf',
    loadChildren: () => import('./pages/dashboards/dashboard-amdf/dashboard-amdf.module').then( m => m.DashboardAMDFPageModule)
  },
  {
    path: 'dashboard/mazda',
    loadChildren: () => import('./pages/dashboards/dashboard-by-dealer/dashboard-mazda/dashboard-mazda.module').then( m => m.DashboardMazdaPageModule)
  },
  {
    path: 'dashboard/bmw',
    loadChildren: () => import('./pages/dashboards/dashboard-by-dealer/dashboard-bmw/dashboard-bmw.module').then( m => m.DashboardBMWPageModule)
  },
  {
    path: 'dashboard_bsc/cpv',
    loadChildren: () => import('./pages/dashboards/dashboard-bsc/bsc-cpv/bsc-cpv.module').then(m => m.BSCDashboardCPVPageModule)
  },
  {
    path: 'dashboard_bsc/csi',
    loadChildren: () => import('./pages/dashboards/dashboard-bsc/bsc-csi/bsc-csi.module').then(m => m.BSCDashboardCSIPageModule)
  },
  {
    path: 'dashboard_bsc/csid',
    loadChildren: () => import('./pages/dashboards/dashboard-bsc/bsc-csid/bsc-csid.module').then(m => m.BSCDashboardCSIDPageModule)
  },
  {
    path: 'dashboard_bsc/csix',
    loadChildren: () => import('./pages/dashboards/dashboard-bsc/bsc-csix/bsc-csix.module').then(m => m.BSCDashboardCSIXPageModule)
  },
  {
    path: 'dashboard_bsc/sunus',
    loadChildren: () => import('./pages/dashboards/dashboard-bsc/bsc-sunus/bsc-sunus.module').then(m => m.BSCDashboardSUNUSPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'claims',
    loadChildren: () => import('./pages/claims/claims.module').then(m => m.ClaimsPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./pages/config/config.module').then(m => m.ConfigPageModule)
  },
  {
    path: 'score',
    loadChildren: () => import('./pages/score/score.module').then(m => m.ScorePageModule)
  },
  {
    path: 'score/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/score/score.module').then(m => m.ScorePageModule)
  },
  {
    path: 'travel/list/vessel',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/vessel-travel-list/vessel-travel-list.module').then(m => m.VesselTravelListPageModule)
  },
  {
    path: 'travel/list/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/travel-list/travel-list.module').then(m => m.TravelListPageModule)
  },
  // Current tracking map
  /* {
    path: 'tracking',
    loadChildren: () => import('./pages/tracking-map/tracking-map.module').then(m => m.TrackingMapPageModule)
  }, */
  // Test Intelligent tracking map
  {
    path: 'tracking',
    
    loadChildren: () => import('./pages/Intelligent-tracking-map/intelligent-tracking-map.module').then(m => m.IntelligentTrackingMapPageModule)
  },
  {
    path: 'tracking/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/Intelligent-tracking-map/intelligent-tracking-map.module').then(m => m.IntelligentTrackingMapPageModule)
  },
  {
    path: 'travel/detail/vessel/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/vessel-travel-detail/vessel-travel-detail.module').then(m => m.VesselTravelDetailPageModule)
  },
  {
    path: 'travel/detail/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/travel-detail/travel-detail.module').then(m => m.TravelDetailPageModule)
  },
  {
    path: 'vehicle/detail/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/vehicle-detail/vehicle-detail.module').then(m => m.VehicleDetailPageModule)
  },
  {
    path: 'consult/site',
    loadChildren: () => import('./pages/consulta-cd/consulta-cd.module').then(m => m.ConsultaCdPageModule)
  },
  {
    path: 'report/list/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/report-list/report-list.module').then(m => m.ReportListPageModule)
  },
  {
    path: 'report/menu',
    loadChildren: () => import('./pages/report-menu/report-menu.module').then(m => m.ReportMenuPageModule)
  },
  {
    path: 'report/viewer/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/report-viewer/report-viewer.module').then(m => m.ReportViewerPageModule)
  },
  {
    path: 'modal-faq',
    loadChildren: () => import('./pages/modal-faq/modal-faq.module').then(m => m.ModalFaqPageModule)
  },
  {
    path: 'consult/site/detail',
    loadChildren: () => import('./pages/detail-cd/detail-cd.module').then(m => m.DetailCdPageModule)
  },
  {
    path: 'consult/site/detail/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/detail-cd/detail-cd.module').then(m => m.DetailCdPageModule)
  },
  {
    path: 'vehicle/single',
    loadChildren: () => import('./pages/single-query-vin/single-query-vin.module').then(m => m.SingleQueryVinPageModule)
  },
  {
    path: 'vehicle/single/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/single-query-vin/single-query-vin.module').then(m => m.SingleQueryVinPageModule)
  },
  {
    path: 'vehicle/multiple',
    loadChildren: () => import('./pages/multiple-vin-consult/multiple-vin-consult.module').then(m => m.MultipleVinConsultPageModule)
  },
  {
    path: 'exchange',
    loadChildren: () => import('./pages/exchange/exchange.module').then(m => m.ExchangePageModule)
  },
  {
    path: 'exchange-form',
    loadChildren: () => import('./pages/exchange-form/exchange-form.module').then(m => m.ExchangeFormPageModule)
  },
  {
    path: 'exchange/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/vin-exchange/vin-exchange.module').then(m => m.VinExchangePageModule)
  },
  {
    path: 'shipment-request',
    loadChildren: () => import('./pages/shipment-request/shipment-request.module').then(m => m.ShipmentRequestPageModule)
  },
  {
    path: 'shipment-request/filter',
    loadChildren: () => import('./pages/shipment-request/shipment-request-filter/shipment-request-filter.module').then(m => m.ShipmentRequestFilterPageModule)
  },
  {
    path: 'shipment-request/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/shipment-request/shipment-request.module').then(m => m.ShipmentRequestPageModule)
  },
  {
    path: 'equipment',
    loadChildren: () => import('./pages/consulta-de-tractor/consulta-de-tractor.module').then(m => m.ConsultaDeTractorPageModule)
  },
  {
    path: 'equipment/site',
    loadChildren: () => import('./pages/matriz-estatus-equipo/matriz-estatus-equipo.module').then(m => m.MatrizEstatusEquipoPageModule)
  },
  {
    path: 'equipment/schedule',
    loadChildren: () => import('./pages/programacion-carga/programacion-carga.module').then(m => m.ProgramacionCargaPageModule)
  },
  {
    path: 'equipment/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/consulta-de-tractor/consulta-de-tractor.module').then(m => m.ConsultaDeTractorPageModule)
  },
  {
    path: 'equipment/schedule/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/programacion-carga/programacion-carga.module').then(m => m.ProgramacionCargaPageModule)
  },
  {
    path: 'investor-relations',
    loadChildren: () => import('./pages/investor-relations/investor-relations.module').then(m => m.InvestorRelationsPageModule)
  },
  {
    path: 'consult/dealer',
    loadChildren: () => import('./pages/consult-by-dealer/consult-by-dealer.module').then(m => m.ConsultByDealerPageModule)
  },
  {
    path: 'consulta/by_site_mexico',
    loadChildren: () => import('./pages/consult-by-site-mexico/consult-by-site-mexico.module').then(m => m.ConsultBySiteMexicoPageModule)
  },
  {
    path: 'consulta/by_site_mexico/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/consult-by-site-mexico/consult-by-site-mexico.module').then(m => m.ConsultBySiteMexicoPageModule)
  },
  {
    path: 'alerts',
    loadChildren: () => import('./pages/alertas/alertas.module').then(m => m.AlertasPageModule)
  },
  {
    path: 'yards/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/patios/patios.module').then(m => m.PatiosPageModule)
  },
  {
    path: 'vehicle/list/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/multiple-vin-list/multiple-vin-list.module').then(m => m.MultipleVinListPageModule)
  },
  {
    path: 'vin-list',
    loadChildren: () => import('./pages/vin-list/vin-list.module').then(m => m.VinListPageModule)
  },
  {
    path: 'vin-list/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/vin-list/vin-list.module').then(m => m.VinListPageModule)
  },
  {
    path: 'assets-manager',
    loadChildren: () => import('./pages/assets-manager/assets-manager.module').then(m => m.AssetsManagerPageModule)
  },
  {
    path: 'rate-trip',
    loadChildren: () => import('./pages/rate-trip/rate-trip.module').then( m => m.RateTripPageModule)
  },
  {
    path: 'rate-trip-detail/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/rate-trip-detail/rate-trip-detail.module').then( m => m.RateTripDetailPageModule)
  },
  {
    path: 'report-damage/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/report-damage/report-damage.module').then( m => m.ReportDamagePageModule)
  },
  {
    path: 'report-damage-vin/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/report-damage-vin/report-damage-vin.module').then( m => m.ReportDamageVinPageModule)
  },
  {
    path: 'quantum/fleet',
    loadChildren: () => import('./pages/iframe/iframe.module').then( m => m.IframePageModule)
  },
  {
    path: 'quantum/civar/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/iframe/iframe.module').then( m => m.IframePageModule)
  },
  // {
  //   path: 'multiple-vin-list',
  //   loadChildren: () => import('./pages/multiple-vin-list/multiple-vin-list.module').then(m => m.MultipleVinListPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
