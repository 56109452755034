import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { apiURL } from './../../common/constants';
import { apiEndpoints } from "../../common/constants";
import { ApiProvider } from "../common/api.service";

@Injectable({
  providedIn: 'root'
})
export class VehicleDetailProvider {
  logos: any;
  damages: any;
  logosDetail: any;
  damagesDetail: any;

  constructor(private api: ApiProvider) { }

  info: any;
  infoDocuments: any;
  infoDocumentsDetail: any;
  infoDetail: any;
  infoDowloadDocument: any;
  url_documento: any;
  getData(vin) {
    // var cache = appCache.get('vehicleDetail');
    // if (cache && cache.vin === vin) { return appCache.resolve(cache.data); }

    const vehicleDetailRecords = {
      Vehicles: [
        {
          VIN: vin
        }
      ]
    };

    const documentsInfoRecords = {
      VIN: vin,
      IdViaje: ""
    };

    this.info = new Promise((resolve, reject) => {
      this.api
        .post(apiEndpoints.getVehicleDetail, vehicleDetailRecords, apiURL)
        .subscribe(
          (data: any) => {
            if (data.ErrorMessage) {
              if (data.ErrorMessage == null && data.status == null) {
                reject();
              }
            } else {
              this.infoDetail = data ? data : {};
              resolve(this.infoDetail);
            }
          },
          (error: HttpErrorResponse) => {
            reject();
          }
        );
    });

    this.logos = new Promise((resolve, reject) => {
      this.api.post(apiEndpoints.getVehicleLogos).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.logosDetail = data ? data : {};
            //console.log("logosDetail", this.logosDetail);
            resolve(this.logosDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });

    this.damages = new Promise((resolve, reject) => {
      this.api.post(apiEndpoints.getDamageList, vehicleDetailRecords, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.damagesDetail = data ? data : {};
            //console.log("damageDetail", this.damagesDetail);
            resolve(this.damagesDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });

    this.infoDocuments = new Promise((resolve, reject) => {
      this.api.post(apiEndpoints.getInfoDocuments, documentsInfoRecords, apiURL).subscribe((data: any) => {
        if (data.ErrorMessage) {
          reject();
        } else {
          this.infoDocumentsDetail = data ? data : {};
          resolve(this.infoDocumentsDetail);
        }
      },
        (error: HttpErrorResponse) => {
          reject();
        });
    });

    return Promise.all([this.info, this.logos, this.damages, this.infoDocuments]).then(res => {
      var info = res["0"].Vehicles[0],
        logos = res["1"].Marks,
        damages = res["2"].Complaints,
        documents = res["3"].Documents;

      var data = {
        info: this._validateLogo(info, logos),
        damages: this._validateDamagesInfo(damages),
        documents: documents
      };
      return data;
    });
  }

  ////////////////////////////////////////////////////////// PRIVATE

  _validateLogo(info, logos) {
    logos.forEach(logos => {
      if (parseInt(info.IdMarca) === logos.Id) {
        info.logo = logos.Logo;
        return true;
      }
    });
    return info;
  }

  _validateDamagesInfo(damages) {
    damages.forEach(value => {
      value.urlFoto = value.urlFoto
        ? "data:image/png;base64," + value.urlFoto
        : "img/generic-csi.jpg";
    });
    return damages;
  }

  getDowloadDocument(CveDocumento, S3Bucket) {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getDocument;
      const documentDowload = {
        Cve_Documento: CveDocumento,
        S3_Bucket: S3Bucket
      };

      this.api.post(endpoint, documentDowload, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.infoDowloadDocument = data;
            resolve(this.infoDowloadDocument);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );

    });
  }


  DownloadDocumentAttributeImage(Nom_Image: string) {


    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.downloadAttributeImage;
      const documentDownload = {
        Nom_Image: Nom_Image
      };

      this.api.post(endpoint, documentDownload, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.url_documento = data;

            resolve(this.url_documento);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );

    });

  }

}

