
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserProvider } from '../user/user.service';
import { apiEndpoints } from '../../common/constants';
import { ApiProvider } from '../common/api.service';

@Injectable({
  providedIn: 'root'
})
export class UbicacionProvider {

  // taxista:   AngularFirestoreDocument<any>;

  viaje: [any];

  constructor(public _usuarioProv: UserProvider,
    private api: ApiProvider) {

  }

  getList() {
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getTrackingList;
      this.api.post(endpoint).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data.Trips);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

}
