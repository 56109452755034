import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import PerfectScrollbar from 'perfect-scrollbar';

import { Events } from '../../providers/common/events.service';
import { UserProvider } from '../../providers/user/user.service';
import { DashboardsService } from '../../providers/dashboards/dashboards.service';

@Component({
  selector: 'csi-menuLat',
  templateUrl: './menu-lat.component.html',
  styleUrls: ['./menu-lat.component.scss'],
})
export class MenuLatComponent implements AfterViewInit {

  //menu configurable
  showTrackingMap: boolean = false;
  showTravelList: boolean = false;
  showVesselTravelList: boolean = false;
  showScore: boolean = false;
  showClaims: boolean = false;
  showConfig: boolean = true;
  showRateTrip: boolean = false;
  showReport: boolean = false;
  showSingleQueryVin: boolean = true;
  showHome: boolean = true;
  showMultipleVinConsult: boolean = true;
  showConsultByDealer: boolean = true;
  showConsultBySiteMexico: boolean = false;
  showExchange: boolean = false;
  showShipmentRequest: boolean = false;
  showMatrizEstatusEquipo: boolean = false;
  showAssetsManager: boolean = false;
  showProgramacionCarga: boolean = false;
  showConsultaDeTractor: boolean = false;
  
  showAlertasPage: boolean = false;

  showInvestorRelations: boolean = false;

  showDrive: boolean = false;
  showFleet: boolean = false;
  showOntrack: boolean = false;
  showYard: boolean = false;

  userFavHomePage: number = 0;

  private _civarMenu: any[] = [];
  @Input("civarMenu")
  set civarMenu(civarMenu: any[]) {
    this._civarMenu = civarMenu;
    /* this._civarMenu.push({
      Cve_Desc_Menu: "FLEET",
      Cve_Nom_Menu: "FLEET",
      Desc_Pagina: "https://ws.csigroup.com.mx/CSIApp/FLEET/home",
      Id_Cnsc_Menu: 110002,
      Id_Cnsc_MenuPadre: 100000,
      Id_Cnsc_TipoMenu: 1,
      OpcionMenu: [
        {
          Cve_Desc_Menu: "FLEET",
          Cve_Nom_Menu: "FLEET",
          Desc_Pagina:
            "https://ws.csigroup.com.mx/CSIApp/FLEET/home",
          Id_Cnsc_Menu: 110002,
          Id_Cnsc_MenuPadre: 100000,
          Id_Cnsc_TipoMenu: 1,
          OpcionMenu: null,
          Orden: 0,
        },
      ],
      Orden: 0,
    }); */

    this.showCivarMenu = Array(civarMenu.length).fill(false);
  }
  get civarMenu() {
    return this._civarMenu;
  }
  public showQuantumMenu: boolean = false; // Quantum
  @ViewChild("submenuElem") submenuElem;
  @ViewChild("asideElem") asideElem;
  @ViewChild("scrollbarContainer") scrollbarContainer;
  isStatic: boolean = true;
  endedMenuInit: Boolean = false;
  ps: any;
  transitionEnd: string | null = null;
  activeMenuItem: string = "";
  @Output() sendNav = new EventEmitter<any>();
  openedFirstTime: boolean = false;
  public showCivarMenu: boolean[] = [];
  @ViewChildren("civarElem") civarElems: QueryList<ElementRef>;
  addedEventListener: boolean = false;
  showHomeSubmenu: boolean = true;
  showDashboardBSCSubmenu: boolean = true;
  favouriteDashboard: string = "";
  @ViewChild("homeSubmenuElem") homeSubmenuElem;
  @ViewChild("dashboardBSCSubmenuElem") dashboardBSCSubmenuElem;
  dashboardFinancieroActive: boolean = false;
  dashboardOperativoActive: boolean = false;
  dashboardAMDF: boolean = false;
  dashboardMazda: boolean = false;
  dashboardBMW: boolean = false;
  multipleDashboardActive: boolean = false;
  multipleDashboardBSCActive: boolean = false;
  dashboardBSC_CPVActive: boolean = false;
  dashboardBSC_CSIActive: boolean = false;
  dashboardBSC_CSIDActive: boolean = false;
  dashboardBSC_CSIXActive: boolean = false;
  dashboardBSC_SunusActive: boolean = false;
  showDashboardBSC: boolean = false;
  currentDashboard: string = "";
  currentDashboardBSC: string = "";
  HOME_SUBMENU_DEFAULT_HEIGHT = 86;

  /* Variables para el listado de pantallas ocultas por el usuario */
  showMoreMenu: boolean = false;
  showMoreMenuDetails: boolean = true;
  @ViewChild("moreMenuSubmenuElem") moreMenuSubmenuElem;


  showTrackingMap_Collapsible: boolean = false;
  showTravelList_Collapsible: boolean = false;
  showScore_Collapsible: boolean = false;
  showConsultBySiteMexico_Collapsible: boolean = false;
  showReport_Collapsible: boolean = false;
  showExchange_Collapsible: boolean = false;
  showMultipleVinConsult_Collapsible: boolean = false;
  showMatrizEstatusEquipo_Collapsible: boolean = false;
  showProgramacionCarga_Collapsible: boolean = false;
  showAlertasPage_Collapsible: boolean = false;
  showShipmentRequest_Collapsible: boolean = false;
  showConsultaDeTractor_Collapsible: boolean = false;
  showInvestorRelations_Collapsible: boolean = false;
  showAssetsManager_Collapsible: boolean = false;
  dashboardBSC_CSIActive_Collapsible: boolean = false;
  dashboardBSC_SunusActive_Collapsible: boolean = false;
  dashboardBSC_CPVActive_Collapsible: boolean = false;
  dashboardBSC_CSIDActive_Collapsible: boolean = false;
  dashboardBSC_CSIXActive_Collapsible: boolean = false;
  showRateTrip_Collapsible: boolean = false;

  constructor(
    private router: Router,
    private userProvider: UserProvider,
    private plt: Platform,
    private events: Events,
    private dashboardsService: DashboardsService,
  ) {
    this.initializeApp();
    this.events.subscribe("nav:active", (activeMenuItem: string) => {
      this.activeMenuItem = activeMenuItem;
    });
    /* this.events.subscribe("menu:dashboardoperativo:setstate", (state: boolean) => {
      this.dashboardOperativoActive = state;
      if (this.dashboardOperativoActive && this.dashboardFinancieroActive && this.dashboardAMDF) {
        // this.getFavouriteDashboard();
        this.showHomeSubmenu = true;
        if (this.showQuantumMenu) {
          this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + (this.homeSubmenuElem.nativeElement.style.maxHeight || this.HOME_SUBMENU_DEFAULT_HEIGHT)) + "px";
        }
      }
    });
    this.events.subscribe("menu:dashboardfinanciero:setstate", (state: boolean) => {
      this.dashboardFinancieroActive = state;
      if (this.dashboardOperativoActive && this.dashboardFinancieroActive && this.dashboardAMDF) {
        // this.getFavouriteDashboard();
        this.showHomeSubmenu = true;
        if (this.showQuantumMenu) {
          this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + (this.homeSubmenuElem.nativeElement.style.maxHeight || this.HOME_SUBMENU_DEFAULT_HEIGHT)) + "px";
        }
      }
    }); */
  }

  isTablet() {
    if (this.plt.is('tablet')) {
      if (this.plt.is('android') || this.plt.is('ios')) return true;
      else {
        return false;
      }
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.events.destroy("nav:active");
    // this.events.destroy("menu:dashboardoperativo:setstate");
    // this.events.destroy("menu:dashboardfinanciero:setstate");
  }

  ngAfterViewInit() {
    if (window.innerWidth < 991 || this.isTablet() || this.isIpad()) {
      this.asideElem.nativeElement.style.display = "none";
    }
    this.isStatic = this.userProvider.isMenuStatic();
    if (!this.isStatic) {
      this.toggleStaticMenu(false);
    }
    this.transitionEnd = this.whichTransitionEnd();
    this.ps = new PerfectScrollbar(this.scrollbarContainer.nativeElement, {
      suppressScrollX: true
    });
    if (this.transitionEnd !== null) {
      this.submenuElem.nativeElement.addEventListener(this.transitionEnd, () => {
        this.ps.update();
        this.ps.update();
      });
      this.civarElems.changes.subscribe((elems) => {
        if (!this.addedEventListener) {
          this.addedEventListener = true;
          elems.forEach(el => {
            el.nativeElement.addEventListener(this.transitionEnd, () => {
              this.ps.update();
              this.ps.update();
            });
          });
        }
      });

      this.events.subscribe("menu:dashboards:current", (dashboard: string) => {
        console.log('menu:dashboards:current ', dashboard);
        this.currentDashboard = dashboard;
      });

      this.homeSubmenuElem.nativeElement.addEventListener(this.transitionEnd, () => {
        this.ps.update();
        this.ps.update();
      });

    }

    setTimeout(() => {
      this.toggleShowMoreSubmenu();
    }, 1000);
  }

  /* 
  // Esta sección esta documentada al ya no se utilizada para cargar los Dashboards y determinar cual es el favorito seleccionado por el usuario
  async initDashboards() {
    await this.getDashboardFinancieroActive();
    await this.getDashboardOperativoActive();
    // this.dashboardFinancieroActive = this.dashboardOperativoActive = true;
    if (this.dashboardFinancieroActive && this.dashboardOperativoActive) {
      this.getFavouriteDashboard();
    }
  }

  getFavouriteDashboard() {
    this.dashboardsService.getDashboardFavorito().then((dashboard: string) => {
      this.currentDashboard = dashboard;
      this.favouriteDashboard = dashboard;
    }).catch(err => {
      console.error(err);
    });
  }

  async getDashboardFinancieroActive() {
    this.dashboardFinancieroActive = await this.dashboardsService.areActivated();
  }

  async getDashboardOperativoActive() {
    this.dashboardOperativoActive = await this.dashboardsService.areActivatedOperativo();
  }
  */

  whichTransitionEnd() {
    var el = document.createElement('div');

    var transEndEventNames = {
      WebkitTransition: 'webkitTransitionEnd',
      MozTransition: 'transitionend',
      OTransition: 'oTransitionEnd otransitionend',
      transition: 'transitionend'
    }

    for (var name in transEndEventNames) {
      if (el.style[name] !== undefined) {
        return transEndEventNames[name];
      }
    }

    return null
  }

  initializeApp() {
    this.userProvider.getMenu().then(async (data: any) => {
      if (data.UserProfile != 1 && data.UserProfile) {
        //this.showConsultaCD = true;
        this.showConsultBySiteMexico = true;
        this.showConfig = true;
        this.showHome = false;
        // this.showExchange = true;
      }
      else if (data.Preferencias) {
        this.showHome = true;
        for (const item of data['Preferencias']) {
          switch (item.Id_Preferencia) {
            case '1':
              this.showTrackingMap = item.Bit_Collapsible ? true : false;
              this.showTrackingMap_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 2 : this.userFavHomePage;
              break;
            case '2':
              //notifications center
              break;
            case '3':
              this.showTravelList = this.showVesselTravelList = item.Bit_Collapsible ? true : false;
              this.showTravelList_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 3 : this.userFavHomePage;
              break;
            case '4':
              this.showScore = item.Bit_Collapsible ? true : false;
              this.showScore_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 4 : this.userFavHomePage;
              break;
            case '5':
              this.showClaims = true;
              this.userFavHomePage = item.Bit_Default ? 5 : this.userFavHomePage;
              break;
            case '6':
              this.showConfig = true;
              break;
            case '7':
              //help
              break;
            case '8':
              this.showSingleQueryVin = true;
              this.userFavHomePage = item.Bit_Default ? 8 : this.userFavHomePage;
              break;
            case '9':
              this.showConsultBySiteMexico = item.Bit_Collapsible ? true : false;
              this.showConsultBySiteMexico_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 9 : this.userFavHomePage;
              break;
            case '10':
              this.showReport = item.Bit_Collapsible ? true : false;
              this.showReport_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 10 : this.userFavHomePage;
              break;
            case '11':
              this.showExchange = item.Bit_Collapsible ? true : false;
              this.showExchange_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 11 : this.userFavHomePage;
              break;
            case '12':
              this.showMultipleVinConsult = item.Bit_Collapsible ? true : false;
              this.showMultipleVinConsult_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 12 : this.userFavHomePage;
              break;
            case '13':
              this.showConsultByDealer = item.Bit_Collapsible ? true : false;

              this.userFavHomePage = item.Bit_Default ? 13 : this.userFavHomePage;
              break;
            case '15':
              this.showMatrizEstatusEquipo = item.Bit_Collapsible ? true : false;
              this.showMatrizEstatusEquipo_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 15 : this.userFavHomePage;
              break;
            case '16':
              this.showProgramacionCarga = item.Bit_Collapsible ? true : false;
              this.showProgramacionCarga_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 16 : this.userFavHomePage;
              break;
            case '17':
              this.showAlertasPage = item.Bit_Collapsible ? true : false;
              this.showAlertasPage_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 17 : this.userFavHomePage;
              break;
            case '20':
              this.showShipmentRequest = item.Bit_Collapsible ? true : false;
              this.showShipmentRequest_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 20 : this.userFavHomePage;
              break;
            case '21':
              this.showConsultaDeTractor = item.Bit_Collapsible ? true : false;
              this.showConsultaDeTractor_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 21 : this.userFavHomePage;
              break;
            case '22':
              this.showInvestorRelations = item.Bit_Collapsible ? true : false;
              this.showInvestorRelations_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 22 : this.userFavHomePage;
              break;
            case '23':
              this.showAssetsManager = item.Bit_Collapsible ? true : false;
              this.showAssetsManager_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 23 : this.userFavHomePage;
              break;
            case '24':
              this.showDashboardBSC = true;
              this.dashboardBSC_CSIActive = true;
              // this.dashboardBSC_CSIActive = item.Bit_Collapsible ? true : false;
              // this.dashboardBSC_CSIActive_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? -11 : this.userFavHomePage;
              break;
            case '25':
              this.showDashboardBSC = true;
              this.dashboardBSC_SunusActive = true;
              // this.dashboardBSC_SunusActive = item.Bit_Collapsible ? true : false;
              // this.dashboardBSC_SunusActive_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? -14 : this.userFavHomePage;
              break;
            case '26':
              this.showDashboardBSC = true;
              this.dashboardBSC_CPVActive = true;
              // this.dashboardBSC_CPVActive = item.Bit_Collapsible ? true : false;
              // this.dashboardBSC_CPVActive_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? -10 : this.userFavHomePage;
              break;
            case '27':
              this.showDashboardBSC = true;
              this.dashboardBSC_CSIDActive = true;
              // this.dashboardBSC_CSIDActive = item.Bit_Collapsible ? true : false;
              // this.dashboardBSC_CSIDActive_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? -12 : this.userFavHomePage;
              break;
            case '28':
              this.showDashboardBSC = true;
              this.dashboardBSC_CSIXActive = true;
              // this.dashboardBSC_CSIXActive = item.Bit_Collapsible ? true : false;
              // this.dashboardBSC_CSIXActive_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? -13 : this.userFavHomePage;
              break;
            case '29':
              this.showRateTrip = item.Bit_Collapsible ? true : false;
              this.showRateTrip_Collapsible = item.Bit_Collapsible ? false : true;

              this.userFavHomePage = item.Bit_Default ? 24 : this.userFavHomePage;
              break;
            default:
              break;
          }
        }

        data.Preferencias.forEach((pantalla: any) => {
          if (!pantalla.Bit_Collapsible) {
            this.showMoreMenu = true;
            /* 
                        if (pantalla.Id_Preferencia == "1") {
                          this.showTrackingMap_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "3") {
                          this.showTravelList_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "4") {
                          this.showScore_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "9") {
                          this.showConsultBySiteMexico_Collapsible = true
                        }
                        if (pantalla.Id_Preferencia == "10") {
                          this.showReport_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "11") {
                          this.showExchange_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "12") {
                          this.showMultipleVinConsult_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "15") {
                          this.showMatrizEstatusEquipo_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "16") {
                          this.showProgramacionCarga_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "17") {
                          this.showAlertasPage_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "20") {
                          this.showShipmentRequest_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "21") {
                          this.showConsultaDeTractor_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "22") {
                          this.showInvestorRelations_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "23") {
                          this.showAssetsManager_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "24") {
                          this.dashboardBSC_CSIActive_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "25") {
                          this.dashboardBSC_SunusActive_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "26") {
                          this.dashboardBSC_CPVActive_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "27") {
                          this.dashboardBSC_CSIDActive_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "28") {
                          this.dashboardBSC_CSIXActive_Collapsible = true;
                        }
                        if (pantalla.Id_Preferencia == "29") {
                          this.showRateTrip_Collapsible = true;
                        } */
          }
        });
      } else {

      }

      const route = this.router.url;
      if (route.includes('/dashboard_bsc/')) this.onRouteIsDashboarBSC(this.router.url.replace('/dashboard_bsc/', ''));

      // El siguiente bloque de codigo se encarga de mostrar en el menu los dashboards
      if (data.Dashboards) {
        console.log('Loading Dashboards...');
        for (const dashboard of data['Dashboards']) {
          switch (dashboard.Id_Dashboard) {
            case '1': // Dashboard Financiero 
              this.dashboardFinancieroActive = true; // De esta forma indicamos que el dashboard esta habilitado.
              if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'financiero'; // Indicamos cual es el dashboard favorito del usuario.
              break;
            case '2': // Dashboard Operativo 
              this.dashboardOperativoActive = true; // De esta forma indicamos que el dashboard esta habilitado.
              if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'operativo'; // Indicamos cual es el dashboard favorito del usuario.
              break;
            case '3': // Dashboard AMDF 
              this.dashboardAMDF = true; // De esta forma indicamos que el dashboard esta habilitado.
              if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'amdf'; // Indicamos cual es el dashboard favorito del usuario.
              break;
            case '4': // Dashboard Mazda 
              this.dashboardMazda = true; // De esta forma indicamos que el dashboard esta habilitado.
              if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'mazda'; // Indicamos cual es el dashboard favorito del usuario.
              break;
            case '5': // Dashboard BMW 
              this.dashboardBMW = true; // De esta forma indicamos que el dashboard esta habilitado.
              if (dashboard.Bit_Default) this.currentDashboard = this.favouriteDashboard = 'bmw'; // Indicamos cual es el dashboard favorito del usuario.
              break;
            default:
              break;
          }
        }

        this.enableHomeSubMenuShow(this.dashboardFinancieroActive, this.dashboardOperativoActive, this.dashboardAMDF, this.dashboardMazda, this.dashboardBMW);
        // await this.initDashboards();
      }

      this.endedMenuInit = true;
      if (this.asideElem && this.asideElem.nativeElement && this.isStatic) {
        this.showQuantumMenu = true;
        if (this.submenuElem && this.submenuElem.nativeElement) {
          setTimeout(() => {
            // console.log(this.dashboardOperativoActive, this.dashboardFinancieroActive);
            this.submenuElem.nativeElement.style.maxHeight = this.submenuElem.nativeElement.scrollHeight + "px";
          }, 100);
        }
      }
      if (window.innerWidth < 991 || this.isTablet()) {
        if (this.plt.is("tablet") || this.isIpad()) {
          document.getElementById("menu-lat-container").style.display = "none";
          // (document.getElementsByClassName("dashboard") as HTMLCollectionOf<HTMLElement>)[0].style.left = "0";
          // (document.getElementsByClassName("dashboard") as HTMLCollectionOf<HTMLElement>)[0].style.width = "100%";
        }
      }
    });
  }

  onRouteIsDashboarBSC(currentDashboard) {
    this.currentDashboardBSC = currentDashboard;
  }

  enableHomeSubMenuShow(activeFinanciero: boolean, activeOperativo: boolean, activeAMDF: boolean, activeMazda: boolean, activeBMW: boolean) {
    let arrayDashboard = [
      { id: 1, bool: activeFinanciero },
      { id: 2, bool: activeOperativo },
      { id: 3, bool: activeAMDF },
      { id: 4, bool: activeMazda },
      { id: 5, bool: activeBMW },
    ];

    let activeDashboard: number = 0;
    let deactiveDashboard: number = 0;


    arrayDashboard.forEach((dashboardItem) => {
      if (this.check(dashboardItem)) {
        activeDashboard++;
      } else {
        deactiveDashboard++;
      }
    });

    if (arrayDashboard.every(this.check)) {
      console.log("all active");
      this.multipleDashboardActive = true;
    } else {
      if (activeDashboard > 1 && activeDashboard <= 4) {
        console.log("active more than one");
        this.multipleDashboardActive = true;
      } else {
        this.multipleDashboardActive = false;
      }
    }
  }

  check(item) {
    return item.bool === true || false;
  }

  mouseEnter() {
    if (!this.isStatic && !this.openedFirstTime) {
      this.openedFirstTime = true;
      this.showQuantumMenu = true;
      if (this.submenuElem && this.submenuElem.nativeElement, this.submenuElem.nativeElement.scrollHeight) {
        setTimeout(() => {
          this.submenuElem.nativeElement.style.maxHeight = this.submenuElem.nativeElement.scrollHeight + "px";
        }, this.endedMenuInit ? 0 : 500);
      }
    }
  }

  toggleMenu(isCivar: boolean, idx: number = 0) {
    if (isCivar) {
      this.showQuantumMenu = false;
      if (this.submenuElem && this.submenuElem.nativeElement) {
        this.submenuElem.nativeElement.style.maxHeight = "0px";
      }
      const otherElems = this.civarElems.filter((el, i) => {
        if (i !== idx) this.showCivarMenu[i] = false;
        return i !== idx;
      });
      otherElems.forEach(el => {
        el.nativeElement.style.maxHeight = "0px";
      });
      this.showCivarMenu[idx] = !this.showCivarMenu[idx];
      const elem = this.civarElems.find((el, i) => i === idx);
      elem.nativeElement.style.maxHeight = this.showCivarMenu[idx] ? elem.nativeElement.scrollHeight + "px" : "0px";
      if (!this.showCivarMenu[idx]) this.events.publish("menues:close", 1);
    } else {

      console.log('Quantum height: ', this.submenuElem.nativeElement.scrollHeight);

      for (let index = 0; index < this.showCivarMenu.length; index++) {
        this.showCivarMenu[index] = false;
      }
      this.events.publish("menues:close", 1);

      this.civarElems.forEach(el => {
        el.nativeElement.style.maxHeight = "0px";
      });
      this.showQuantumMenu = !this.showQuantumMenu;
      if (this.submenuElem && this.submenuElem.nativeElement) {
        this.submenuElem.nativeElement.style.maxHeight = this.showQuantumMenu ? this.submenuElem.nativeElement.scrollHeight + "px" : "0px";
      }
    }
    if (this.transitionEnd === null) {
      setTimeout(() => {
        this.ps.update();
        this.ps.update();
      }, 500);
    }
  }

  resizeContainer(ev: any, idx: number = 0) {
    const elem = this.civarElems.find((el, i) => idx === i);
    elem.nativeElement.style.maxHeight = (elem.nativeElement.scrollHeight + ev) + "px";
    if (this.transitionEnd === null) {
      setTimeout(() => {
        this.ps.update();
        this.ps.update();
      }, 500);
    }
  }

  isIpad() {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('iPad') > -1) {
      return true;
    }
    if (ua.indexOf('Macintosh') > -1) {
      try {
        document.createEvent("TouchEvent");
        return true;
      } catch (e) { }
    }
    return false;
  }

  toggleStaticMenu(publishEvent: boolean = true) {
    if (this.asideElem && this.asideElem.nativeElement) {
      // Posicionamiento del menu
      const added = this.asideElem.nativeElement.classList.toggle("static-menu");
      this.userProvider.setMenuStatic(added);
      this.isStatic = added;
      if (publishEvent) this.events.publish("staticmenu:toggle");
    }
  }

  toggleHomeSubmenu() {
    let arrayDashboard = [
      { id: 1, bool: this.dashboardFinancieroActive },
      { id: 2, bool: this.dashboardOperativoActive },
      { id: 3, bool: this.dashboardAMDF },
      { id: 4, bool: this.dashboardMazda },
      { id: 5, bool: this.dashboardBMW },
    ];

    let activeDashboard: number = 0;
    let deactiveDashboard: number = 0;

    arrayDashboard.forEach((dashboardItem) => {
      if (this.check(dashboardItem)) {
        activeDashboard++;
      } else {
        deactiveDashboard++;
      }
    });

    console.log(activeDashboard);

    if (arrayDashboard.every(this.check) || (activeDashboard > 1 && activeDashboard <= 4)) {
      this.showHomeSubmenu = !this.showHomeSubmenu;
      this.homeSubmenuElem.nativeElement.style.maxHeight = this.showHomeSubmenu ? this.homeSubmenuElem.nativeElement.scrollHeight + "px" : "0px";
      this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + this.homeSubmenuElem.nativeElement.style.maxHeight) + "px";
      if (this.transitionEnd === null) {
        setTimeout(() => {
          this.ps.update();
          this.ps.update();
        }, 500);
      }
    } else {
      if (this.dashboardFinancieroActive) {
        this.goToDashboardFinanciero();
      }
      else if (this.dashboardOperativoActive) {
        this.goToDashboardOperativo();
      }
      else if (this.dashboardAMDF) {
        this.goToDashboardAMDF();
      }
      else if (this.dashboardMazda) {
        this.goToDashboardMazda();
      }
      else if (this.dashboardBMW) {
        this.goToDashboardBMW();
      }
      /* else {
        this.goToHome();
      } */
    }
  }

  toggleDashboardBSCSubmenu() {
    this.showDashboardBSCSubmenu = !this.showDashboardBSCSubmenu;
    this.dashboardBSCSubmenuElem.nativeElement.style.maxHeight = this.showDashboardBSCSubmenu ? this.dashboardBSCSubmenuElem.nativeElement.scrollHeight + "px" : "0px";
    this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + this.dashboardBSCSubmenuElem.nativeElement.style.maxHeight) + "px";
    if (this.transitionEnd === null) {
      setTimeout(() => {
        this.ps.update();
        this.ps.update();
      }, 500);
    }
  }

  toggleShowMoreSubmenu() {
    this.showMoreMenuDetails = !this.showMoreMenuDetails;
    this.moreMenuSubmenuElem.nativeElement.style.maxHeight = this.showMoreMenuDetails ? this.moreMenuSubmenuElem.nativeElement.scrollHeight + "px" : "0px";
    this.submenuElem.nativeElement.style.maxHeight = (this.submenuElem.nativeElement.scrollHeight + this.moreMenuSubmenuElem.nativeElement.style.maxHeight) + "px";
    if (this.transitionEnd === null) {
      setTimeout(() => {
        this.ps.update();
        this.ps.update();
      }, 500);
    }
  }

  async selectFavouriteDashboard(dashboard: string) {
    (await this.dashboardsService.setDashboardFavorito(dashboard)).subscribe(data => {
      this.favouriteDashboard = dashboard;
    }, err => {
      console.error(err);
    });
  }

  goToDashboardFinanciero() {
    this.currentDashboard = "financiero";
    this.events.publish("menu:dashboards:current", this.currentDashboard);

    this.sendNav.emit(-1);
  }

  goToDashboardOperativo() {
    this.currentDashboard = "operativo";
    this.events.publish("menu:dashboards:current", this.currentDashboard);

    this.sendNav.emit(-2);
  }

  goToDashboardAMDF() {
    this.currentDashboard = "amdf";
    this.events.publish("menu:dashboards:current", this.currentDashboard);

    this.sendNav.emit(-3);
  }

  goToDashboardMazda() {
    this.currentDashboard = "mazda";
    this.events.publish("menu:dashboards:current", this.currentDashboard);

    this.sendNav.emit(-4);
  }

  goToDashboardBMW() {
    this.currentDashboard = "bmw";
    this.events.publish("menu:dashboards:current", this.currentDashboard);

    this.sendNav.emit(-5);
  }

  goToDashboardBSCCPV() {
    this.currentDashboardBSC = "cpv";
    this.sendNav.emit(-10);
  }

  goToDashboardBSCCSI() {
    this.currentDashboardBSC = "csi";
    this.sendNav.emit(-11);
  }

  goToDashboardBSCCSID() {
    this.currentDashboardBSC = "csid";
    this.sendNav.emit(-12);
  }

  goToDashboardBSCCSIX() {
    this.currentDashboardBSC = "csix";
    this.sendNav.emit(-13);
  }

  goToDashboardBSCSunus() {
    this.currentDashboardBSC = "sunus";
    this.sendNav.emit(-14);
  }

  goToHome() {
    if (this.userFavHomePage !== 0) {
      // console.log('Valor: ', this.userFavHomePage);

      this.sendNav.emit(this.userFavHomePage);
    } else {
      this.sendNav.emit(1);
    }

  }

  goToClaims() {
    this.sendNav.emit(5);
  }

  goToConfig() {
    this.sendNav.emit(6);
  }

  goToTravelList() {
    this.sendNav.emit(3);
  }

  goToVesselTravelList() {
    this.sendNav.emit(25);
  }

  goToTrackingMap() {
    this.sendNav.emit(2);
  }

  goToScore() {
    this.sendNav.emit(4);
  }

  // gotToConsultaCD() {
  //   this.navCtrl.setRoot(ConsultaCdPage)
  // }

  goToReports() {
    this.sendNav.emit(10);
  }

  goToExchange() {
    this.sendNav.emit(11);
  }

  goToShipmentRequest() {
    this.sendNav.emit(20);
  }

  goToMultipleVinConsult() {
    this.sendNav.emit(12);
  }

  goToConsultByDealer() {
    this.sendNav.emit(13);
  }

  goToConsultBySiteMexico() {
    this.sendNav.emit(9);
  }

  goToMatrizEstatusEquipo() {
    this.sendNav.emit(15);
  }

  goToProgramacionCarga() {
    this.sendNav.emit(16);
  }

  goToAlertasPage() {
    this.sendNav.emit(17);
  }

  goToConsultaDeTractor() {
    this.sendNav.emit(21);
  }

  goToInvestorRelationsPage() {
    this.sendNav.emit(22);
  }

  goToAssetsManagerPage() {
    this.sendNav.emit(23);
  }

  goToRateTrip() {
    this.sendNav.emit(24);
  }

  goToWebFrame() {
    this.sendNav.emit(100);
  }

  logout() {
    this.sendNav.emit(14);
  }

  getIconNameCivarMenu(nameCivar: string) {
    let nameIcon: string = 'earth';

    switch (nameCivar) {
      case 'ADMINISTRACION':
        nameIcon = 'person-circle-outline';
        break;
      case 'OPERACION':
        nameIcon = 'earth';
        break;
      case 'CALIDAD':
        nameIcon = 'medal-outline';
        break;
      case 'RH':
        nameIcon = 'people-outline';
        break;
      case 'TI':
        nameIcon = 'server-outline';
        break;
    }

    return nameIcon;
  }
}
