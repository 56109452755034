import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ApiProvider } from '../common/api.service';
import { apiEndpoints } from '../../common/constants';

import { apiURL } from "../../common/constants";

/*
  Generated class for the VinListProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class VinListProvider {

  logos: any;
  list: any;

  listDetail: any;
  logosDetail: any;

  constructor(private api: ApiProvider) {

  }

  getData(idCD: string, idEstatus: string, idArea: string, idTransporte: string, page: number, marcas: string) {

    var request;

    if (idArea) {
      request = {
        'VINCD': {
          'idPunto': idCD,
          'idEstatusVin': idEstatus,
          'idArea': idArea,
          'idTransporte': '0',
          'page': page,
          'Marcas': marcas
        }
      };
    }
    else if (idTransporte) {
      request = {
        'VINCD': {
          'idPunto': idCD,
          'idEstatusVin': idEstatus,
          'idArea': '0',
          'idTransporte': idTransporte,
          'page': page,
          'Marcas': marcas
        }
      };
    }
    else {
      request = {
        'VINCD': {
          'idPunto': idCD,
          'idEstatusVin': idEstatus,
          'idArea': '0',
          'idTransporte': '0',
          'page': page,
          'Marcas': marcas
        }
      };
    }

    this.list = new Promise((resolve, reject) => {
      this.api.post(apiEndpoints.getVinCDList, request, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.listDetail = data ? data : {};
            resolve(this.listDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });

    this.logos = new Promise((resolve, reject) => {
      this.api.post(apiEndpoints.getVehicleLogos).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.logosDetail = data ? data : {};
            //console.log("logosDetail", this.logosDetail);
            resolve(this.logosDetail);
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });

    return Promise.all([this.list, this.logos]).then(res => {
      //console.log(res);
      var info = res["0"].CDVinsList,
        logos = res["1"].Marks

      // var data = {
      //   info: this._validateLogo(info, logos)
      // };

      info.forEach(vin => {
        vin = this._validateLogo(vin, logos)
      });

      // appCache.put('vehicleDetail', { vin: vin, data: data });

      return info;
    });
  }

  _validateLogo(info, logos) {
    logos.forEach(logos => {
      if (info.MARCA === logos.Name) {
        info.logo = logos.Logo;
        return true;
      }
    });
    return info;
  }

}
