import { Component, Input, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Pass, Username } from "./../../common/constants";

import { Events } from '../../providers/common/events.service';
import { DataService } from '../../providers/common/data.service';
import { CapacitorUtilsService } from '../../providers/common/capacitorUtils.service';
import { AppStorageProvider } from 'src/app/providers/common/app-storage.service';

/**
 * @name MyMenuComponent
 * @description Componente para mostrar el menu de CIVAR en el menu lateral
 */
@Component({
  selector: 'my-menu',
  templateUrl: './my-menu.component.html',
  styleUrls: ['./my-menu.component.scss']
})
export class MyMenuComponent {
  private _items: any[] = [];
  @Input("level") public level: number = 0;
  @Input("menu") public menu: string = "";
  public showSubMenu: boolean[] = [];
  @Output("onSubmenuOpen") public onSubmenuOpenEmitter = new EventEmitter<any>();
  @ViewChildren("submenuElem") submenuElems: QueryList<any>;

  constructor(private events: Events, private capacitorService: CapacitorUtilsService,
    private plt: Platform, private router: Router,
    private dataService: DataService, private storage: AppStorageProvider) {
    this.events.subscribe("menues:close", (level) => {
      if (this.level >= level) {
        for (let i in this.showSubMenu) {
          this.showSubMenu[i] = false;
        }
        this.submenuElems.forEach(item => item.nativeElement.style.maxHeight = "0px");
      }
    })
  }

  @Input("items")
  set items(items: any[]) {
    this._items = items;
    this.showSubMenu = Array(this._items.length).fill(false);
  }

  get items() {
    return this._items;
  }

  /**
   * @name onClick
   * @desc Metodo que que se usa para la accion de un tab del menu
   */
  onClick(item: any, idx: number) {
    if (item.OpcionMenu == null) {
      this.openPage(item);
    } else {
      this.events.publish("menues:close", this.level + 1);
      for (let i in this.showSubMenu) {
        if (parseInt(i) != idx) this.showSubMenu[i] = false;
      }
      this.submenuElems.forEach((item, i) => {
        if (i != idx) item.nativeElement.style.maxHeight = "0px";
      });
      this.showSubMenu[idx] = !this.showSubMenu[idx];
      const elem = this.submenuElems.find((item, i) => i === idx);
      if (elem && elem.nativeElement) {
        const height = this.showSubMenu[idx] ? elem.nativeElement.scrollHeight : 0;
        this.onSubmenuOpenEmitter.emit(height);
        elem.nativeElement.style.maxHeight = height + "px";
      }
    }
  }

  resizeContainer(ev: any) {
    for (let item of this.showSubMenu) {
      if (item) {
        const elem = this.submenuElems.find((el, i) => i === this.showSubMenu.indexOf(item));
        if (elem && elem.nativeElement) {
          this.onSubmenuOpenEmitter.emit(elem.nativeElement.scrollHeight + ev);
          elem.nativeElement.style.maxHeight = (elem.nativeElement.scrollHeight + ev) + "px";
        }
        break;
      }
    }
  }

  /**
   * @name openPage
   * @description Metodo que abre una pagina del menu
   */
  openPage(item: any) {
    console.log('openPage, item: ', JSON.stringify(item));

    if (item.Desc_Pagina) {
      if (item.Desc_Pagina.includes("/ARTUS")) {
        let dashboard: number;
        switch (this.menu) {
          case "ADMINISTRACION":
          case "ADMINISTRATION":
            dashboard = 29;
            break;
          case "OPERACION":
          case "OPERATION":
            dashboard = 126;
            break;
          case "CALIDAD":
          case "QA":
            dashboard = 44;
            break;
          case "RH":
          case "RRHH":
            dashboard = 95;
            break;
          default:
            dashboard = 0;
            break;
        }
        this.autoLoginARTUS(item, Username, Pass, dashboard);
      } else {
        if (this.plt.is("mobile") && (this.plt.is("android") || this.plt.is("ios"))) {
          console.log("IN APP BROWSER CREATE");
          this.capacitorService.goToLaunchBrowser(item.Desc_Pagina);
        } else {

          if (item.Bit_Frame) {
            console.log("FRAME OPEN");
            const routeParams = { // Se genera un objeto con los parametros a transmitir a la siguiente route
              externalURL: item.Desc_Pagina, // URL del item a mostrar en el frame
              routeId: item.Id_Cnsc_Menu,
            };
            this.dataService.setData(routeParams.routeId, routeParams); // Con ayuda del provider DataService asignamos el objeto parametros a un iD, para de esta manera poder recibirlo en la siguiente ruta/pantalla
            this.storage.put(routeParams.routeId, routeParams); // Con ayuda del provider StorageService asignamos el objeto parametros a un iD, para de esta manera poder obtenerlo desde el storage en caso de necesitarlo en la siguiente ruta/pantalla

            this.router.navigate(['/quantum/civar/' + routeParams.routeId], { replaceUrl: true });
          } else {
            console.log("WINDOW OPEN");
            
            window.open(item.Desc_Pagina, "_blank");
          }
        }
      }
    }
  }

  /**
   * @name autoLoginARTUS
   * @description Metodo que hace un inicio de sesion en el sistem ARTUS
   */
  autoLoginARTUS(item, user, pass, dashboard) {
    if (user != null && pass != null) {
      var form = document.createElement("form");
      form.method = "POST";
      form.action = "https://aws.csigroup.com.mx/ARTUSG9/LoadStage.php";
      form.target = "_blank";

      form.method = "POST";
      var element1 = document.createElement("input");
      var element2 = document.createElement("input");
      var element3 = document.createElement("input");
      var element4 = document.createElement("input");
      var element5 = document.createElement("input");

      element1.id = "repository";
      element1.name = "txtRepositorio";
      element1.value = "BISunusProd";
      form.appendChild(element1);

      element2.id = "username";
      element2.name = "txtUser";
      element2.value = user;
      form.appendChild(element2);

      element3.id = "password";
      element3.name = "txtPassword";
      element3.value = pass;
      form.appendChild(element3);

      element4.name = "txtNumEsc";
      element4.value = dashboard;
      form.appendChild(element4);

      element5.name = "txtParametros";
      element5.value = "TPParams";
      form.appendChild(element5);

      document.body.appendChild(form);

      form.submit();
    } else {
      if (this.plt.is("mobile") && (this.plt.is("android") || this.plt.is("ios"))) {
        console.log("IN APP BROWSER CREATE");
        this.capacitorService.goToLaunchBrowser(item.Desc_Pagina);
      } else {

        if (item.Bit_Frame) {
          console.log("FRAME OPEN");

          const routeParams = { // Se genera un objeto con los parametros a transmitir a la siguiente route
            externalURL: item.Desc_Pagina, // URL del item a mostrar en el frame
            routeId: item.Id_Cnsc_Menu,
          };
          this.dataService.setData(routeParams.routeId, routeParams); // Con ayuda del provider DataService asignamos el objeto parametros a un iD, para de esta manera poder recibirlo en la siguiente ruta/pantalla
          this.storage.put(routeParams.routeId, routeParams); // Con ayuda del provider StorageService asignamos el objeto parametros a un iD, para de esta manera poder obtenerlo desde el storage en caso de necesitarlo en la siguiente ruta/pantalla

          this.router.navigate(['/quantum/civar/' + routeParams.routeId], { replaceUrl: true });
        } else {
          console.log("WINDOW OPEN");
          window.open(item.Desc_Pagina, "_blank");
        }
      }
    }
  }

}
