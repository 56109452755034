import { DatePipe } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

// import { SessionProvider } from '../providers/session/session.provider';
// import { ErrorService } from '../providers/error-service/error-service';
import { AlertController } from '@ionic/angular';
import { SessionProvider } from '../providers/session/session.service';
import { ErrorService } from '../providers/error-service/error.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private datePipe: DatePipe,
    private sessionProvider: SessionProvider,
    private errorService: ErrorService,
    private alertCtrl: AlertController
  ) {}
  /**
   * @name intecept
   * @desc Método para interceptar requests httpClient para agregar body y headers.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const acceptJson = request.body ? request.body.JSON : true;
    let records;
    if (Array.isArray(request.body)) {
      records = [];
      for (let el of request.body) records.push(el);
    } else {
      records = { ...request.body };
      delete records.JSON;
    }
    const body = {
      Operation: {
        DateTime: this.datePipe.transform(
          new Date(),
          'yyyy-MM-ddTHH:mm:ssZZZZZ'
        ),
        Access_Token: this.sessionProvider.key,
        OperationName: 'user_menu',
      },
      Records: records,
    };

    const bodyJSON = JSON.stringify(body);

    const headers = new HttpHeaders({
      'Content-Type': acceptJson ? 'application/json' : 'application/json',
    });

    request = request.clone({
      headers: headers,
      body: bodyJSON,
    });

    // let jString = JSON.stringify(request);
    // let json = JSON.parse(jString);

    return next.handle(request).pipe(
      tap(
        (success) => {
          if (success instanceof HttpResponse) {
            if (success.body.Success === 'false') {
              if (
                success.body.Operation.ErrorMessage &&
                success.body.Operation.ErrorMessage === 'Sesion Expirada'
              ) {
                console.error('Sesión Expirada');
                //this.sessionProvider.resetAll();
              }
            }
          }
        },
        (error) => {
          console.error(error);
          // Can be: timeout | not found | conected to network but no internet access
          this.errorService.addErrorOffline({
            Fecha_Hora: this.datePipe.transform(
              new Date(),
              'yyyy-MM-ddTHH:mm:ssZZZZZ'
            ),
            Error_Code: error.status ? error.status.toString() : '0',
            Error_Message: error.message || 'Error Desconocido.',
          });
          if (error.status == ErrorService.SERVER_TIMEOUT_ERROR_CODE) {
            const headerAlert = 'Algo salió mal!';
            const messageAlert =
              'Servicio no disponible por el momento. Vuelva a intentarlo más tarde.';
            this.showErrorAlert(headerAlert, messageAlert);
          } else {
            const headerAlert = 'Error Desconocido';
            const messageAlert = 'Ocurrio un error desconocido, lo sentimos.';
            this.showErrorAlert(headerAlert, messageAlert);
          }
        }
      )
    );
  }

  /**
   * @name showErrorAlert
   * @desc Metodo que se encargar de mostrar una Alerta de error sencilla
   * @title El parametro que recive es el titulo a mostrar en la Alerta
   * @error El parametro que recive es el error a mostrar en la Alerta
   */
  async showErrorAlert(title: string, error: string) {
    let acceptButton: any = {
      text: 'Aceptar',
    };
    const errorAlert = await this.alertCtrl.create({
      header: title,
      message: error,
      buttons: [acceptButton],
      cssClass: 'base-alert error-alert',
    });
    await errorAlert.present();
  }
}
