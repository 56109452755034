import { Injectable } from '@angular/core';

import { ApiProvider } from '../common/api.service';
import { patiosWss, patiosWssUrl } from '../../common/constants';

/**
 * @name PatiosService
 * @desc Servicio usado para hacer las peticiones de las vistas de patios / cd's
 */
@Injectable({
  providedIn: 'root'
})
export class PatiosService {
  constructor(private api: ApiProvider) { }

  /**
   * @name getUbicacionCentrosDistribucion
   * @desc Metodo que obtiene la informacion general de todos los centros de distribucion (usado en el mapa)
   */
  getUbicacionCentrosDistribucion(id: number | null = null) {
    let body = {};
    if (id !== null) {
      body["Id_Cnsc_CD"] = id;
    }
    return this.api.post(patiosWss.getUbicacionCentrosDistribucion, body, patiosWssUrl);
  }

  /**
   * @name getUbicacionCentrosDistribucion
   * @desc Metodo que obtiene la informacion general de todos los centros de distribucion (usado en el mapa)
   */
  getUbicacionCD(byVIN: string = '') {
    const records = {
        VIN: byVIN.length === 17 ? byVIN : null
    };

    return this.api.post(patiosWss.getUbicacionCentrosDistribucion, records, patiosWssUrl);
  }

  /**
   * @name getEstatus
   * @desc Metodo que obtiene los estatus de un centro de distribucion
   */
  getEstatus(id: number, floor: number | null = null) {
    let body = {
      Id_Cnsc_CD: id
    };
    if (floor !== null) {
      body["Piso"] = floor;
    }
    return this.api.post(patiosWss.getEstatus, body, patiosWssUrl);
  }

  /**
   * @name getPatios
   * @desc Metodo que obtiene los patios de un centro de distribucion
   */
  getPatios(id: number, floor: number | null = null) {
    let body = {
      Id_Cnsc_CD: id
    };
    if (floor !== null) {
      body["Piso"] = floor;
    }
    return this.api.post(patiosWss.getPatios, body, patiosWssUrl);
  }

  /**
   * @name getBahias
   * @desc Metodo que obtiene las filas y cajones de una bahia
   */
  getBahias(id: number, idBahia: number, floor: number | null = null) {
    let body = {
      Id_Cnsc_CD: id,
      NSeccion: idBahia
    };
    if (floor !== null) {
      body["Piso"] = floor;
    }
    return this.api.post(patiosWss.getBahias, body, patiosWssUrl);
  }

  searchVin(vin: string) {
    return this.api.post(patiosWss.searchVin, { VIN: vin }, patiosWssUrl);
  }
}
