import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { AlertController } from '@ionic/angular';

import { App } from '@capacitor/app';
import { AppUpdate } from '@capawesome/capacitor-app-update';

import { appVersionInfo } from '../../interfaces/version.interface';

@Injectable({
    providedIn: 'root'
})
export class AppVersionCheckService {

    constructor(private alertCtrl: AlertController) { }

    /***
     * @name getAppVersionInfo
     * @desc Metodo que se encarga de obtener la version de la apliacion
    ***/
    async getAppVersionInfo() {
        const infoAppVersion: string = (await App.getInfo()).version;

        const { url } = await App.getLaunchUrl();
        console.log('App opened with URL: ' + url);


        return infoAppVersion;
    }

    /***
     * @name getObjectVersionInfo
     * @desc Metodo que se encarga de devolver la informacion de la version de la app.
    ***/
    async getObjectVersionInfo() {
        const appData = await App.getInfo();
        let appVersion = appData.version;

        const appInfo: appVersionInfo = {
            appPlatform: Capacitor.getPlatform(),
            appVersion: appVersion,
        };

        return appInfo;
    }

    /* async getObjectVersionInfo() {
        const appInfo: appVersionInfo = {
            appPlatform: 'ios',
            appVersion: '6.5.4',
        };

        // this.appUpdateURL = CapacitorUtilsService.wichPlatform === 'ios' ? appStoreiOS : appStoreAndroid;
        // this.appStoreName = CapacitorUtilsService.wichPlatform === 'ios' ? 'App Store' : 'Play Store';
        const appUpdateURL = appStoreiOS;
        const appStoreName = 'App Store';

        return appInfo;
    } */

    /***
     * @name alertUpdateAppRequired
     * @desc Callback para login fallido, muestra una alerta solicitando que es necesario actualizar la app porque se cuenta con una version no soportada.
     * @titulosAlerta Texto que se mostrara en el titulo de la alerta.
     * @mensajeAlerta Texto que se mostrara en el detalle de la alerta.
     * @aceptarButonAlerta Texto que se mostrara en el boton de aceptar de la alerta.
    ***/
    async alertUpdateAppRequired(titulosAlerta, mensajeAlerta, aceptarButonAlerta) {
        console.log('Open Alert');
        const confirmar = await this.alertCtrl.create({
            header: titulosAlerta,
            message: mensajeAlerta,
            buttons: [
                {
                    text: aceptarButonAlerta,
                    handler: () => {
                        this.goToOpenStore();
                    }
                }
            ],
            cssClass: "base-alert"
        });
        await confirmar.present();

        setTimeout(() => {
            console.log('Close Alert');
            confirmar.dismiss();

            confirmar.onDidDismiss().then(() => {
                this.goToOpenStore();
            })
        }, 5000);
    }

    /***
     * @name goToOpenStore
     * @desc Metodo que se encarga de Abrir la store del dispositivo.
    ***/
    goToOpenStore() {
        AppUpdate.openAppStore().then(() => { })
            .catch((err: any) => {
                console.log('Error abriendo la store: ', err);
            });
    }
}
