import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { apiEndpoints } from "../../common/constants";
import { ApiProvider } from "../common/api.service";
import { AppStorageProvider } from "../common/app-storage.service";

@Injectable({
  providedIn: 'root'
})
export class TravelListProvider {
  travels: any[];

  constructor(
    private appStorage: AppStorageProvider,
    private api: ApiProvider
  ) {
    this.loadStorage();
  }

  /**
   * @name loadStorage
   * @desc Metodo para la obtencion de viajes almacenados en el storage interno solo cuando el
   * el tipo de viaje es CURRENT(En Transito).
   * @returns {Object} - LocalStorage(Cache Interno)
   */
  loadStorage() {
    Promise.all([this.appStorage.get("travelList/current")]).then(
      (values: any[]) => {
        if (values[0]) {
          this.travels = values[0].trips.current;
        }
      }
    );
  }

  /**
   * @name getTravels
   * @desc Metodo para resolver data de viajes "EnTransito".
   * @returns {Object} - Promesa
   */
  getTravels(tripType) {
    return new Promise((resolve, reject) => {
      // if (this.travels && tripType == "CURRENT") {
      //   this.loadStorage();
      //   setTimeout(() => {
      //     resolve(this.travels);
      //   }, 50);
      // } else {
      const records = {
        TripType: tripType,
        Page: 1,
        EnVigilancia: 0
      };
      const endpoint = apiEndpoints.getTravelList;
      this.api.post(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            this.travels = null;
            if (data.Trips != null && data.Trips.length > 0) {
              this.travels = data.Trips;
              // this.travels = data.Trips.length > 0 ? data.Trips : null;
              this.saveTrips(tripType);
              resolve(data.Trips);
            } else {
              this.saveTrips(tripType);
              resolve(null);
            }
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
      //}
    });
  }

  /**
   * @name saveTrips
   * @desc Metodo encargado de validacion y guardado en LocalStorage(Cache Interno)
   * solo cuando el tipo de viaje es CURRENT(En Transito)
   * @returns {Object} - LocalStorage(Cache Interno)
   */
  saveTrips(tripType) {
    if (tripType == "CURRENT") {
      let travels = {
        trips: {}
      };
      this.appStorage.get("travelList/current").then(data => {
        if (data) {
          travels.trips = data["trips"];
          travels.trips["current"] = this.travels;
        }
        this.appStorage.put("travelList/current", travels);
      });
    }
  }

  setSurvillance(travelId, action) {
    return new Promise<void>((resolve, reject) => {
      const records = {
        Trips: [
          {
            IdViaje: travelId
          }
        ]
      };

      const endpoint = apiEndpoints.setSurvillance;
      this.api.post(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject();
          } else {
            resolve();
          }
        },
        (error: HttpErrorResponse) => {
          reject();
        }
      );
    });
  }
}
