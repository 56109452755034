import { Injectable } from '@angular/core';
import { ApiProvider } from '../common/api.service';
import { consultasCivar, consultasCivarUrl } from '../../common/constants';

/**
 * @name MultipleVinService
 * @desc Servicio que se usa para hacer la busqueda de vines
 */
@Injectable({
  providedIn: 'root'
})
export class MultipleVinService {
  constructor(private api: ApiProvider) { }

  /**
   * @name getVinsInformation
   * @desc Metodo que regresa la información de los vines encontrados
   */
  getVinesTodos(drillDown: string, page: number, sortBy: string = "", sortOrder: string) {
    console.log("dtos a buscar", drillDown);
    return this.api.post(consultasCivar.vinesTodos, {
      numeroHoja: page,
      campos: "",
      filtroDrillDown: drillDown,
      detalle: 1,
      campoOrdenamiento: sortBy,
      direccionOrdenamiento: sortOrder
    }, consultasCivarUrl);
  }

  /**
   * @name getVinesDetail
   * @desc Metodo que regresa una lista de vines dado un criterio de busqueda
   */
  getVinesDetail(drillDown: string, page: number, dealer: number = 0, sortBy: string = "", sortOrder: string, filters = "") {
    return this.api.post(consultasCivar.vines2, {
      dealer,
      nueva: 1,
      filtros: filters == "" ? 0 : 1,
      campos: "",
      filtroDrillDown: drillDown,
      detalle: 1,
      id_Cnsc_Consulta: 0,
      filtrosXML: filters,
      numeroHoja: page,
      campoOrdenamiento: sortBy,
      direccionOrdenamiento: sortOrder
    }, consultasCivarUrl);
  }

  exportDetalle(columns: string, filtroDrillDown: string = "", filters: string = "", dealer: number = 0) {
    return this.api.post(consultasCivar.exportDetalle, {
      dealer,
      "nueva": 1,
      "filtros": filters == "" ? 0 : 1,
      "campos": "",
      "filtroDrillDown": filtroDrillDown,
      "detalle": 1,
      "id_Cnsc_Consulta": 0,
      "filtrosXML": filters,
      "columnasExcel": columns
    }, consultasCivarUrl);
  }

  exportMultiple(columns: string, filtroDrillDown: string = "") {
    return this.api.post(consultasCivar.exportMultiple, {
      "campos": "",
      filtroDrillDown,
      "detalle": 1,
      "columnasExcel": columns
    }, consultasCivarUrl);
  }
}
