import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { apiEndpoints, apiCivarMenu, CargarMenuCivar } from '../../common/constants';
import { ApiProvider } from '../common/api.service';
import { AppStorageProvider } from '../common/app-storage.service';

// import { apiURL } from "../../common/constants";

@Injectable({
  providedIn: 'root'
})
export class UserProvider {
  private menuIsStatic: boolean = true;

  constructor(private appStorage: AppStorageProvider,
    private api: ApiProvider) { }

  /**
   * @name getProfile
   * @desc Método para solicitar informacion general del usuario
   */
  getProfile() {
    return new Promise((resolve, reject) => {
      this.appStorage.get('userProfile').then(profile => {
        if (profile) {
          resolve(profile);
        } else {
          const endpoint = apiEndpoints.getUser;
          this.api.post(endpoint).subscribe(
            (data: any) => {
              const user = data.User || {};
              const newProfile = {
                name: user.Name,
                email: user.Mail,
                avatar: user.Avatar
              }

              this.appStorage.put('userProfile', newProfile);
              resolve(newProfile);
            },
            (error: HttpErrorResponse) => {
              reject(error.status + " " + error.statusText);
            }
          );
        }
      });
    });
  }

  /**
   * @name getMenu
   * @desc Método para solicitar las opciones del menu que el usuario puede ver
   */
  getMenu() {
    return new Promise((resolve, reject) => {
      this.appStorage.get('userMenu').then(menu => {
        // if (menu) {
        //   resolve(menu);
        // }
        // else {
        const endpoint = apiEndpoints.getUserMenu;
        this.api.post(endpoint).subscribe(
          (data: any) => {
            // const preferencias = data.Preferencias;
            const preferencias = data;
            if (preferencias) {
              this.appStorage.put('userMenu', preferencias);
            }
            resolve(preferencias);
          },
          (error: HttpErrorResponse) => {
            resolve(error.status + " " + error.statusText);
            // reject(error.status + " " + error.statusText);
          }
        );
        // }
      });
    });
  }

  isMenuStatic(): any {
    return this.menuIsStatic;
  }

  setMenuStatic(isStatic: boolean) {
    this.menuIsStatic = isStatic;
  }

  getCivarMenu() {
    return new Promise((resolve, reject) => {

      if (!CargarMenuCivar) return;

      this.api.post('', {}, apiCivarMenu, false).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            if (CargarMenuCivar) {
              reject(data.ErrorMessage);
            } else {
              resolve(null);
            }
          } else {
            resolve(data.Menu);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + ' ' + error.statusText);
        }
      );
    });
  }
  changePasword(oldPwd, newPwd) {
    const body = {
      password: oldPwd,
      newpassword: newPwd
    }
    const endpoint = apiEndpoints.userChangePassword
    return this.api.post(endpoint, body);
  }

  /**
   * @name updateUserMenuPreferences
   * @desc Método para solicitar las opciones del menu que el usuario puede ver
   */
  updateUserMenuPreferences(lsPreferences: any) {
    /* return this.api.post(apiEndpoints.updateUserMenuPreferences,
      lsPreferences); */

    return new Promise((resolve, reject) => {

        const endpoint = apiEndpoints.updateUserMenuPreferences;
        this.api.post(endpoint, lsPreferences).subscribe(
          (data: any) => {
            // const preferencias = data.Preferencias;
            const preferencias = data;

            resolve(preferencias);
          },
          (error: HttpErrorResponse) => {
            resolve(error.status + " " + error.statusText);
            // reject(error.status + " " + error.statusText);
          }
        );
        // }
      });
  }

}
