import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";
import { Preferences } from "@capacitor/preferences";

@Injectable({
  providedIn: 'root'
})
export class AppStorageProvider {

  constructor(private platform: Platform) { }

  /**
  * @name get
  * @desc Metodo para consultar información de objeto en local storage.
  * @param {String} key - Referencia de key de objeto en local storage.
  * @returns {Object} - Objeto con información en caso de existir, de no existir devuelve undefined
  */
  get(key: string) {
    return new Promise(async (resolve, reject) => {
      if (this.platform.is('cordova')) {
        const data = await Preferences.get({ key: key });
        resolve(JSON.parse(data.value));
      } else {
        resolve(JSON.parse(localStorage.getItem(key)));
      }
    });
  }

  /**
  * @name put
  * @desc Método para agregar información de objeto cache.
  * @param {String} key - Referencia de key de objeto en cache.
  * @param {Object} data - Data con la información a guardar.
  */
  async put(key: string, data: any) {
    if (this.platform.is('cordova')) {
      await Preferences.set({
        key: key,
        value: JSON.stringify(data)
      });
    } else {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  /**
  * @name remove
  * @desc Método para eliminar un de objeto específico del local storage.
  * @param {String} id - Referencia de key de objeto en local storage.
  */
  async remove(key: string) {
    await Preferences.remove({ key: key });
  }

  /**
  * @name removeAll
  * @desc Método para limpiar objeto cache completo.
  */
  async removeAll() {
    if (this.platform.is('cordova')) {
      const credentials = await get('credentials');
      await Preferences.clear();      
      set('credentials', credentials);
    } else {
      localStorage.clear();
    }
  }
}

export async function set(key: string, value: any): Promise<void> {
  await Preferences.set({
    key: key,
    value: JSON.stringify(value),
  });
}

export async function get(key: string): Promise<any> {
  const item = await Preferences.get({ key: key });
  return JSON.parse(item.value);
}

export async function remove(key: string): Promise<void> {
  await Preferences.remove({ key: key });
}
