import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { apiURL } from "../../common/constants";
import { apiEndpoints } from '../../common/constants';
import { ApiProvider } from '../common/api.service';


@Injectable({
  providedIn: 'root'
})
export class ReportListProvider {
  rptList: any;

  constructor(public http: HttpClient, private api: ApiProvider) {

  }

  getList(idType) {

    var request;

    request = {
      'idType': idType
    };

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.getReportList;
      this.api.post(endpoint, request, apiURL).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            this.rptList = data ? data : {};
            resolve(this.rptList);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

}
