import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";
import { DatePipe } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { fromEvent } from 'rxjs';

import { AppStorageProvider } from '../common/app-storage.service';
import { CsiError } from '../../interfaces/csi-error';
import { bitacoraErroresUrl, bitacoraErrores } from '../../common/constants';

/**
 * @name ErrorService
 * @desc Servicio que se usa para guardar errores en la bitacora
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public static INVALID_SESSION_ERROR_CODE = "1001";
  public static SQL_TIMEOUT_ERROR_CODE = "1002";
  public static SERVER_TIMEOUT_ERROR_CODE = "504";
  public static SQL_ERROR_CODE = "1003";
  public static NO_CONEXION_ERROR_CODE = "409";
  private online: boolean = true;

  constructor(private storage: AppStorageProvider, private http: HttpClient, private datePipe: DatePipe,
  private plt: Platform) {
    this.sendStoredErrors();
    let onlineObs = fromEvent(document, "online");
    let offlineObs = fromEvent(document, "offline");
    onlineObs.subscribe(() => {
      this.online = true;
    });
    offlineObs.subscribe(() => {
      this.online = false;
    });
  }

  isConnected(): boolean{
    if(this.plt.is("cordova")){
      return this.online;
      // let conntype = this.network.type;
      // return conntype && conntype !== 'none';
    } else {
      return navigator.onLine;
    }
  }

  addError(error: CsiError) {
    if(this.isConnected()){
      this.http.post(bitacoraErroresUrl + bitacoraErrores.setError, [error]).subscribe(data => {
        // Exito
        console.log("Error sent.");
      }, err => {
        console.error(err);
        const httpError = {
          Fecha_Hora: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZZZZZ'),
          Error_Code: err.status ? err.status.toString() : "500",
          Error_Message: err.message || err.statusText
        }
        this.storage.get("csiErrors").then((errors: CsiError[] | null) => {
          if(errors){
            errors.push(error, httpError);
            this.storage.put("csiErrors", errors);
          } else {
            this.storage.put("csiErrors", [error, httpError]);
          }
        });
      });
    } else {
      this.addErrorOffline(error);
    }
  }

  addErrorOffline(error){
    this.storage.get("csiErrors").then((errors: CsiError[] | null) => {
      if(errors){
        errors.push(error);
        this.storage.put("csiErrors", errors);
      } else {
        this.storage.put("csiErrors", [error]);
      }
    });
  }

  sendStoredErrors() {
    if(this.isConnected()){
      this.storage.get("csiErrors").then((errors: CsiError[] | null) => {
        console.log("errors", errors);
        if(errors && errors.length){
          this.http.post(bitacoraErroresUrl + bitacoraErrores.setError, errors).subscribe(data => {
            console.log(errors.length + " error(s) sent. ");
            this.storage.put("csiErrors", null);
          }, err => {
            console.error(err);
            errors.push({
              Fecha_Hora: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ssZZZZZ'),
              Error_Code: err.status ? err.status.toString() : "500",
              Error_Message: err.message || err.statusText
            });
            this.storage.put("csiErrors", errors);
          });
        }
      });
    }
  }
}
